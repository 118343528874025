.project_mobilePreview {
    position: relative;
    margin: $twoGrid 0;
    margin-left: $fourGrid;
    color: #fff;
    $deviceColor: #fff;

    .mp {
        position: relative;
        cursor: pointer !important;
        transition: $t-medium;
        color: $deviceColor;

        .bg {
            fill: transparentize($deviceColor, 0.5);
            transition: $t-medium;
        }

        .obj {
            fill: currentColor;
        }

        &.zoom {
            z-index: 30;
            transform: scale(1.05);

            .bg {
                fill: transparentize($deviceColor, 0.3);
            }
        }

        &.iphone {
            &.zoom {
                @media all and (min-width: $bp-handheld-min) {
                    z-index: 301;
                    transform: translate(25%, -34%) scale(1.5) !important;
                    transition: $t-medium;
                }
            }
        }

        figure {
            overflow: hidden;
            position: absolute;
            z-index: 11;
            border-radius: 3px;
            transition: $t-medium;
            opacity: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: currentColor;

            &:before {
                position: absolute;
                z-index: 24;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: $ci-blue;
            }

            &:after {
                content: "Klick to scroll";
                position: absolute;
                left: 50%;
                top: 7rem;
                transform: translate(-50%, 0);
                z-index: 30;
                background: $ci-green;
                font-size: 1.8rem;
                white-space: nowrap;
                font-weight: 700;
                padding: 1rem;
                line-height: 1;
                box-shadow: 0 0.6em 1em -0.2em rgba(0, 0, 0, 0.5);
                opacity: 0;
                pointer-events: none;
                transition: $t-fast;
            }

            img {
            }

            &:hover,
            .touch & {
                &:before,
                &:after {
                    opacity: 1;
                }

                img {
                    animation: scrollPreview normal forwards ease-in-out 0.3s 0s;
                }
            }

            &.scrolling {
                opacity: 1;

                &:before,
                &:after {
                    opacity: 0 !important;
                }

                img {
                    animation: 0;
                }
            }
        }

        &.ipad {
            transform-origin: center bottom;

            figure {
                width: 87.85%;
                height: 92.3%;
            }
        }

        &.iphone {
            position: absolute;
            width: 25%;
            bottom: -15%;
            left: -15%;
            z-index: 15;

            @media all and (min-width: 1500px) {
                left: -26.5%;
                width: 30%;

                &.zoom {
                    transform: translate(16.8%, -21.9%) scale(1.31);
                }
            }

            @media all and (min-width: 1800px) {
                &.zoom {
                    transform: none;
                }
            }

            figure {
                width: 93.75%;
                height: 78.6%;
            }

            img.shadow {
                transform: scaleX(1.25) scaleY(1.35) translate(1%, 3%);
                transform-origin: 0 center;
            }

            &.appeared {
                transform: none;
                opacity: 1;
            }
        }
    }

    .device {
        position: relative;
        z-index: 10;
        opacity: 1;
        transition: $t-medium;
    }

    img.shadow {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        transform: scale(1.11) translate(3%, -5%);
    }
}

section p + .project_mobilePreview {
    margin-top: $oneGrid;
}

@keyframes scrollPreview {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes scrollFull {
    0% {
        background-position: 0 top;
    }

    50% {
        background-position: 0 bottom;
    }

    100% {
        background-position: 0 top;
    }
}

@media screen and (max-width: 1499px) {
    .project_mobilePreview {
        margin-left: $twoGrid;
    }
}

@media screen and (max-width: 1024px) {
    .project_mobilePreview {
        margin-left: $threeGrid;
        margin-bottom: 12.5vw;
    }
}

@media screen and (max-width: 1023px) {
    .project_mobilePreview {
        margin-left: 0;

        img.shadow {
            transform: scale(1.11) translate(0%, -6%);
            opacity: 0.75;
        }

        .mp.iphone {
            display: none;
        }
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .project_mobilePreview {
        .mp.ipad {
            display: none;
        }

        .mp.iphone {
            display: block;
            position: relative;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 425px;
            margin: 4em auto 0;
            transform: none !important;
        }
    }
}
