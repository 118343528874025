.sliderNav {
    position: fixed;
    z-index: 10;
    width: 75%;
    right: 0;
    bottom: -100%;
    height: $oneGrid;
    background: $grey-lighter;
    text-align: center;
    transition: $t-slow;

    /* position: fixed;
  z-index: 10;
  width: auto;
  left: calc(50% + 12.5vw);
  transform: translateX(-50%);
  bottom: -100%;
  height: $oneGrid;
  background: $grey-lighter;
  text-align: center;
  box-shadow: 0 1.6em 1.1em -0.5em rgba(32, 99, 123, 0.5);
  transition: $t-slow; */
    .currentProjects {
        position: absolute;
        left: $oneGrid;
        top: 100%;
        margin-top: 1px;
        transform: translateY(0);
        opacity: 0;
        line-height: 1;
        font-weight: 700;
        font-size: calc(15px + 0.5vw);
        text-transform: uppercase;
        transition: $t-medium;
        color: $ci-blue-bright;
    }

    &.show {
        bottom: 0;
        transition: $t-slow;

        .currentProjects {
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;
            transition-delay: 1s;
            color: $ci-blue-grey;
        }

        .slide-burger {
            background: #fff;
            transition-delay: 1s;

            span {
                background: $ci-blue-grey;
                transition-delay: 1s;
            }

            &:hover,
            &:focus,
            &:active {
                span {
                    transition-delay: 0s;
                }
            }
        }
    }

    .slide-left,
    .slide-right,
    .slide-burger {
        height: $oneGrid;
        width: $oneGrid;
        background: $grey-lighter;
        display: inline-block;
        vertical-align: top;
        margin: 0 -2px;
        cursor: pointer;
    }

    .slide-burger {
        background: $ci-blue-bright;
        transition: $t-medium;
        transition-delay: 0.25s;

        span {
            background: #fff;
        }
    }

    .slide-left {
        transform: rotate(-90deg);
    }

    .slide-right {
        transform: rotate(90deg);
    }

    .analog-icon.arrow_up {
        display: inline-block;
        font-size: 2em;
        color: $grey-dark;

        @include centerHorz;
    }

    .burger {
        animation: none;
        opacity: 1;
        top: 50%;
        height: 14px;
    }
}

.slideMenu {
    z-index: 10;
    padding: 0;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);
    bottom: 100%;
    position: absolute;
    max-width: $oneGrid;
    min-width: $oneGrid;
    max-height: $oneGrid;
    transform-origin: center bottom;
    transition: $t-medium;
    background: transparent;
    transition-delay: 0.3s;
    visibility: hidden;

    &:before {
        content: "";
        position: absolute;
        border-radius: 30px;
        display: block;
        z-index: -1;
        width: 90%;
        height: 90%;
        opacity: 0;
        left: 5%;
        bottom: 0;
        background: $ci-blue;
        box-shadow: 0 0 3em $ci-blue, 0 0 3em $ci-blue, 0 0 7em $ci-blue, 0 0 9em $ci-blue;
        transition: $t-fast;
    }

    ul {
        margin: 0;
        padding: 0;
        position: relative;
        transition: $t-medium;

        li {
            float: left;
            vertical-align: top;
            padding: 0 $halfGrid;
            padding-right: 0;
            width: 33.33333%;
            margin-bottom: $halfGrid;

            .inner {
                background: #fff;
                transform: scale(0);
                opacity: 0;
                transition: $t-fast;
                position: relative;
                text-align: center;

                img {
                    padding: $oneGrid;
                    transition: $t-medium;
                }
            }
        }
    }
}

.open {
    .slideMenu {
        visibility: visible;
        max-width: (($oneGrid) * 14);
        min-width: (($oneGrid) * 14);
        max-height: 100vh;
        transform: translate3d(-50%, 0, 0);
        transition-delay: 0s;

        &:before {
            opacity: 0.15;
            transition-delay: 0s;
        }

        ul {
            opacity: 1;
            visibility: visible;
            padding: $halfGrid $halfGrid 0 0;
            background: $grey-lighter;

            li {
                .inner {
                    transform: scale(1);
                    opacity: 1;
                    transition-delay: 0.2s;
                }

                &:hover,
                &:focus,
                &:active {
                    .inner {
                        transition-delay: 0s;
                        transform: scale(1.05);
                        box-shadow: 2px 3px 2em transparentize(#000, 0.9);

                        img {
                            transform: scale(1.25);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .sliderNav {
        position: fixed;
        width: calc(100% - 75px);
        height: $twoGrid;

        .slide-left,
        .slide-right,
        .slide-burger {
            height: $twoGrid;
            width: $twoGrid;
        }
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .sliderNav {
        z-index: 10;
        width: 100%;
        height: $mobileNavHeight;

        &.scrollUp,
        &.show {
            transform: translateY(-55px) !important;
        }

        .slide-left,
        .slide-right,
        .slide-burger {
            height: $mobileNavHeight;
            width: $mobileNavHeight;
        }
    }
}

@media screen and (max-width: 480px) {
    .slideMenu {
        max-width: 100%;
        min-width: 100%;
        left: 0;
        transform: translate3d(0, 100%, 0);
    }

    .open .slideMenu {
        max-width: 100%;
        min-width: 100%;
        transform: translate3d(0, 0, 0);
    }

    .sliderNav {
        .currentProjects {
            display: none;
        }
    }
}
