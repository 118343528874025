.testimonial {
    position: relative;
    padding: $twoGrid 0;
    display: flex;
    margin-left: $twoGrid;

    .icon-quote {
        display: flex;
        justify-content: center;
        margin: 0 $halfGrid $oneGrid 0;
        width: ($twoGrid - $halfGrid);
        height: ($twoGrid - $halfGrid);
        flex: 0 0 ($twoGrid - $halfGrid);
        background: rgba(93, 245, 210, 0.36);

        @media all and (min-width: 1281px) {
        }

        img {
            width: 55%;
        }
    }

    p.subline {
        font-weight: 400;
        text-align: left;
        font-size: 1.6em;
        line-height: 1;
        font-style: normal;
        opacity: 1;
    }

    @media all and (max-width: 1280px) {
        margin-left: 0;

        .icon-quote {
        }
    }

    @media all and (max-width: $bp-handheld-max) {
        font-size: 0.75em;

        .icon-quote {
            margin-right: $oneGrid;
        }
    }

    @media all and (max-width: 600px) {
        flex-direction: column;

        .icon-quote {
            margin-right: 0;
            flex: 0 0 ($twoGrid + $oneGrid);
            width: ($twoGrid + $oneGrid);
            height: ($twoGrid + $oneGrid);
        }

        blockquote {
            flex: 1 0 100%;
        }
    }
}
