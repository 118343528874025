/* .wf-loading{
  .sideBar{
    transition-delay: 2s;
    width: 80vw;
    transition: $t-medium;
    &:before{
      transform: translateX(0);
      transition-delay: 0s;
    }
    .social{
      opacity: 0;
    }
    .burger{
      opacity: 0;
      span{
        opacity: 0;
      }
    }
  }
  .sliderNav, .sliderNav.scrollUp {
    transform: translateY(120%);
    transition: $t-fast;
    transition-delay: 0s;
  }
} */
html.loading {
    .gr-main,
    .gr-main * {
        transition: none !important;
    }
}

body {
    //overflow: hidden;
    //position: fixed;
}

.logo {
    max-width: 150px;
    transition: $t-medium;
    transition-delay: 1s;
}

.gr-menu,
.mainBar {
    z-index: 100;
    transition: $t-slow;
}

.content {
    position: relative;

    section {
        top: 0;
        display: block;
        position: fixed;
        //left: -200%;
        max-width: 100vw;
        overflow: hidden;
        opacity: 0;
        //transform: translateX(-200%);

        &.active {
            -webkit-overflow-scrolling: touch;
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
            //transform: translateX(0%);
        }

        &.inactive {
            //transform: translateX(-200%);
            opacity: 0;
            height: auto;
            transition: all 0.5s;
        }
    }
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    transform: translate(-50%, -50%) scale(0);
    transition: $t-fast;
    opacity: 0;
}

.resizing {
    section *,
    .gr-main,
    .sideNav,
    .slider {
        transition: none !important;
        transition-delay: 0s !important;
        //opacity: 0 !important;
    }

    .mainBar,
    .mainBar * {
        transition: none !important;
        transition-delay: 0s !important;
    }

    .gr-menu {
        //height: 100vh;
        transition: none !important;
        transition-delay: 0s !important;
    }
}

.wf-loading {
    body {
        overflow: hidden !important;
    }

    .loader {
        transform: translate(-50%, -50%) scale(1);
        transition-delay: 0.35s;
        opacity: 1;
    }

    .gr-menu,
    .mainBar,
    .sideBar {
        width: 100%;
        opacity: 1 !important;
        transition: all 0s;
        transition-delay: 0s;

        .phone {
            opacity: 0;
        }
    }

    .mainBar {
        background-color: $ci-blue-grey !important;
    }

    .logo {
        transition: $t-fast;
        opacity: 0;

        img.big {
            //display: block;
        }

        img.small {
            opacity: 0;
            visibility: hidden;
        }
    }

    .burger,
    .mainNav,
    .social {
        opacity: 0;
        visibility: hidden;
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .wf-loading {
        .mainBar,
        .gr-menu {
            height: 100vh;
        }

        .mainNav {
            opacity: 0;
            transition-delay: 0s;
        }
    }
}

@media screen and (max-width: 479px) {
    .loader {
        transform: translate(-50%, -50%) scale(0);
    }
}
