$oneGrid: 4.166667vw;
$oneGrid-neg: -4.166667vw;
$twoGrid: (($oneGrid) * 2);
$threeGrid: (($oneGrid) * 3);
$fourGrid: (($oneGrid) * 4);
$halfGrid: (($oneGrid) / 2);
$thirdGrid: (($oneGrid) / 3);
$quarterGrid: (($oneGrid) / 4);
$threeGrid: ((($oneGrid) / 3) * 2);
$threeGrid2: ((($halfGrid) / 3) * 2);

$halfGrid-neg: (($oneGrid-neg) / 2);

$oneMainBar-Grid: (($oneGrid) * 4);
$oneSection-Grid: (($oneGrid) * 1.33333);
$mainbarWidth: (($oneGrid) * 5);

$mobileNavHeight: 55px;
$oneGridPerc: ((100% / 18));

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 $halfGrid;

    .row {
        margin: 0 -$halfGrid;
    }

    @media screen and (max-width: 1023px) {
        padding: 0 30px;

        .row {
            margin: 0 -30px;
        }
    }
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.gr-menu {
    position: fixed;
    width: 25%;
    height: 100%;
    z-index: 2;
}

.mainBar,
.sideBar {
    height: 100%;
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    will-change: transform;
}

.mainBar {
    width: (($oneGrid) * 5);
    padding: $oneGrid;
    transition: $t-medium;
}

.sideBar {
    width: $oneGrid;
    left: $mainbarWidth;
    padding: $oneGrid 0 0 0;
    z-index: 1;
    transition: $t-medium;
}

.gr-main {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    position: relative;
    padding: 0;
    margin: 0 0 0 25%;
    min-height: 100vh;
    transition: $t-medium;
    will-change: transform, opacity, filter;
    transform: translateZ(0);
}

.offset-half {
    margin-left: $halfGrid;
}

.offset-1 {
    margin-left: $oneGrid;
}

.offset-2 {
    margin-left: (($oneGrid) * 2);
}

.offset-3 {
    margin-left: (($oneGrid) * 3);
}

.offset-4 {
    margin-left: (($oneGrid) * 4);
}

.col-1 {
    width: $oneGrid;
}

.col-2 {
    width: (($oneGrid) * 2);
}

.col-3 {
    width: (($oneGrid) * 3);
}

.col-4 {
    width: (($oneGrid) * 4);
}

.col-5 {
    width: (($oneGrid) * 5);
}

.col-6 {
    width: (($oneGrid) * 6);
}

.col-7 {
    width: (($oneGrid) * 7);
}

.col-8 {
    width: (($oneGrid) * 8);
}

.col-9 {
    width: (($oneGrid) * 9);
}

.col-10 {
    width: (($oneGrid) * 10);
}

.col-11 {
    width: (($oneGrid) * 11);
}

.col-12 {
    width: (($oneGrid) * 12);
}

.col-13 {
    width: (($oneGrid) * 13);
}

.col-14 {
    width: (($oneGrid) * 14);
}

[class*="col-"] {
    float: left;
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.col2 {
    display: flex;
    flex-wrap: wrap;

    [class*="col-"] {
        flex: 1 0 50%;
        max-width: 50%;
    }
}

.col3 {
    display: flex;
    flex-wrap: wrap;

    [class*="col-"] {
        flex: 1 0 30%;
        max-width: 50%;
    }
}

.col4 {
    display: flex;
    flex-wrap: wrap;

    [class*="col-"] {
        flex: 1 0 25%;
        max-width: 50%;
    }
}

.col5 {
    display: flex;
    flex-wrap: wrap;

    [class*="col-"] {
        flex: 1 0 20%;
        max-width: 50%;
    }
}

.col6 {
    display: flex;
    flex-wrap: wrap;

    [class*="col-"] {
        flex: 1 0 15%;
        max-width: 50%;
    }
}

.social + .social {
    display: none !important;
}

@media screen and (min-width: 1500px) {
    .mainBar {
        padding: $oneGrid (($oneGrid) * 1.5);
    }

    .mainNav li a {
        font-size: 1.25em !important;

        span.analog-icon {
            width: 1.25em !important;
        }
    }
}

@media screen and (max-width: 1280px) {
    .gr-main {
        font-size: 1.2em;
    }
}

@media screen and (max-width: 1023px) {
    .gr-menu {
        width: 75px;
    }

    .gr-main {
        transition: $t-slow;
        //transition-delay: .2s;
        width: calc(100% - 75px);
        margin-left: 75px;
    }

    .sideBar {
        display: none;
    }

    .mainBar {
        padding: 15px;
        width: 75px;
        overflow: hidden;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .gr-menu {
        width: 100%;
        bottom: 0;
    }

    .gr-main {
        width: 100%;
        min-height: 100%;
        margin-left: 0;
        overflow: hidden;
    }

    .sideBar {
        display: none;
    }

    .mainBar {
        padding: 0;
        width: 100%;
        height: auto;
        overflow: visible;
        border: none;
        position: static;
        background-color: $grey-darkest;
    }

    .logo {
        display: none !important;
    }

    .social {
        display: none;
    }
}

@media screen and (min-width: 320px) {
    .offset-xs-half {
        margin-left: $halfGrid;
    }

    .offset-xs-1 {
        margin-left: $oneGrid;
    }

    .offset-xs-2 {
        margin-left: (($oneGrid) * 2);
    }

    .offset-xs-3 {
        margin-left: (($oneGrid) * 3);
    }

    .offset-xs-4 {
        margin-left: (($oneGrid) * 4);
    }

    .col-xs-1 {
        width: $oneGrid;
    }

    .col-xs-2 {
        width: (($oneGrid) * 2);
    }

    .col-xs-3 {
        width: (($oneGrid) * 3);
    }

    .col-xs-4 {
        width: (($oneGrid) * 4);
    }

    .col-xs-5 {
        width: (($oneGrid) * 5);
    }

    .col-xs-6 {
        width: (($oneGrid) * 6);
    }

    .col-xs-7 {
        width: (($oneGrid) * 7);
    }

    .col-xs-8 {
        width: (($oneGrid) * 8);
    }

    .col-xs-9 {
        width: (($oneGrid) * 9);
    }

    .col-xs-10 {
        width: (($oneGrid) * 10);
    }

    .col-xs-11 {
        width: (($oneGrid) * 11);
    }

    .col-xs-12 {
        width: (($oneGrid) * 12);
    }

    .col-xs-13 {
        width: (($oneGrid) * 13);
    }

    .col-xs-14 {
        width: (($oneGrid) * 14);
    }
}

@media screen and (min-width: 480px) {
    .offset-sm-half {
        margin-left: $halfGrid;
    }

    .offset-sm-1 {
        margin-left: $oneGrid;
    }

    .offset-sm-2 {
        margin-left: (($oneGrid) * 2);
    }

    .offset-sm-3 {
        margin-left: (($oneGrid) * 3);
    }

    .offset-sm-4 {
        margin-left: (($oneGrid) * 4);
    }

    .col-sm-1 {
        width: $oneGrid;
    }

    .col-sm-2 {
        width: (($oneGrid) * 2);
    }

    .col-sm-3 {
        width: (($oneGrid) * 3);
    }

    .col-sm-4 {
        width: (($oneGrid) * 4);
    }

    .col-sm-5 {
        width: (($oneGrid) * 5);
    }

    .col-sm-6 {
        width: (($oneGrid) * 6);
    }

    .col-sm-7 {
        width: (($oneGrid) * 7);
    }

    .col-sm-8 {
        width: (($oneGrid) * 8);
    }

    .col-sm-9 {
        width: (($oneGrid) * 9);
    }

    .col-sm-10 {
        width: (($oneGrid) * 10);
    }

    .col-sm-11 {
        width: (($oneGrid) * 11);
    }

    .col-sm-12 {
        width: (($oneGrid) * 12);
    }

    .col-sm-13 {
        width: (($oneGrid) * 13);
    }

    .col-sm-14 {
        width: (($oneGrid) * 14);
    }
}

@media screen and (min-width: $bp-handheld-min) {
    .offset-md-half {
        margin-left: $halfGrid;
    }

    .offset-md-1 {
        margin-left: $oneGrid;
    }

    .offset-md-2 {
        margin-left: (($oneGrid) * 2);
    }

    .offset-md-3 {
        margin-left: (($oneGrid) * 3);
    }

    .offset-md-4 {
        margin-left: (($oneGrid) * 4);
    }

    .col-md-1 {
        width: $oneGrid;
    }

    .col-md-2 {
        width: (($oneGrid) * 2);
    }

    .col-md-3 {
        width: (($oneGrid) * 3);
    }

    .col-md-4 {
        width: (($oneGrid) * 4);
    }

    .col-md-5 {
        width: (($oneGrid) * 5);
    }

    .col-md-6 {
        width: (($oneGrid) * 6);
    }

    .col-md-7 {
        width: (($oneGrid) * 7);
    }

    .col-md-8 {
        width: (($oneGrid) * 8);
    }

    .col-md-9 {
        width: (($oneGrid) * 9);
    }

    .col-md-10 {
        width: (($oneGrid) * 10);
    }

    .col-md-11 {
        width: (($oneGrid) * 11);
    }

    .col-md-12 {
        width: (($oneGrid) * 12);
    }

    .col-md-13 {
        width: (($oneGrid) * 13);
    }

    .col-md-14 {
        width: (($oneGrid) * 14);
    }
}

@media screen and (min-width: 1024px) {
    .offset-lg-half {
        margin-left: $halfGrid;
    }

    .offset-lg-1 {
        margin-left: $oneGrid;
    }

    .offset-lg-2 {
        margin-left: (($oneGrid) * 2);
    }

    .offset-lg-3 {
        margin-left: (($oneGrid) * 3);
    }

    .offset-lg-4 {
        margin-left: (($oneGrid) * 4);
    }

    .col-lg-1 {
        width: $oneGrid;
    }

    .col-lg-2 {
        width: (($oneGrid) * 2);
    }

    .col-lg-3 {
        width: (($oneGrid) * 3);
    }

    .col-lg-4 {
        width: (($oneGrid) * 4);
    }

    .col-lg-5 {
        width: (($oneGrid) * 5);
    }

    .col-lg-6 {
        width: (($oneGrid) * 6);
    }

    .col-lg-7 {
        width: (($oneGrid) * 7);
    }

    .col-lg-8 {
        width: (($oneGrid) * 8);
    }

    .col-lg-9 {
        width: (($oneGrid) * 9);
    }

    .col-lg-10 {
        width: (($oneGrid) * 10);
    }

    .col-lg-11 {
        width: (($oneGrid) * 11);
    }

    .col-lg-12 {
        width: (($oneGrid) * 12);
    }

    .col-lg-13 {
        width: (($oneGrid) * 13);
    }

    .col-lg-14 {
        width: (($oneGrid) * 14);
    }
}

@media screen and (min-width: 1280px) {
    .offset-xl-half {
        margin-left: $halfGrid;
    }

    .offset-xl-1 {
        margin-left: $oneGrid;
    }

    .offset-xl-2 {
        margin-left: (($oneGrid) * 2);
    }

    .offset-xl-3 {
        margin-left: (($oneGrid) * 3);
    }

    .offset-xl-4 {
        margin-left: (($oneGrid) * 4);
    }

    .col-xl-1 {
        width: $oneGrid;
    }

    .col-xl-2 {
        width: (($oneGrid) * 2);
    }

    .col-xl-3 {
        width: (($oneGrid) * 3);
    }

    .col-xl-4 {
        width: (($oneGrid) * 4);
    }

    .col-xl-5 {
        width: (($oneGrid) * 5);
    }

    .col-xl-6 {
        width: (($oneGrid) * 6);
    }

    .col-xl-7 {
        width: (($oneGrid) * 7);
    }

    .col-xl-8 {
        width: (($oneGrid) * 8);
    }

    .col-xl-9 {
        width: (($oneGrid) * 9);
    }

    .col-xl-10 {
        width: (($oneGrid) * 10);
    }

    .col-xl-11 {
        width: (($oneGrid) * 11);
    }

    .col-xl-12 {
        width: (($oneGrid) * 12);
    }

    .col-xl-13 {
        width: (($oneGrid) * 13);
    }

    .col-xl-14 {
        width: (($oneGrid) * 14);
    }
}
