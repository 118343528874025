.mainNav {
    ul {
        li {
            .mobileNav {
                display: none;
                overflow: hidden;
            }
        }
    }
}

.burgernav {
    display: none;
}

@media screen and (max-width: $bp-handheld-max) {
    .mainBar .burgernav {
        cursor: pointer;
        display: flex;

        .burger {
            display: block;
            transform: none;
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            margin: 0;
            color: $ci-green-bright;
            width: 24px;
        }
    }

    .mobileNav-open {
        .burgernav {
            .burger {
                transform: rotate(-135deg);
            }
        }
    }

    .mainNav {
        ul {
            li {
                .mobileNav {
                    position: absolute;
                    right: 0;
                    bottom: 100%;
                    width: 100%;
                    display: block;
                    box-sizing: content-box;
                    padding: 20px 0 0 20px;
                    visibility: hidden;

                    ul {
                        box-shadow: 0 0 19px rgba(34, 117, 147, 0.55);
                        transform: translateY(120%);
                        transition: $t-medium;

                        @include gradient_ci-Bright;

                        li {
                            a:not(.analog-icon) {
                                padding: 15px;
                                color: #fff;
                                transition: $t-fast !important;
                                transition-delay: 0s;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.25);

                                &:hover,
                                &:focus,
                                &:active {
                                    background: transparentize($ci-green-bright, 0.75);
                                }
                            }

                            &:last-child {
                                a {
                                    border: none;
                                }
                            }

                            &.socials {
                                display: flex;
                                justify-content: center;

                                a {
                                    font-size: 2.5em;
                                    padding: 0.5em 0.75em;
                                    flex: 0;
                                }
                            }
                        }
                    }

                    .mobileNav-open & {
                        visibility: visible;

                        ul {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .mainNav {
        ul {
            li {
                .mobileNav {
                    width: 100%;
                }
            }
        }
    }
}
