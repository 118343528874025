.tunnel {
    background: $ci-blue-grey;
    width: 100vw;
    height: 100vh;
    padding: 0 20vw 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
    font-size: 1rem;

    @media all and (max-width: 600px) {
        padding: 0 30px 5vh;
    }

    .content {
        position: relative;
        width: 100%;
        max-width: 700px;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    .analogo {
        margin: 17.5vh 0 5vh;
        flex: 1 0 100%;
        text-align: center;

        img,
        svg {
            width: 12.5em;
            max-width: 175px;
            height: auto;
            margin: 0 auto;
        }
    }

    .claim {
        position: relative;
        width: 100%;
        margin: 0 0 6.5vh;

        svg {
            width: 100%;

            text {
                font-family: "analog", sans-serif;
                font-weight: 250;
                text-transform: uppercase;
                font-size: 27.6px;
                fill: url(#logo-gradient);
                transform: translate(0, 22px);

                tspan {
                    fill: #fff;
                }
            }
        }
    }

    .typo3 {
        position: absolute;
        right: 0;
        top: 0;
        width: 4%;
        transform: translate(100%, -100%);

        svg {
            width: 100%;
            height: auto;
        }
    }

    .tunnelnav {
        width: 100%;
        position: relative;
        overflow: hidden;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid transparentize(#fff, 0.5);
            border-left: none;
            border-right: none;
            transition: $t-medium;

            &:hover {
                //border-color: transparent;
            }

            li {
                width: 50%;

                a {
                    cursor: pointer;
                    display: block;
                    font-size: 1.75em;
                    height: 3.2em;
                    font-weight: 400;
                    padding: 1em 0;
                    color: transparentize(#fff, 0.5);
                    transition: $t-medium;
                    position: relative;

                    @media all and (min-width: 1600px) {
                        font-size: 24px;
                    }

                    @media all and (max-width: 450px) {
                        font-size: 1.35em;
                        text-align: center;

                        span {
                            position: static !important;
                            transform: none !important;
                        }
                    }

                    span {
                        pointer-events: none;
                        display: inline-block;
                        transition: $t-medium;
                        position: relative;
                        left: 0;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: $ci-green-bright;

                        span {
                            right: 50%;
                            transform: translateX(-100%);
                            transition-delay: 0.15s;
                        }
                    }
                }

                &:first-child {
                    padding-right: 1.5em;

                    a {
                        span {
                            left: 100%;
                            transform: translateX(-100%);
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $ci-green-bright;

                            span {
                                left: 50%;
                                transform: translateX(-50%);
                                transition-delay: 0.15s;
                            }
                        }
                    }
                }

                &:last-child {
                    text-align: left;
                    padding-left: 1.5em;

                    a {
                        &:hover,
                        &:focus,
                        &:active {
                            span {
                                right: auto;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
        }

        #highlight {
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            height: 100%;
            color: $ci-green-bright;
            transition: all 0.5s;
            border-top: 2px solid currentColor;
            border-bottom: 2px solid currentColor;

            &.out {
                opacity: 0;
            }

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                display: block;

                @include gradient_ci-Bright;

                background-attachment: scroll;
                opacity: 0.35;
            }
        }
    }

    .social {
        bottom: 0;
        transform: translateY(7rem);
        display: block;

        @media all and (max-height: 700px) {
            display: none;
        }

        a {
            padding: 0;
            width: 2em;
            height: 2em;
            line-height: 1.9em;
            border: 2px solid rgba(185, 193, 199, 0.35);
            border-radius: 50%;
            text-decoration: none !important;
            font-size: 2em;
        }
    }

    .impressum {
        color: transparentize(#fff, 0.5);
        display: flex;
        position: absolute;
        bottom: $oneGrid;
        right: $oneGrid;
        text-decoration: none;
        font-size: 1em;

        a {
            opacity: 0.5;
            transition: all 0.35s;

            &:hover {
                opacity: 1;
            }
        }

        .divider {
            margin: 0 0.5em;
            color: $ci-green-bright;
        }

        @media all and (min-width: 601px) {
            transform: rotate(90deg) translate3d(0, 0, 0);
            transform-origin: 100% 0;
            letter-spacing: 0.06em;
            width: 100%;
            bottom: 1rem;
            margin: 0;
            right: 1.8rem;
            justify-content: flex-end;
        }

        @media all and (max-width: 600px) {
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            bottom: 15px;
        }

        &:hover {
            color: #fff;
        }
    }

    &.hidden {
        transition: $t-medium;
        transform: translateY(-100%);

        .analogo,
        .claim,
        .tunnelnav,
        .social {
            transform: translateY(-30vh);
            opacity: 0;
            transition: $t-medium;
        }

        .analogo {
            transition-delay: 0.1s;
        }

        .claim {
            transition-delay: 0.25s;
        }

        .tunnelnav {
            transition-delay: 0.35s;
        }

        .social {
            transition-delay: 0.4s;
        }

        transition-delay: 0.75s;
    }
}
