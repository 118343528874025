//==========Appear==========//
@mixin softAppear {
    opacity: 0;
    transition: $t-slow;
    transition-delay: 0s;
    transform: translateY(30px);

    &.appeared {
        transform: translateX(0);
        opacity: 1;
    }
}

@mixin noAppear {
    opacity: 1;
    transform: translateY(0);

    &.appeared {
        transform: translateX(0);
        opacity: 1;
    }
}

p {
    @include softAppear;
}

@media (min-width: 320px) {
    section {
        &.imageHeader,
        &.jumpNav {
            transform: translateY(0);
            opacity: 1;
        }

        p,
        > h3,
        article,
        .h2 {
            @include softAppear;
        }

        h1 + p.intro,
        h1 + p:first-child,
        h1 + p.intro,
        p:first-of-type {
            transition-delay: 0.75s;
        }

        h1 + p:first-child,
        h1 + p.intro + p {
            transition-delay: 0.9s;
        }

        h2 {
            transition: $t-medium;
            transform: translateX(10vw);
            opacity: 0;

            &:before {
                opacity: 0;
                transition: $t-fast;
            }

            &.appeared {
                transition-delay: 0.4s;
                transform: translateX(0);
                opacity: 1;
                height: auto !important;

                &:before {
                    opacity: 1;
                    transform: none;
                    transition-delay: 0.6s;
                }

                .shuffled {
                    height: auto !important;
                }
            }
        }
    }

    .msIE {
        section:not(.fixedBgImage),
        footer .liga-logo,
        footer .ksc-logo,
        .sponsoren .sponsor,
        .hauptsponsor .klaiber-logo,
        .textElement {
            transform: none;
            opacity: 1;
        }
    }
}

@media (min-width: 1024px) {
    section h2.appeared {
        transform: translateX(0);
    }
}

@media (min-width: 1280px) {
    section h2.appeared {
        transform: translateX(0);
    }
}
