::selection {
    background: transparent;
    color: $ci-green;
}

::-moz-selection {
    background: transparentize($ci-green, 0.5);
    background: transparent;
    color: $ci-green;
}

section {
    color: #fff;

    h1,
    .h1 {
        font-size: 10em;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        font-weight: 800;
        padding: 0;
        line-height: 1;
        position: relative;
        margin: 0 0 6vw;
        //transform: translateX(-0.9%);

        span {
            display: block;
            margin-bottom: 0.25em;
            left: 0.4%;
            bottom: 100%;
            line-height: 1;
            color: $ci-green;
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.33333em;
            text-transform: uppercase;
        }

        @media screen and (max-width: 600px) {
            span{

            }
        }
    }

    h2,
    .h2 {
        font-size: 3.6em;
        letter-spacing: -0.03em;
        text-transform: none;
        font-weight: 700;
        line-height: 1;
        margin: $oneGrid 0 $halfGrid 0;
        padding: 0 0 0 0.5em;
        position: relative;

        + p {
            margin-top: 0 !important;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0.15em;
            background: $ci-green;
            transform: translateX(-2em);
            opacity: 0;
        }

        span {
            opacity: 0.4;

            &.shuffled {
                opacity: 1;
            }
        }
    }

    h3,
    .h3 {
        font-size: 2.25em;
        margin-top: 0;
    }

    p,
    ul,
    ol {
        font-weight: 200;
        font-size: 1.8em;
        line-height: 1.35;
        margin: $oneGrid 0;
        letter-spacing: 0.015em;

        + p {
            margin-top: calc(#{$halfGrid} * -1);
        }

        a {
            color: #fff;
            position: relative;
            background-image: linear-gradient(120deg, $ci-green 0%, $ci-green-bright 100%);
            background-repeat: no-repeat;
            background-size: 100% 0.125em;
            background-position: 0 95%;
            transition: background-size 0.2s, color 0.2s;
            text-decoration: none;

            &:before {
                //content:"";
                display: block;
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: $ci-blue;
            }

            &:hover,
            &:focus {
                color: $ci-blue;
                background-size: 100% 100%;
            }
        }

        strong,
        b {
            font-weight: 600;
        }

        &.intro {
            font-weight: 300;
            font-style: italic;
            font-size: 2.25em;
            line-height: 1.45;
            margin-top: -0.75em;
        }
    }

    blockquote {
        font-size: 1em;
        margin: 0;
        padding: 0;

        p {
            margin-left: 0;
            font-size: 3.2em;
            font-weight: 200;
            font-style: italic;
            text-align: left;
            line-height: 1.2;
            margin-bottom: 1.2em;
        }
    }

    ol {
        @include orderedList;
    }
}

@media all and (min-width: 1281px) {
    section {
        h2,
        .h2 {
            margin-left: $twoGrid;
        }

        h3,
        .h3 {
            margin-left: $twoGrid;
        }

        p,
        ul {
            margin-left: $twoGrid;
        }
    }
}

@media all and (max-width: 1280px) {
    section {
        h1,
        .h1 {
            font-size: 8vw;
        }

        h2,
        .h2 {
            margin-left: 0;

            &:before {
                transform: translateX(-2vw);
            }

            &.appeared {
                &:before {
                    transform: translateX(0);
                }
            }
        }

        p,
        ul {
            margin-left: $twoGrid;
        }
    }
}

@media all and (max-width: 1024px) {
    section {
        h1,
        .h1 {
            font-size: 10vw;
        }

        h2,
        .h2 {
            font-size: 5vw;
            margin-top: 10vw;
            margin-bottom: 4vw;
        }
    }
}

@media all and (max-width: 1023px) {
    section {
        h1,
        .h1 {
            margin-bottom: $oneGrid;
        }

        h2,
        .h2 {
            margin: $twoGrid 0 $oneGrid;

            > span,
            > div {
                &:last-child {
                    color: $ci-green-bright;
                }
            }

            &:before {
                transform: translateX(0);
                //display: none;
            }
        }

        p,
        ul {
            font-size: 1.75em;
            margin-bottom: $oneGrid;
            margin-left: 0;
            font-weight: 200;

            &.intro {
                font-size: 2.25em;
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 800px) {
    section p {
        font-size: 1.4em;
    }
}

@media all and (max-width: $bp-handheld-max) {
    section {
        h1,
        .h1 {
            font-size: 4em;
        }

        h2,
        .h1 {
            font-size: 3em;

            &:before {
            }
        }

        p {
            &.intro {
                font-size: 2em;
                line-height: 1.45;
            }
        }
    }
}

@media all and (max-width: 600px) {
    section {
        h1,
        .h1 {
            font-size: 6em;
        }

        h2,
        .h1 {
            font-size: 2.5em;
            margin-top: $fourGrid;

            &:before {
            }
        }

        p {
            &.intro {
                font-size: 2em;
                line-height: 1.45;
            }
        }
    }
}

@media all and (max-width: 480px) {
    section {
        h1,
        .h1 {
            font-size: 6em;
        }

        p {
            &.intro {
                font-size: 2em;
                margin-top: 0;
            }
        }
    }
}

@media all and (max-width: 414px) {
    section {
        h1,
        .h1 {
            font-size: 5em;
        }

        h2,
        .h2 {
            font-size: 2.5em;
        }
    }
}

@media (max-width: 374px) {
    section {
        h1,
        .h1 {
            font-size: 4.4em;
        }
    }
}
