$font-path: "../../../Fonts/analog" !default;

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=abb16308-47ef-477f-8656-798a7af7ffde");


@font-face {
    font-family: "analog";
    src: url("#{$font-path}/a2594b9c-4d47-481f-bf13-74ae95f6814e.eot?#iefix");
    src: url("#{$font-path}/a2594b9c-4d47-481f-bf13-74ae95f6814e.eot?#iefix") format("eot"), url("#{$font-path}/0501f5b5-8008-430d-a121-fb527cf99f94.woff2") format("woff2"), url("#{$font-path}/90b3832f-e8fd-4abb-abb1-12f52290b297.woff") format("woff"), url("#{$font-path}/ab069724-4e8f-40e8-9064-f694b4cfdd37.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/fb33381b-ecac-4533-a3c2-a04668af2029.eot?#iefix");
    src: url("#{$font-path}/fb33381b-ecac-4533-a3c2-a04668af2029.eot?#iefix") format("eot"), url("#{$font-path}/60109bd1-cd58-44f6-9587-c51f1c2ea19f.woff2") format("woff2"), url("#{$font-path}/5d79bc83-7ce2-4fa3-9e10-7812a4e32469.woff") format("woff"), url("#{$font-path}/01ae4fb4-318c-4535-b8a0-b9b4855c211e.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/015d19d6-7748-4143-962f-494b650b2340.eot?#iefix");
    src: url("#{$font-path}/015d19d6-7748-4143-962f-494b650b2340.eot?#iefix") format("eot"), url("#{$font-path}/304a53be-b1e9-48d6-ad5b-a6eb1740a78f.woff2") format("woff2"), url("#{$font-path}/25819e06-46fe-4013-b85a-eed3e44d5a2c.woff") format("woff"), url("#{$font-path}/2925287b-037d-4c75-9c61-cd4d318e581f.ttf") format("truetype");
    font-weight: 250;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/f2452202-0941-43c8-a16e-fb3f3af4c418.eot?#iefix");
    src: url("#{$font-path}/f2452202-0941-43c8-a16e-fb3f3af4c418.eot?#iefix") format("eot"), url("#{$font-path}/9dc3bddc-c188-4433-b902-6a54d9fe6cad.woff2") format("woff2"), url("#{$font-path}/650e6d25-ea30-490e-8e38-465da5c5fa3b.woff") format("woff"), url("#{$font-path}/766c76b9-0c16-4ff8-8a9a-1735a491b87c.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/d53d548d-e050-4dd0-aacb-0d998952d14b.eot?#iefix");
    src: url("#{$font-path}/d53d548d-e050-4dd0-aacb-0d998952d14b.eot?#iefix") format("eot"), url("#{$font-path}/cb8bce68-3c81-4d94-a7ea-1635ab689637.woff2") format("woff2"), url("#{$font-path}/f71d640e-41c0-4c89-b999-fae92e3c00eb.woff") format("woff"), url("#{$font-path}/7aca3abd-ff6c-4391-9642-e87c9e4d7982.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/aefcc5ee-a012-48c3-9ebf-731f07bb3cd3.eot?#iefix");
    src: url("#{$font-path}/aefcc5ee-a012-48c3-9ebf-731f07bb3cd3.eot?#iefix") format("eot"), url("#{$font-path}/28a23213-3346-41f7-8f14-71d464d767ea.woff2") format("woff2"), url("#{$font-path}/9283fcc2-ec46-4d69-9278-187c1ce28dd8.woff") format("woff"), url("#{$font-path}/58906072-d870-44da-8fd6-73ff545ecbb6.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/84b3b337-f678-4043-85ec-4fe614e8eb9d.eot?#iefix");
    src: url("#{$font-path}/84b3b337-f678-4043-85ec-4fe614e8eb9d.eot?#iefix") format("eot"), url("#{$font-path}/f1a2a077-35d2-4c0b-b6bc-d3f83a36287f.woff2") format("woff2"), url("#{$font-path}/be3407b0-afb2-4c9e-ad46-e285ed2ff795.woff") format("woff"), url("#{$font-path}/37e76ca2-6002-4a18-9604-3997d2289119.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/57d58f8d-c7cb-46d5-a101-c4fed6b2b298.eot?#iefix");
    src: url("#{$font-path}/57d58f8d-c7cb-46d5-a101-c4fed6b2b298.eot?#iefix") format("eot"), url("#{$font-path}/64e5d7b5-0c34-4a8b-9040-2a0c9cbab751.woff2") format("woff2"), url("#{$font-path}/0c9565d2-8853-4e0b-8968-3d5a35e641f8.woff") format("woff"), url("#{$font-path}/daa6682c-3400-43ee-98cd-8c8482fcb7ff.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/be3d9415-ca0f-4fe1-b2f5-85c4498f554a.eot?#iefix");
    src: url("#{$font-path}/be3d9415-ca0f-4fe1-b2f5-85c4498f554a.eot?#iefix") format("eot"), url("#{$font-path}/666e80fa-4d40-441c-9130-d91b9c95f741.woff2") format("woff2"), url("#{$font-path}/7cf1577f-c5ee-4f35-9755-02453d0865b9.woff") format("woff"), url("#{$font-path}/49f7071a-63ba-450f-9256-ef6b1196e41f.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/*
@font-face {
  font-family: "analog";
  src: url("#{$font-path}/9b5eb463-b285-4c0a-a855-2fcb2611b130.eot?#iefix");
  src: url("#{$font-path}/9b5eb463-b285-4c0a-a855-2fcb2611b130.eot?#iefix") format("eot"), url("#{$font-path}/8010df30-8aa3-4174-b955-5baf1092e203.woff2") format("woff2"), url("#{$font-path}/07afaa6b-f1d1-44d8-a722-66fc064ba98f.woff") format("woff"), url("#{$font-path}/4ce15e5c-d502-4ff9-bcae-63fcd27694cb.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;

}
 */

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/3ebf1cec-a6e6-4b55-a2c8-e500aaf2b030.eot?#iefix");
    src: url("#{$font-path}/3ebf1cec-a6e6-4b55-a2c8-e500aaf2b030.eot?#iefix") format("eot"), url("#{$font-path}/6130b850-872c-4bc5-9d06-ab6201cdbcec.woff2") format("woff2"), url("#{$font-path}/d25e3f4f-6e02-485f-bc92-eb37a6a216d8.woff") format("woff"), url("#{$font-path}/a70921c9-cca7-4da7-b8d7-bbe312822b3f.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/f32e46b9-e1d8-4805-bbe0-396647493474.eot?#iefix");
    src: url("#{$font-path}/f32e46b9-e1d8-4805-bbe0-396647493474.eot?#iefix") format("eot"), url("#{$font-path}/36b4f1d2-1f44-4b78-aac4-0a62af15ba3a.woff2") format("woff2"), url("#{$font-path}/6ba49908-4dab-4637-9f2c-03228457d145.woff") format("woff"), url("#{$font-path}/724844fe-811e-4ba5-a9cf-2780718c85b7.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/7c930cfc-b9ab-46fb-bbda-d779be21ac79.eot?#iefix");
    src: url("#{$font-path}/7c930cfc-b9ab-46fb-bbda-d779be21ac79.eot?#iefix") format("eot"), url("#{$font-path}/bf3aafc4-3b84-44de-8fca-a88a40eeb2a5.woff2") format("woff2"), url("#{$font-path}/b81db7c8-3b90-4e82-a443-f50178db0958.woff") format("woff"), url("#{$font-path}/dc9738fb-10ff-44f5-8e7a-f7ca8f908af8.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "analog";
    src: url("#{$font-path}/139251fe-7a53-4f16-a515-699ff7afbd0f.eot?#iefix");
    src: url("#{$font-path}/139251fe-7a53-4f16-a515-699ff7afbd0f.eot?#iefix") format("eot"), url("#{$font-path}/84a50ec3-c094-4387-ad89-f8e67a5255f0.woff2") format("woff2"), url("#{$font-path}/8f13e53e-dd2c-4dcb-ab34-e2206bd2f58c.woff") format("woff"), url("#{$font-path}/f0bd180c-dd36-4c5b-9e80-4f649ec26c03.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
