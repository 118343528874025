section {
    &.news {
        > .h1 {
            b {
            }
        }
    }

    article,
    div {
        &.newsArticle {
            margin-bottom: $twoGrid;

            @media all and (min-width: 1024px) {
                margin-left: $twoGrid;
                margin-bottom: $twoGrid;
            }

            @media all and (min-width: 1281px) {
                margin-left: $fourGrid;
                margin-bottom: $twoGrid;
            }

            h3 {
                font-size: 2.75em;
            }

            p,
            h3,
            .bigButton {
                margin-left: 0;
            }

            .newsLatest_img {
                margin-bottom: $halfGrid;

                &.floating_img {
                    @media all and (min-width: 680px) {
                        float: right;
                        margin: 0 0 $halfGrid $halfGrid;
                        max-width: calc(50% - #{$halfGrid});
                    }
                }
            }
        }

        &.newsDetail,
        &.newsList {
            > .h2:first-child {
                color: $ci-green-bright;
                display: none;

                span {
                    opacity: 1;
                    color: #fff;
                }
            }

            .h2 {
                margin: 0;
                font-size: 3.33333em;

                @media all and (min-width: 1024px) {
                    margin: $halfGrid 0 0 0;
                }


                &:before {

                }

                + h1,
                + .h1 {
                    margin-top: 0;
                    transform: none;
                }
            }

            .h2.subheader {
                margin-bottom: $oneGrid;
            }

            p {
                margin-left: 0;
                padding-left: 0;
            }

            h3.h2 {
                @media all and (min-width: 1024px) {
                    margin-left: (($oneGrid) * -1);
                }

                margin-top: $halfGrid;
                margin-bottom: $halfGrid;
                //color: $ci-green-bright;
                span {
                    opacity: 1;
                    color: currentColor;
                }
            }
        }

        .newsDetail--img {
            margin-bottom: $halfGrid;
        }

        .newsDetail-imagebox {
            display: flex;
            flex-wrap: wrap;
            margin: 0 (($quarterGrid) * -1);

            .col-6 {
                width: 50%;
                flex: 1 0 50%;
                padding: 0 $quarterGrid $halfGrid;
            }
        }

        a.news--listitem {
            display: block;
            border-left: 0.5em solid $ci-green-bright;
            padding-left: 1.5em;
            margin-bottom: $oneGrid;
            text-decoration: none;

            * {
                text-decoration: none;
            }

            &:-webkit-any-link,
            &:link {
                color: #fff;
                text-decoration: none;
            }

            h2,
            .h2 {
                margin-bottom: $quarterGrid;
                position: relative;
                color: #fff;
            }

            p {
                transform: $t-fast !important;
                transition-delay: 0s;
                margin: 0 0 $halfGrid;
                color: #fff;
            }

            .date {
                font-weight: 800;
                font-size: 0.45em;
                line-height: 1;
                text-align: right;
                color: $ci-green-bright;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d((($oneGrid) * -1), 7%, 0);
                display: none !important;

                @media all and (max-width: 1024px) {
                    display: block;
                    position: static;
                    transform: none;
                    text-align: left;
                    margin-bottom: (($quarterGrid) / 2);

                    div {
                        display: inline;
                    }
                }
            }
        }

        .news--listitem_footer {
            display: flex;
            text-decoration: none;
            color: $ci-green-bright;

            span {
                box-sizing: content-box;
                color: currentColor;
                text-decoration: none;
                font-weight: 600;
                font-size: 1.6em;
                min-width: 6.5em;
                padding: 0;
                text-transform: uppercase;
                transition: $t-fast;

                @include link-arrow;
            }
        }
    }
}

body {
    .newsList {
        @media all and (min-width: 1024px) {
            margin-left: $twoGrid;
        }
    }

    .news--body {
        &.list {
            @include col3;

            @media all and (min-width: 768px) {
                .col {
                    flex: 1 0 50%;
                    max-width: 50%;
                }
            }
        }
    }

    section div {
        .news--listitem,
        .news--listitem:link {
            border: none;
            transition: $t-fast;

            &:hover {
                transform: scale(1.07);
                box-shadow: 0 0.5em 3em 0.4em rgba(33, 109, 136, 0.55);
            }

            .inner {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: $halfGrid;
                background: transparentize($ci-blue, 0.4);
                color: $ci-blue-grey;

                @media screen and (max-width: 1023px) {
                    padding: $oneGrid;
                }

                @media screen and (max-width: 550px) {
                    padding: $twoGrid;
                }

                h2,
                .h2 {
                    margin-top: 0;
                    font-size: 2.5em;
                    color: $ci-green;
                    padding-left: 0;

                    &:before {
                        content: none;
                    }
                }

                p {
                    font-size: 1.4em;
                    font-weight: 200;
                    color: #fff;
                }

                .news--listitem_footer {
                    margin-top: auto;
                    color: #fff;
                }
            }
        }
    }

    article.newsDetail {
        .news--body {
            @media screen and (min-width: 1281px) {
                margin-left: $twoGrid;
            }
        }

        h1 {
            font-size: 8em;
            line-height: 0.8;
        }

        .h2.date {
            display: block !important;
            margin: 0.25em 0 0;
            padding: 0;

            + h1 {
                margin-top: 0 !important;
            }
        }

        .news--text {
            @include softAppear;

            p {
                @include noAppear;
            }
        }

        @media screen and (max-width: 550px) {
            h1 {
                font-size: 5em;
            }

            h2 {
                font-size: 2.5em;
            }
        }
    }

    &.home {
        @media screen and (min-width: 768px) {
            .news--body.list > * {
                max-width: 100%;
            }
        }
    }
}
