.slider {
    z-index: 10;
}

.imgSlider {
    margin-top: $oneGrid;
    margin-left: $twoGrid;

    figure.slick-slide {
        background: #eef0f2;
    }
}

.slick-arrow {
    position: absolute;
    z-index: 10;
    background: $grey-lighter;
    color: $grey-dark;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    right: -45px;
    top: 0;
    text-align: center;
    transition: $t-fast;
    cursor: pointer;

    &.slick-prev {
        transform: rotate(-90deg);
        top: 40px;
        margin-top: 1px;
    }

    &.slick-next {
        transform: rotate(90deg);
    }

    &:hover,
    &:focus {
        background: #fff;
        color: $ci-blue;
    }
}

ul.slick-dots {
    font-size: 2em;
    position: relative;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0.5em 0 2em;

    li {
        cursor: pointer;
        margin: 0 0.3em;
        padding: 0;
        list-style: none;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: transparentize(#fff, 0.5);
        border: none;
        transition: $t-fast;
        position: relative;

        &:before {
            @include centerVertHorz;

            position: absolute;
            display: block;
            content: "";
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 1px solid;
            transition: $t-fast;
            transform: translate3d(-50%, -50%, 0) scale(0.3);
            opacity: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            transform: scale(1.25);

            &:before {
                opacity: 1;
                transform: translate3d(-50%, -50%, 0) scale(1);
            }
        }

        &.slick-active {
            background: #fff;
            cursor: default;

            &:hover,
            &:focus {
                transform: scale(1);
            }
        }


        button {
            display: none;
        }
    }
}

@media screen and (max-width: 1024px) {
    .imgSlider {
        margin-left: 0;
    }
}

@media screen and (max-width: 1024px) {
    .slick-arrow {
        display: none !important;
        width: $twoGrid;
        height: $twoGrid;
        line-height: $twoGrid;
        right: -$twoGrid;

        &.slick-prev {
            top: $twoGrid;
        }

        &.slick-next {
        }
    }
}
