@media screen and (min-width: $bp-handheld-min) and (max-width: 1023px) {
    .mainBar {
        transition: $t-medium;

        .mainNav {
            min-width: 160px;
            opacity: 0;
            visibility: hidden;
            transition: $t-fast;
            transition-delay: 0s;
            margin-left: 12px;
            transform: translateX(-25px);
            left: 0;
        }

        .logo {
            width: 43px;
            padding: 0;
            left: auto;
            transform: none;
        }

        &.open {
            width: (($twoGrid) * 3);

            .mainNav {
                transform: translateX(0);
                visibility: visible;
                opacity: 1;
                transition: $t-medium;
                transition-delay: 0.25s;
            }
        }

        .social {
            position: absolute;
            width: 100%;
            left: 0;
            border: none;
            bottom: 22px;

            a {
                display: block;
            }
        }
    }

    .tabletNav-open {
        .gr-main {
            transform: translateX(calc(25vw - 75px));
            opacity: 0.15;
            transition: $t-slow;
        }
    }
}

@media screen and (min-width: $bp-handheld-min) and (max-width: 768px) {
    .mainBar {
        &.open {
            width: 50vw;
        }
    }
}
