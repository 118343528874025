.gr-menu {
    z-index: 31;
    transition: $t-medium;
}

.mainBar {
    background: url("../../../Images/Scss-Assets/patternBG_01.png") $ci-blue-grey;
    color: #fff;
    will-change: transform;
    transform: translate3d(0, 0, 0);

    .phone,
    .imprint {
        display: none;
        text-align: center;
        position: absolute;
        bottom: 4em;
        left: 0;
        width: 100%;
        padding: 0 $oneGrid;
        transition: $t-medium;
        transition-delay: 1s;

        span {
            display: block;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.95em;
            color: $ci-green;
        }

        a,
        .divider {
            font-size: 1.3em;
            color: #fff;
            text-decoration: none;
        }
    }
}

.impressum,
.impressum.bottomInfo,
.imprint.mobile {
    display: flex;
    justify-content: center;
    color: #fff;
    margin-top: 20px;

    .divider {
        margin: 0 0.25em;
    }

    a,
    .divider {
        font-size: 0.9em;
        transition: color 0.25s;
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $ci-green-bright;
        }

        @media all and (max-width: 1299px) {
            font-size: 1.1em;
        }
    }
}

.imprint.mobile {
    display: none;

    @media screen and(max-width: 1023px) {
        display: flex;
    }
}

.sideBar {
    text-align: center;
    background: white;
    transform: translate3d(0, 0, 0);
    will-change: transform;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @include gradient_ci;

        transform: translateX(-100%);
        transition: all 0.75s ease-in-out;
        transition-delay: 0.25s;
    }

    .social a {
        font-size: 1.5em;
        margin: 0.75em 0.3em 0;
        display: block;

        &:hover {
            color: $ci-blue;
        }
    }
}

.logo {
    display: block;
    padding: 10px 10px 0;
    margin-top: 5px;
    top: 0;

    @include centerVert;

    .big {
        display: none;
    }

    .small {
        display: block;
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: 1499px) {
    .logo {
        transform: translateX(-50%) scale(0.75);
        transform-origin: center top;
    }
}

@media screen and (min-width: $bp-handheld-min) {
}

@media screen and (min-width: 1024px) {
    .logo {
        margin: 0;
        padding: 0;

        .big {
            display: block;
        }

        .small {
            display: none;
        }
    }

    .mainBar {
        .mainNav,
        .social {
            display: none;
        }

        .phone {
            display: block;
        }
    }

    .sideBar {
        transition-delay: 0.25s;
    }

    .gr-main {
        transition-delay: 0.35s;
    }

    .desktopNav-open {
        .sideBar {
            width: (($oneGrid) * 5);
            transition-delay: 0s;
        }

        .gr-main {
            transform: translateX(($oneGrid) * 4);
            //opacity: .15;
            //background: $ci-blue;
            transition-delay: 0s;
        }
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .mainBar {
        background: url("../../../Images/Scss-Assets/patternBG_01.png") $grey-darkest;
        color: #fff;
        height: 100%;
    }
}

@keyframes burgerSlide {
    0% {
        left: -26px;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.gr-main {
    section:not(.welcome) {
        transition: $t-medium;
    }
}

body[class*="-open"] {
    .gr-main {
        section:not(.welcome) {
            filter: blur(5px);
        }
    }
}
