.item.pop {
    $popupWidth: 25rem;
    $popupHeight: $popupWidth;

    opacity: 0;
    transform: scale(0.25);
    font-size: 1rem;

    &.uk-scrollspy-inview {
        opacity: 1;
        transform: scale(1);
    }

    &:after {
        opacity: 0;
        transition: $t-fast;
        transition-delay: 0s;
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: red;
        width: 100%;
        height: 25%;

        @include gradient_white;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    .popup {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: none;
        transform: translate(-50%, -50%);
        z-index: 30;
        background: #fff;
        color: $ci-blue-grey;
        width: 100%;
        height: 100%;
        padding: 2.5em;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 1.5rem 1.1rem -0.5rem $ci-blue;
        opacity: 0;
        pointer-events: none;
        transition: $t-medium, opacity cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s;

        * {
            margin: 0;
            transform: none;
            opacity: 1;
        }

        h3,
        .h3,
        .popup-content {
            width: 100%;
        }

        .popup-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: 100%;
            opacity: 0;
            transform: translateY(2em);
        }

        p,
        ul,
        ol,
        a.link {
            padding: 0;
            color: $ci-blue-grey;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 1.618em;

            + a.link {
                margin: -1em 0 1.618em;
            }
        }

        a.link {
            @include link-arrow;

            font-weight: 600;
            padding-right: 1em;

            &:hover {
                &:after {
                    color: $ci-green;
                    transform: scale(0.5) translate(30px, 10%);
                }
            }
        }

        p,
        ul,
        ol,
        h3,
        .h3,
        h4,
        .h4,
        .button {
            opacity: 0;
            transform: translateY(3rem);
            transition: $t-medium;
            min-width: 0;
        }

        ul {
            padding-left: 1.5rem;
        }

        h3,
        .h3 {
            margin-bottom: 0.5em;
            font-weight: 700;
            line-height: 1.15;
            color: $ci-green;
        }

        h4,
        .h4 {
            font-size: 1.4em;
            margin: -0.5em 0 1em;
        }

        .button {
            margin-top: auto;
            transition-delay: 0.5s;
        }

        .closePopup {
            font-size: 1.5rem;
            line-height: 1;
            position: absolute;
            z-index: 30;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
            height: 1.1em;
            width: 1.1em;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $t-fast;

            &:hover,
            &:focus,
            &:active {
                background-color: $ci-green;
                color: #fff;
            }
        }
    }

    &.popupOpen {
        pointer-events: none;
        cursor: auto;
        z-index: 30;

        &:after {
            opacity: 1;
            transition-delay: 0.2s;
        }

        .popup {
            width: 25rem;
            height: 25rem;
            opacity: 1;
            pointer-events: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p,
            ul,
            ol,
            h3,
            .h3,
            h4,
            .h4,
            .button,
            .popup-content {
                transition-delay: 0.15s;
                opacity: 1;
                transform: none;
            }

            h3,
            .h3,
            .h4,
            h4,
            .popup-content {
                transition-delay: 0.15s;
            }

            p,
            ul,
            ol {
                transition-delay: 0.2s;
            }

            .button {
                transition-delay: 0.25s;
            }

            .h3,
            h4,
            .h4 {
                transition-delay: 0.1s;
                flex: none;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .popup {
            .closePopup {
                font-size: 2rem;
            }

            h3,
            .h3 {
                font-size: 3em;
            }

            p,
            ul,
            ol {
                font-size: 1.75em;
            }
        }

        &.popupOpen {
            .popup {
                width: 33rem;
                height: 33rem;
            }
        }
    }

    @media all and (max-width: 599px) {
        .popup {
            left: calc(#{$halfGrid} * -1);
            transform: translate(0, -50%);
        }

        &:nth-child(2n) {
            .popup {
                left: auto;
                right: calc(#{$halfGrid} * -1);
                transform: translate(0, -50%);
            }
        }
    }

    @media screen and (max-width: 412px) {
        .popup {
            left: 50% !important;
            right: auto !important;
            transform: translate(-50%, -50%) !important;
        }

        &.popupOpen .popup {
            width: calc(100% + #{$twoGrid});
            height: calc(100% + #{$twoGrid});

            .popupOpen & {
                h3,
                .h3,
                p,
                ul,
                ol {
                    font-size: 3em;
                }

                p,
                ul,
                ol {
                    font-size: 1.75rem;
                }
            }
        }
    }

    @media all and (min-width: 600px) and (max-width: 1199px) {
        .popup {
            left: calc(#{$halfGrid} * -1);
            transform: translate(0, -50%);
        }

        &:nth-child(2n) {
            .popup {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:nth-child(3n) {
            .popup {
                left: auto;
                right: calc(#{$halfGrid} * -1);
                transform: translate(0, -50%);
            }
        }

        &:nth-child(4n) {
            .popup {
                left: calc(#{$halfGrid} * -1);
                transform: translate(0, -50%);
            }
        }

        &:nth-child(5n),
        &:nth-child(8n) {
            .popup {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.referenzen,
.logoBadges {
    .item.pop {
        &:after {
            content: none;
        }
    }
}
