section {
    padding: $oneGrid calc(#{$twoGrid} - 20px) $oneGrid $oneGrid;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 10;
    transform: translate3d(0, 0, 0);
    will-change: transform, filter;
    overflow: hidden;

    section {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.gr-main {
    > section {
        > * {
        }
    }
}

@media all and (max-width: 1024px) {
    section {
        padding: $oneGrid;
    }
}

@media (max-width: 800px) {
    section {
        padding: $twoGrid;
        padding-top: $threeGrid;
    }
}

@media (max-width: 800px) {
    section {
        padding: $twoGrid;
    }
}

@media (max-width: $bp-handheld-max) {
    section {
        padding-bottom: 130px;
    }
}
