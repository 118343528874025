* {
    box-sizing: border-box;
    outline: none !important;

    &:hover,
    &:focus {
        outline: none !important;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $grey-darkest;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    border: none;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: $ci-green;
    border-radius: 0;
}

body,
html {
    min-height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 320px;
}

body,
input {
    font-family: "analog", sans-serif;
    color: #fff;
}

body {
    min-height: 100vh;

    @include gradient_ci;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-backface-visibility: hidden;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;

    &.noOverflow {
        height: 100vh;
        overflow: hidden;
    }

    &[class*="-open"] {
        overflow: hidden;

        .gr-main {
            cursor: pointer;
        }
    }

    .mask {
        opacity: 0;
        visibility: hidden;
        background: url(../../../Images/Scss-Assets/patternBG_01.png) #2c424d;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: opacity 0.5s 0.5s;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 150px;
            height: 150px;
            transform: translate(-50%, -50%) scale(1);
            transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
            opacity: 1;
        }
    }
}

.home .gr-main section > h1 {
    @media screen and (max-width: $bp-handheld-max) {
        background: url(../../../Images/Misc/Images/analogo_sign_white.svg) no-repeat;
        background-size: auto 0.8em;
        background-position: right center;
        padding-right: 1.2em;
    }
}

html.loading {
    overflow: hidden;

    .mask {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

nav {
    ol,
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            padding: 0;
            margin: 0;

            a {
                text-transform: uppercase;
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

img,
svg {
    width: 100%;
    height: auto;
}

img {
    display: block;
}

figure {
    margin: 0;
}

hr {
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    height: 0;
    padding: 0;
    margin: $oneGrid 0;
}

.touch .mainNav *:not(.burger) {
    &:hover,
    &:focus,
    &:active {
        transition: none !important;
    }
}

.typo3-member {
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 5.75vw;
    z-index: 30;
    transition: $t-fast;

    .imprintOpen & {
        opacity: 0;
        visibility: hidden;
    }
}

.nicescroll-rails {
    left: auto !important;
    right: 0;
}

@media screen and (max-width: 1280px) {
    .typo3-member {
        width: $twoGrid;
    }
}

@media screen and (max-width: 1024px) {
    .typo3-member {
        width: 8.5vw;
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .typo3-member {
        display: none;
    }
}

@media screen and (max-width: 479px) {
    body {
        overflow: auto !important;
    }
}

#particles-js {
    display: none;
    pointer-events: none;
}

.mapIsOpen {
    section.contact {
        a,
        .showMap {
            cursor: pointer;
        }
    }

    #particles-js {
        display: block;
        pointer-events: none;
    }
}
