.square {
    font-size: 2rem;
    width: 100em;
    height: 100em;
    position: fixed;
    right: -100em;
    transform: rotate(45deg) translateZ(0);
    transform-origin: 50% top;
    opacity: 0.3;

    &.sq01 {
        background: #5df4d1;
        top: -50em;
    }

    &.sq02 {
        background: #2b8fb3;
        margin-top: 100em;
    }

    &.sq03 {
        width: 150em;
        height: 150em;
        right: -150em;
    }
}
