.mobileLogo {
    display: none;
}

.home h1 {
    span {
        margin-bottom: -0.25em;
    }
}

@media all and (max-width: $bp-handheld-max) {
    .home h1 {
        span {
            display: none;
        }

        .type {
            visibility: hidden;
        }
    }

    .mobileLogo {
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        height: 100%;
        width: auto;
        transform: translateX(-7%) scale(1);
        transform-origin: left center;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.analog-icon.arrow_right {
    position: relative;
    top: 50%;
    text-align: center;

    &:before {
        @include centerHorz;
    }
}

.logoBadges {
    .items {
        @include col3;
    }

    .item.pop {
        transition: $t-medium;

        img,
        .svg {
            border: none;
            transition: $t-medium;
        }

        .inner {
            text-align: center;
            display: flex;
            flex: 1;
            transition: $t-medium;
            max-width: none;

            > a {
                flex: 1;
            }
        }

        .popup {
            max-width: none;

            h3,
            .h3 {
                margin: auto;
                text-align: center;
            }

            &.popupOpen {
                h3,
                .h3 {
                    transition-delay: 0.35s !important;
                }
            }
        }

        &:hover {
            .inner {
                img {
                    transform: scale(1.15);
                }
            }
        }
    }
}

.referenzen {
    ul {
        @include col3;

        li {
            cursor: pointer;
            transition: $t-fast;
            padding: 0;
            list-style: none;
            position: relative;
            z-index: 1;

            .inner {
                background: #fff;
                transition: $t-fast;
                position: relative;
                text-align: center;

                > .h3 {
                    color: $grey-mid;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    font-size: 1.75em;
                    padding: 0.5em;
                    background: #edf1f4;
                    background: $grey-lighter;
                    margin: 0;
                    height: 2.6em;

                    @media all and (min-width: 1281px) {
                        font-size: 1.25em;
                    }
                }

                img {
                    padding: $halfGrid $oneGrid ($oneGrid + $halfGrid);
                    transition: $t-medium;

                    @media all and (min-width: 1281px) {
                        padding: $oneGrid;
                        transform: translateY(-6%);
                    }

                    @media screen and (max-width: 1023px) {
                        transform: translateY(0) scale(0.7);
                    }
                }

                h4 {
                    color: $grey-dark;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    font-size: 1.2em;
                    line-height: 1;
                    letter-spacing: 0;
                    font-weight: 600;
                    margin: 0;
                    padding: 1em;
                    background: transparentize($grey-dark, 0.85);
                }
            }

            &:hover {
                transform: scale(1.07) !important;
                z-index: 2;

                .inner {
                    transform: scale(1);
                    box-shadow: 0 0.5em 3em 0.4em rgba(33, 109, 136, 0.55);

                    img {
                        transform: translateY(-6%) scale(1.1);

                        @media screen and (max-width: 1280px) {
                            transform: translateY(3.5%) scale(1.1);
                        }

                        @media screen and (max-width: 1023px) {
                            transform: translateY(0) scale(1);
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: $bp-handheld-max) {
    .logoBadges .item .info {
        .inner h3,
        .h3 {
            font-size: 1.25em;
        }
    }
}
