ul#movingLogos {
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block !important;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    overflow: hidden;
    opacity: 0.4;

    @include gradient_ci;
}

li.layer {
    position: relative;
    width: 130%;
    height: 130%;
    left: -15% !important;
    top: -15% !important;
    text-align: center;
}

.starfield {
    .layer .img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
        //animation: pulse 1s ease-in-out infinite;
        animation-direction: normal;
        background-repeat: repeat;
    }

    #img-3 {
        background-image: url(../../../Images/Scss-Assets/parallax/bg-dust_green.png);
        //animation: pulse 5s ease-in-out infinite;
    }

    #img-2 {
        background-image: url(../../../Images/Scss-Assets/parallax/dust-medio_green.png);
        //animation: pulse 7s ease-in-out infinite;
    }

    #img-1 {
        background-image: url(../../../Images/Scss-Assets/parallax/dust-delante_green.png);
        //animation: pulse 11s ease-in-out infinite;
    }

    @keyframes pulse {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }
    }
}

.logos {
    .layer .img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
        animation: pulse 1s ease-in-out infinite normal;
        background-size: 115vw;
        background-repeat: repeat;
        opacity: 0.3;
    }

    #img-4 {
        background-image: url(../../../Images/Scss-Assets/parallax/level-01.svg);
        animation: pulse 3s ease-in-out infinite;
    }

    #img-3 {
        background-image: url(../../../Images/Scss-Assets/parallax/level-02.svg);
        animation: pulse 5s ease-in-out infinite;
    }

    #img-2 {
        background-image: url(../../../Images/Scss-Assets/parallax/level-03.svg);
        animation: pulse 7s ease-in-out infinite;
    }

    #img-1 {
        background-image: url(../../../Images/Scss-Assets/parallax/level-04.svg);
        animation: pulse 11s ease-in-out infinite;
    }

    @keyframes pulse {
        0%,
        100% {
            opacity: 0.3;
            //background-size: 58%;
        }

        50% {
            opacity: 0.1;
            //background-size:90%;
        }
    }
}

@media all and (max-width: 1024px) {
    .logos {
        .layer .img {
            background-size: 145vw;
        }
    }
}

@media all and (max-width: 1023px) {
    ul#movingLogos {
        width: 100%;
    }
}

@media all and (max-width: $bp-handheld-min) {
    .logos {
        .layer .img {
            //background-size: 200%;
        }
    }
}
