.button {
    cursor: pointer;
    background: transparent;
    border: 2px solid #fff;
    line-height: 1;
    width: auto;
    display: inline-block;
    text-decoration: none;
    padding: 1em 1.4em;
    margin: 1em 0;
    text-align: center;
    transition: $t-medium;
    position: relative;
    overflow: hidden;
    min-width: 250px;

    @media all and (min-width: 1281px) {
        font-size: 1rem;
    }

    span {
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        font-size: 2em;
        min-height: 1em;
        display: block;
        text-decoration: none;
        transform: scale(0.85);
        transition: $t-medium;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scaleY(0);
        transition: $t-medium;
        background: $ci-blue;
        opacity: 0;
    }

    &:hover {
        background: transparentize($ci-blue, 0.5);

        span {
            transform: scale(1);
        }

        &:before {
            opacity: 0.5;
            transform: translate(-50%, -50%) scaleY(1);
        }
    }

    &.btn-green {
        background: $ci-green-bright;

        @include gradient_ci;

        background-size: 120%;
        background-attachment: unset;
        border: none;
    }

    &.btn-block {
        display: block;
    }
}

.projectSlideButton {
    justify-content: space-between;
}

.projectSlideButton,
.bigButton {
    border-top: 1px solid #fff;
    padding-right: 0;
    margin: 5em 0;
    margin-left: $twoGrid;
    position: relative;
    text-align: left;
    display: flex;

    .button {
        padding: 1em 0;
        display: block;
        text-align: right;
        overflow: visible;

        &.visitSite {
            margin-left: auto;
            border: none;

            span {
                font-size: 1.75em;
                display: block;
                transform: scale(1);

                &:after {
                    @include customIcon;

                    display: inline-block;
                    transform: scale(0.5) translate(15px, 10%);
                    transform-origin: center center;
                    content: $analog-icon-arrow_right;
                    transition: $t-fast;
                }
            }

            &:before {
                display: none;
                top: 0;
                transform: none;
                height: 2px;
                width: 20px;
            }

            &:hover {
                background: none !important;

                span:after {
                    transform: scale(0.5) translate(25px, 10%);
                }

                span:before {
                    transform: scale(0.5) translate(-25px, 10%) rotate(180deg);
                }
            }
        }

        &.nextProject,
        &.lastProject {
            width: calc(50% - 3px);
            display: block;

            &:before {
                display: none;
            }
        }

        &.nextProject {
            text-align: right;
        }

        &.lastProject {
            text-align: left;
            margin-left: 0;

            span {
                &:after {
                    display: none;
                }

                &:before {
                    @include customIcon;

                    display: inline-block;
                    transform: scale(0.5) translate(-15px, 10%) rotate(180deg);
                    transform-origin: center center;
                    content: $analog-icon-arrow_right;
                    transition: $t-fast;
                }
            }
        }
    }
}

@media all and (max-width: 1023px) {
    .projectSlideButton {
        margin-left: 0;
    }

    .button {
        min-width: 30%;
    }
}


@media all and (max-width: $bp-handheld-max) {
    .projectSlideButton .button {
        &.nextProject {
            display: block;
            width: 100%;
        }

        &.lastProject {
            display: none;
        }
    }
}

@media all and (max-width: 479px) {
    .button {
        margin-left: 0 !important;
        min-width: 100%;
        display: block;
    }
}
