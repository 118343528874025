.burger {
    cursor: pointer;
    width: 26px;
    height: 14px;
    position: relative;
    display: inline-block;
    z-index: 100;
    transition: $t-medium;
    margin: 0;

    @include centerVert;

    &:before {
        content: "";
        background-color: $ci-green;

        @include centerVertHorz;

        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        position: absolute;
        z-index: 1;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        opacity: 0;
        transition: $t-medium;

        &.tablet {
            background-color: transparentize($ci-green, 0.4);
        }
    }

    &.mobile {
        &:before {
            border-radius: 5em;
        }
    }

    span {
        position: absolute;
        z-index: 2;
        display: block;
        background: currentColor;
        bottom: auto;
        width: 100%;
        height: 2px;
        border-radius: 0;
        transition: $t-fast;

        &:nth-child(1) {
            top: 0;
            transform: none;
        }

        &:nth-child(2) {
            top: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
            width: 0;
            left: 50%;
        }

        &:nth-child(3) {
            bottom: 0;
            transform: none;
        }
    }

    .no-touch & {
        &:hover {
            span {
                &:nth-child(2) {
                    opacity: 1;
                    width: 100%;
                }
            }
        }
    }

    &.burgerOpen {
        transform: translateX(-50%) rotate(-135deg) scale(0.75);
        overflow: visible;
        color: #fff;

        &:before {
            opacity: 1;
        }

        span {
            &:nth-child(1) {
                top: 50%;
                transform: rotate(-90deg) translateX(0%);
            }

            &:nth-child(2) {
                top: 50% !important;
                opacity: 0 !important;
                transform: none;
                visibility: hidden;
            }

            &:nth-child(3) {
                top: 50%;
            }
        }

        .no-touch & {
            &:hover {
                &:before {
                    background: $ci-blue;
                }
            }
        }

        &.mobile {
            &:before {
                background: transparentize($ci-green-bright, 0.85);
                transform: translate3d(-50%, -50%, 0) scale(3.5);
            }
        }

        &.tablet {
            color: $ci-blue-grey;
            transform: translateX(0) rotate(-135deg) scale(0.5);
        }
    }
}

.burger {
    &.tablet,
    &.desktop {
        display: none;
    }

    @media all and (min-width: $bp-handheld-min) and (max-width: 1023px) {
        &.tablet {
            display: block;
            margin-top: 1.4rem;
            transform: none;
            left: 1em;
        }
    }

    @media all and (min-width: 1024px) {
        &.desktop {
            display: block;
            color: $ci-blue;
            margin-top: 3.75rem;

            &.burgerOpen {
                color: #fff;
            }
        }
    }
}
