section.contact {
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    p a:hover {
        color: $ci-green-bright;
    }

    .t3-member {
        margin: 60px auto;
        max-width: 120px;
    }

    p.mail a {
        text-decoration: none;
    }

    .social {
        display: block !important;

        a {
            color: #fff;
            font-size: 3em;
            margin: 0 0.5em 0 0;

            &:hover,
            &:focus {
                color: $ci-green-bright;
            }
        }
    }

    .social,
    .impressum {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        width: auto;
    }

    .social {
        left: 0;
        text-align: left;
    }

    .impressum {
        right: 0;
        cursor: pointer;
        font-size: 1.5em;
        transition: $t-medium;

        &:hover {
            color: $ci-green-bright;
        }
    }
}

#gMap {
    .analog-mapoverlay {
        color: $ci-blue;
    }

    a.analog-route {
        color: $ci-blue;
    }
}

.contactFooter {
    position: relative;
    padding: $oneGrid 0;
    margin: $oneGrid 0 $oneGrid $twoGrid;
    border: 1px solid #fff;
    border-left: none;
    border-right: none;
}

.button.showMap {
    margin-left: $twoGrid;
}

ul#movingLogos {
    transition: $t-slow;
    transition-delay: 0.5s;
}

.popup.imprint {
    position: fixed;
    max-width: 100%;
    width: 75vw;
    height: 100vh;
    overflow-x: hidden !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 2vw;
    z-index: 30;
    top: 0;
    right: 0;
    background: #fff;
    color: $ci-blue-grey;
    transform: translateX(100%);
    visibility: visible;
    will-change: transform, scroll;
    transition: $t-medium;

    h2 {
        transform: translateX(0);
        opacity: 1;

        span,
        div {
            color: $ci-blue !important;
        }

        &:before {
        }
    }

    a {
        color: $ci-blue-grey;

        &:hover {
            color: $ci-blue;
        }
    }

    p {
        transform: none;
        opacity: 1;
    }
}

body.imprintOpen {
    .popup.imprint {
        transform: translateX(0);
    }

    section.contact {
        max-height: 100%;
        overflow: hidden;
    }
}

.slick-slider {
    //transition-delay: .5s;
    //transition: $t-slow;
}

.mapBG {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute !important;
    opacity: 0;
    visibility: hidden;
    transition: $t-medium;
    z-index: 2;

    .gr-main {
        background: none;
        z-index: 30;
    }

    section.contact {
        opacity: 0;
        transition: $t-medium;
        transition-delay: 2s;
        max-height: 100vh;
        overflow: hidden;
        overflow-y: auto;

        &.active {
        }
    }
}

#particles-js {
    z-index: 5;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#gMap {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed !important;
    opacity: 0.175;
    transition: $t-superslow;

    &.zoom {
        transform: scale(1.5);
    }

    .gr-main {
        background: none !important;
        transition: $t-slow;
    }
}

.mapIsOpen {
    .typo3-member {
        opacity: 0;
    }

    .open .burger:hover span {
        background: $ci-blue;
    }
    $delayTime: 1.5s;
    //overflow: hidden;
    .mainNav li a:hover span.text,
    .mainNav li a:focus span.text {
    }

    .burger span {
        background: #fff;
    }

    .mainNav {
        ul {
            li {
                a {
                    color: #fff;
                    border-color: #fff;

                    &:hover {
                        span {
                            color: $ci-blue;
                        }
                    }
                }
            }
        }
    }

    .sideBar .social a {
        color: #fff;
    }

    .content {
        transform: translateX(-100%);
        max-height: 100vh;
    }

    .gr-main {
        overflow: hidden;
    }

    .mapBG {
        opacity: 1;
        visibility: visible;
        overflow: hidden;

        .gr-main {
            overflow: hidden;
            -webkit-overflow-scrolling: touch;
            transition-delay: 0s !important;
        }

        .content {
            transform: translateX(0);
        }

        section.contact {
            opacity: 1;
            transition-delay: 0s;
            padding-bottom: 0;
        }
    }

    .mapBG,
    .mainBar {
        transition-delay: 0.25s;
    }

    .sliderNav {
        display: none;
    }

    ul#movingLogos {
        opacity: 0;
        transition-delay: 0.25s;
    }

    .sliderNav {
        transform: translateX(100%) !important;
        transition-delay: 0.2s;
    }

    .mainBar {
        transition-delay: 0s;
    }

    .sideBar {
        text-align: center;
        background: white;
        background: transparentize(#fff, 0.7);
        transition-delay: 0s;
    }

    &.mapOnly {
        #gMap {
            &.zoom {
                transition-delay: 0.75s;
            }
        }

        .mapBG {
            opacity: 0.8;
        }
    }
}

.closeView {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    z-index: 100000;
    transition: $t-medium;
    cursor: pointer;
    transform: rotate(0deg);
    transform-origin: center center;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
        top: 50%;
        transform-origin: center center;
        transition: $t-medium;
    }

    &:before {
        transform: translateY(-50%);
    }

    &:after {
        transform: translateY(-50%) rotate(90deg);
    }

    &:hover,
    &:focus {
        transition: $t-fast;
        opacity: 0.75;

        &:before,
        &:after {
            transition: $t-fast;
            background: #fff;
        }
    }

    .mapOnly &,
    .portfolioView &,
    .imprint & {
        opacity: 1;
        visibility: visible;
        transform: rotate(-45deg);
        transition-delay: 0s;
        background: #fff;
        border-radius: 50%;
        padding: 0;
        border: 9px solid #fff;

        .imprint & {
            background: $ci-green;
        }

        &:before,
        &:after {
            background: $grey-darkest;
        }

        &:before {
            transform: translateY(-50%);
        }

        &:after {
            transform: translateY(-50%) rotate(90deg);
        }

        &:hover,
        &:focus {
            background: $ci-green-bright;
            border-color: $ci-green-bright;
            transition-delay: 0s;

            &:before,
            &:after {
                background: #fff;
            }
        }
    }
}

.mapOnly {
    #gMap {
        opacity: 0.7;
    }

    .gr-menu {
        transform: translateX(-100%);
    }

    .gr-main {
        transform: translateX(100%);
        transition-delay: 0s;
    }
}

@media screen and (min-width: $bp-handheld-min) {
    .mapIsOpen {
        .mainBar {
            transition-delay: 0s;
            background-color: transparentize($grey-darkest, 0.6);
        }
    }
}

@media screen and (min-width: 1024px) {
    .mapIsOpen .gr-main {
        transition-delay: 0s;
    }
}

@media screen and (max-width: 1023px) {
    .contactFooter {
        margin-left: 0;
    }

    .popup.imprint {
        width: calc(100vw - 75px);
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .popup.imprint {
        width: 100%;
    }

    .contactFooter {
        padding: 50px 0;
        margin: 30px 0;

        .impressum {
            font-size: 1.5em;
        }
    }
}

@media screen and (max-width: $bp-handheld-max) {
    .mapOnly .gr-menu {
        transform: translate(0, 100%);
    }

    section.contact {
        h2,
        .h2 {
            margin-top: 30px;
        }

        .contactFooter {
            text-align: center;
            padding: 30px 0;

            .impressum,
            .social {
                text-align: center;
                position: relative;
                display: block;
                width: 100%;
                transform: none;
            }

            .impressum {
                font-size: 1.1em;
                margin-top: 13px;
            }

            .social a {
            }
        }
    }
}

@media screen and (max-width: 479px) {
    #particles-js {
        display: none !important;
    }
}
