section.welcome {
    position: relative;
    display: none;
    overflow: hidden;
    max-width: 100vw;
    padding: 0;

    .content {
        position: relative;
        display: block;
        width: 100%;
    }

    .headline {
        color: $ci-green;
        text-align: center;
        font-size: calc(10px + 2.75vw);
        width: 100%;
        padding: 0 $oneGrid;
        margin: 0;
        font-weight: 200;
        letter-spacing: 0;
        position: absolute;
        bottom: 100px;
    }

    @media all and (max-width: 1023px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
        background: url("../../../Images/Scss-Assets/patternBG_01.png") $ci-blue-grey;

        .logo {
            transition: none;
            display: block !important;
            transform: none;
            left: auto;
            top: auto;
            margin: 0 auto 80px;
            padding: 0;

            .big {
                display: block;
            }
        }

        .typo3-member {
            display: block;
            width: 50%;
            max-width: 80px;
            margin: 0.5rem;
        }

        + section {
            padding-top: $fourGrid;
        }
    }
}
