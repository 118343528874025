@charset "UTF-8";
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=abb16308-47ef-477f-8656-798a7af7ffde");
@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/a2594b9c-4d47-481f-bf13-74ae95f6814e.eot?#iefix");
  src: url("../../../Fonts/analog/a2594b9c-4d47-481f-bf13-74ae95f6814e.eot?#iefix") format("eot"), url("../../../Fonts/analog/0501f5b5-8008-430d-a121-fb527cf99f94.woff2") format("woff2"), url("../../../Fonts/analog/90b3832f-e8fd-4abb-abb1-12f52290b297.woff") format("woff"), url("../../../Fonts/analog/ab069724-4e8f-40e8-9064-f694b4cfdd37.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/fb33381b-ecac-4533-a3c2-a04668af2029.eot?#iefix");
  src: url("../../../Fonts/analog/fb33381b-ecac-4533-a3c2-a04668af2029.eot?#iefix") format("eot"), url("../../../Fonts/analog/60109bd1-cd58-44f6-9587-c51f1c2ea19f.woff2") format("woff2"), url("../../../Fonts/analog/5d79bc83-7ce2-4fa3-9e10-7812a4e32469.woff") format("woff"), url("../../../Fonts/analog/01ae4fb4-318c-4535-b8a0-b9b4855c211e.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/015d19d6-7748-4143-962f-494b650b2340.eot?#iefix");
  src: url("../../../Fonts/analog/015d19d6-7748-4143-962f-494b650b2340.eot?#iefix") format("eot"), url("../../../Fonts/analog/304a53be-b1e9-48d6-ad5b-a6eb1740a78f.woff2") format("woff2"), url("../../../Fonts/analog/25819e06-46fe-4013-b85a-eed3e44d5a2c.woff") format("woff"), url("../../../Fonts/analog/2925287b-037d-4c75-9c61-cd4d318e581f.ttf") format("truetype");
  font-weight: 250;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/f2452202-0941-43c8-a16e-fb3f3af4c418.eot?#iefix");
  src: url("../../../Fonts/analog/f2452202-0941-43c8-a16e-fb3f3af4c418.eot?#iefix") format("eot"), url("../../../Fonts/analog/9dc3bddc-c188-4433-b902-6a54d9fe6cad.woff2") format("woff2"), url("../../../Fonts/analog/650e6d25-ea30-490e-8e38-465da5c5fa3b.woff") format("woff"), url("../../../Fonts/analog/766c76b9-0c16-4ff8-8a9a-1735a491b87c.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/d53d548d-e050-4dd0-aacb-0d998952d14b.eot?#iefix");
  src: url("../../../Fonts/analog/d53d548d-e050-4dd0-aacb-0d998952d14b.eot?#iefix") format("eot"), url("../../../Fonts/analog/cb8bce68-3c81-4d94-a7ea-1635ab689637.woff2") format("woff2"), url("../../../Fonts/analog/f71d640e-41c0-4c89-b999-fae92e3c00eb.woff") format("woff"), url("../../../Fonts/analog/7aca3abd-ff6c-4391-9642-e87c9e4d7982.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/aefcc5ee-a012-48c3-9ebf-731f07bb3cd3.eot?#iefix");
  src: url("../../../Fonts/analog/aefcc5ee-a012-48c3-9ebf-731f07bb3cd3.eot?#iefix") format("eot"), url("../../../Fonts/analog/28a23213-3346-41f7-8f14-71d464d767ea.woff2") format("woff2"), url("../../../Fonts/analog/9283fcc2-ec46-4d69-9278-187c1ce28dd8.woff") format("woff"), url("../../../Fonts/analog/58906072-d870-44da-8fd6-73ff545ecbb6.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/84b3b337-f678-4043-85ec-4fe614e8eb9d.eot?#iefix");
  src: url("../../../Fonts/analog/84b3b337-f678-4043-85ec-4fe614e8eb9d.eot?#iefix") format("eot"), url("../../../Fonts/analog/f1a2a077-35d2-4c0b-b6bc-d3f83a36287f.woff2") format("woff2"), url("../../../Fonts/analog/be3407b0-afb2-4c9e-ad46-e285ed2ff795.woff") format("woff"), url("../../../Fonts/analog/37e76ca2-6002-4a18-9604-3997d2289119.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/57d58f8d-c7cb-46d5-a101-c4fed6b2b298.eot?#iefix");
  src: url("../../../Fonts/analog/57d58f8d-c7cb-46d5-a101-c4fed6b2b298.eot?#iefix") format("eot"), url("../../../Fonts/analog/64e5d7b5-0c34-4a8b-9040-2a0c9cbab751.woff2") format("woff2"), url("../../../Fonts/analog/0c9565d2-8853-4e0b-8968-3d5a35e641f8.woff") format("woff"), url("../../../Fonts/analog/daa6682c-3400-43ee-98cd-8c8482fcb7ff.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/be3d9415-ca0f-4fe1-b2f5-85c4498f554a.eot?#iefix");
  src: url("../../../Fonts/analog/be3d9415-ca0f-4fe1-b2f5-85c4498f554a.eot?#iefix") format("eot"), url("../../../Fonts/analog/666e80fa-4d40-441c-9130-d91b9c95f741.woff2") format("woff2"), url("../../../Fonts/analog/7cf1577f-c5ee-4f35-9755-02453d0865b9.woff") format("woff"), url("../../../Fonts/analog/49f7071a-63ba-450f-9256-ef6b1196e41f.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

/*
@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/9b5eb463-b285-4c0a-a855-2fcb2611b130.eot?#iefix");
  src: url("../../../Fonts/analog/9b5eb463-b285-4c0a-a855-2fcb2611b130.eot?#iefix") format("eot"), url("../../../Fonts/analog/8010df30-8aa3-4174-b955-5baf1092e203.woff2") format("woff2"), url("../../../Fonts/analog/07afaa6b-f1d1-44d8-a722-66fc064ba98f.woff") format("woff"), url("../../../Fonts/analog/4ce15e5c-d502-4ff9-bcae-63fcd27694cb.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;

}
 */
@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/3ebf1cec-a6e6-4b55-a2c8-e500aaf2b030.eot?#iefix");
  src: url("../../../Fonts/analog/3ebf1cec-a6e6-4b55-a2c8-e500aaf2b030.eot?#iefix") format("eot"), url("../../../Fonts/analog/6130b850-872c-4bc5-9d06-ab6201cdbcec.woff2") format("woff2"), url("../../../Fonts/analog/d25e3f4f-6e02-485f-bc92-eb37a6a216d8.woff") format("woff"), url("../../../Fonts/analog/a70921c9-cca7-4da7-b8d7-bbe312822b3f.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/f32e46b9-e1d8-4805-bbe0-396647493474.eot?#iefix");
  src: url("../../../Fonts/analog/f32e46b9-e1d8-4805-bbe0-396647493474.eot?#iefix") format("eot"), url("../../../Fonts/analog/36b4f1d2-1f44-4b78-aac4-0a62af15ba3a.woff2") format("woff2"), url("../../../Fonts/analog/6ba49908-4dab-4637-9f2c-03228457d145.woff") format("woff"), url("../../../Fonts/analog/724844fe-811e-4ba5-a9cf-2780718c85b7.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/7c930cfc-b9ab-46fb-bbda-d779be21ac79.eot?#iefix");
  src: url("../../../Fonts/analog/7c930cfc-b9ab-46fb-bbda-d779be21ac79.eot?#iefix") format("eot"), url("../../../Fonts/analog/bf3aafc4-3b84-44de-8fca-a88a40eeb2a5.woff2") format("woff2"), url("../../../Fonts/analog/b81db7c8-3b90-4e82-a443-f50178db0958.woff") format("woff"), url("../../../Fonts/analog/dc9738fb-10ff-44f5-8e7a-f7ca8f908af8.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog";
  src: url("../../../Fonts/analog/139251fe-7a53-4f16-a515-699ff7afbd0f.eot?#iefix");
  src: url("../../../Fonts/analog/139251fe-7a53-4f16-a515-699ff7afbd0f.eot?#iefix") format("eot"), url("../../../Fonts/analog/84a50ec3-c094-4387-ad89-f8e67a5255f0.woff2") format("woff2"), url("../../../Fonts/analog/8f13e53e-dd2c-4dcb-ab34-e2206bd2f58c.woff") format("woff"), url("../../../Fonts/analog/f0bd180c-dd36-4c5b-9e80-4f649ec26c03.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "analog-icons";
  src: url("../../../Fonts/iconfont/analog-icons.woff2?qz1e5s") format("woff2"), url("../../../Fonts/iconfont/analog-icons.ttf?qz1e5s") format("truetype"), url("../../../Fonts/iconfont/analog-icons.woff?qz1e5s") format("woff"), url("../../../Fonts/iconfont/analog-icons.svg?qz1e5s#analog-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class^="analog-icon."],
[class*=" analog-icon."],
[class*="analog-icon "] {
  font-family: "analog-icons" !important;
  speak: none;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.analog-icon.analogo_font:before {
  content: ""; }

.analog-icon.analogo_sign:before {
  content: ""; }

.analog-icon.arrow_right:before {
  content: ""; }

.analog-icon.arrow_up:before {
  content: ""; }

.analog-icon.menu_contact:before {
  content: ""; }

.analog-icon.menu_home:before {
  content: ""; }

.analog-icon.menu_projects:before {
  content: ""; }

.analog-icon.menu_services:before {
  content: ""; }

.analog-icon.social_facebook:before {
  content: ""; }

.analog-icon.social_twitter:before {
  content: ""; }

.analog-icon.social_xing:before {
  content: ""; }

.analog-icon.t3_member:before {
  content: ""; }

.analog-icon.speechBubble:before {
  content: ""; }

.analog-icon.menu_burger:before {
  content: ""; }

.analog-icon.menu_news:before {
  content: ""; }

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 2.08333vw; }
  .container .row {
    margin: -2.08333vw; }
  @media screen and (max-width: 1023px) {
    .container {
      padding: 0 30px; }
      .container .row {
        margin: 0 -30px; } }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.gr-menu {
  position: fixed;
  width: 25%;
  height: 100%;
  z-index: 2; }

.mainBar,
.sideBar {
  height: 100%;
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  will-change: transform; }

.mainBar {
  width: 20.83334vw;
  padding: 4.16667vw;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }

.sideBar {
  width: 4.16667vw;
  left: 20.83334vw;
  padding: 4.16667vw 0 0 0;
  z-index: 1;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }

.gr-main {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  position: relative;
  padding: 0;
  margin: 0 0 0 25%;
  min-height: 100vh;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  will-change: transform, opacity, filter;
  transform: translateZ(0); }

.offset-half {
  margin-left: 2.08333vw; }

.offset-1 {
  margin-left: 4.16667vw; }

.offset-2 {
  margin-left: 8.33333vw; }

.offset-3 {
  margin-left: 12.5vw; }

.offset-4 {
  margin-left: 16.66667vw; }

.col-1 {
  width: 4.16667vw; }

.col-2 {
  width: 8.33333vw; }

.col-3 {
  width: 12.5vw; }

.col-4 {
  width: 16.66667vw; }

.col-5 {
  width: 20.83334vw; }

.col-6 {
  width: 25.0vw; }

.col-7 {
  width: 29.16667vw; }

.col-8 {
  width: 33.33334vw; }

.col-9 {
  width: 37.5vw; }

.col-10 {
  width: 41.66667vw; }

.col-11 {
  width: 45.83334vw; }

.col-12 {
  width: 50.0vw; }

.col-13 {
  width: 54.16667vw; }

.col-14 {
  width: 58.33334vw; }

[class*="col-"] {
  float: left; }

.floatRight {
  float: right; }

.floatLeft {
  float: left; }

.col2 {
  display: flex;
  flex-wrap: wrap; }
  .col2 [class*="col-"] {
    flex: 1 0 50%;
    max-width: 50%; }

.col3 {
  display: flex;
  flex-wrap: wrap; }
  .col3 [class*="col-"] {
    flex: 1 0 30%;
    max-width: 50%; }

.col4 {
  display: flex;
  flex-wrap: wrap; }
  .col4 [class*="col-"] {
    flex: 1 0 25%;
    max-width: 50%; }

.col5 {
  display: flex;
  flex-wrap: wrap; }
  .col5 [class*="col-"] {
    flex: 1 0 20%;
    max-width: 50%; }

.col6 {
  display: flex;
  flex-wrap: wrap; }
  .col6 [class*="col-"] {
    flex: 1 0 15%;
    max-width: 50%; }

.social + .social {
  display: none !important; }

@media screen and (min-width: 1500px) {
  .mainBar {
    padding: 4.16667vw 6.25vw; }
  .mainNav li a {
    font-size: 1.25em !important; }
    .mainNav li a span.analog-icon {
      width: 1.25em !important; } }

@media screen and (max-width: 1280px) {
  .gr-main {
    font-size: 1.2em; } }

@media screen and (max-width: 1023px) {
  .gr-menu {
    width: 75px; }
  .gr-main {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
    width: calc(100% - 75px);
    margin-left: 75px; }
  .sideBar {
    display: none; }
  .mainBar {
    padding: 15px;
    width: 75px;
    overflow: hidden;
    border-right: 1px solid rgba(255, 255, 255, 0.5); } }

@media screen and (max-width: 499px) {
  .gr-menu {
    width: 100%;
    bottom: 0; }
  .gr-main {
    width: 100%;
    min-height: 100%;
    margin-left: 0;
    overflow: hidden; }
  .sideBar {
    display: none; }
  .mainBar {
    padding: 0;
    width: 100%;
    height: auto;
    overflow: visible;
    border: none;
    position: static;
    background-color: #323538; }
  .logo {
    display: none !important; }
  .social {
    display: none; } }

@media screen and (min-width: 320px) {
  .offset-xs-half {
    margin-left: 2.08333vw; }
  .offset-xs-1 {
    margin-left: 4.16667vw; }
  .offset-xs-2 {
    margin-left: 8.33333vw; }
  .offset-xs-3 {
    margin-left: 12.5vw; }
  .offset-xs-4 {
    margin-left: 16.66667vw; }
  .col-xs-1 {
    width: 4.16667vw; }
  .col-xs-2 {
    width: 8.33333vw; }
  .col-xs-3 {
    width: 12.5vw; }
  .col-xs-4 {
    width: 16.66667vw; }
  .col-xs-5 {
    width: 20.83334vw; }
  .col-xs-6 {
    width: 25.0vw; }
  .col-xs-7 {
    width: 29.16667vw; }
  .col-xs-8 {
    width: 33.33334vw; }
  .col-xs-9 {
    width: 37.5vw; }
  .col-xs-10 {
    width: 41.66667vw; }
  .col-xs-11 {
    width: 45.83334vw; }
  .col-xs-12 {
    width: 50.0vw; }
  .col-xs-13 {
    width: 54.16667vw; }
  .col-xs-14 {
    width: 58.33334vw; } }

@media screen and (min-width: 480px) {
  .offset-sm-half {
    margin-left: 2.08333vw; }
  .offset-sm-1 {
    margin-left: 4.16667vw; }
  .offset-sm-2 {
    margin-left: 8.33333vw; }
  .offset-sm-3 {
    margin-left: 12.5vw; }
  .offset-sm-4 {
    margin-left: 16.66667vw; }
  .col-sm-1 {
    width: 4.16667vw; }
  .col-sm-2 {
    width: 8.33333vw; }
  .col-sm-3 {
    width: 12.5vw; }
  .col-sm-4 {
    width: 16.66667vw; }
  .col-sm-5 {
    width: 20.83334vw; }
  .col-sm-6 {
    width: 25.0vw; }
  .col-sm-7 {
    width: 29.16667vw; }
  .col-sm-8 {
    width: 33.33334vw; }
  .col-sm-9 {
    width: 37.5vw; }
  .col-sm-10 {
    width: 41.66667vw; }
  .col-sm-11 {
    width: 45.83334vw; }
  .col-sm-12 {
    width: 50.0vw; }
  .col-sm-13 {
    width: 54.16667vw; }
  .col-sm-14 {
    width: 58.33334vw; } }

@media screen and (min-width: calc(500px)) {
  .offset-md-half {
    margin-left: 2.08333vw; }
  .offset-md-1 {
    margin-left: 4.16667vw; }
  .offset-md-2 {
    margin-left: 8.33333vw; }
  .offset-md-3 {
    margin-left: 12.5vw; }
  .offset-md-4 {
    margin-left: 16.66667vw; }
  .col-md-1 {
    width: 4.16667vw; }
  .col-md-2 {
    width: 8.33333vw; }
  .col-md-3 {
    width: 12.5vw; }
  .col-md-4 {
    width: 16.66667vw; }
  .col-md-5 {
    width: 20.83334vw; }
  .col-md-6 {
    width: 25.0vw; }
  .col-md-7 {
    width: 29.16667vw; }
  .col-md-8 {
    width: 33.33334vw; }
  .col-md-9 {
    width: 37.5vw; }
  .col-md-10 {
    width: 41.66667vw; }
  .col-md-11 {
    width: 45.83334vw; }
  .col-md-12 {
    width: 50.0vw; }
  .col-md-13 {
    width: 54.16667vw; }
  .col-md-14 {
    width: 58.33334vw; } }

@media screen and (min-width: 1024px) {
  .offset-lg-half {
    margin-left: 2.08333vw; }
  .offset-lg-1 {
    margin-left: 4.16667vw; }
  .offset-lg-2 {
    margin-left: 8.33333vw; }
  .offset-lg-3 {
    margin-left: 12.5vw; }
  .offset-lg-4 {
    margin-left: 16.66667vw; }
  .col-lg-1 {
    width: 4.16667vw; }
  .col-lg-2 {
    width: 8.33333vw; }
  .col-lg-3 {
    width: 12.5vw; }
  .col-lg-4 {
    width: 16.66667vw; }
  .col-lg-5 {
    width: 20.83334vw; }
  .col-lg-6 {
    width: 25.0vw; }
  .col-lg-7 {
    width: 29.16667vw; }
  .col-lg-8 {
    width: 33.33334vw; }
  .col-lg-9 {
    width: 37.5vw; }
  .col-lg-10 {
    width: 41.66667vw; }
  .col-lg-11 {
    width: 45.83334vw; }
  .col-lg-12 {
    width: 50.0vw; }
  .col-lg-13 {
    width: 54.16667vw; }
  .col-lg-14 {
    width: 58.33334vw; } }

@media screen and (min-width: 1280px) {
  .offset-xl-half {
    margin-left: 2.08333vw; }
  .offset-xl-1 {
    margin-left: 4.16667vw; }
  .offset-xl-2 {
    margin-left: 8.33333vw; }
  .offset-xl-3 {
    margin-left: 12.5vw; }
  .offset-xl-4 {
    margin-left: 16.66667vw; }
  .col-xl-1 {
    width: 4.16667vw; }
  .col-xl-2 {
    width: 8.33333vw; }
  .col-xl-3 {
    width: 12.5vw; }
  .col-xl-4 {
    width: 16.66667vw; }
  .col-xl-5 {
    width: 20.83334vw; }
  .col-xl-6 {
    width: 25.0vw; }
  .col-xl-7 {
    width: 29.16667vw; }
  .col-xl-8 {
    width: 33.33334vw; }
  .col-xl-9 {
    width: 37.5vw; }
  .col-xl-10 {
    width: 41.66667vw; }
  .col-xl-11 {
    width: 45.83334vw; }
  .col-xl-12 {
    width: 50.0vw; }
  .col-xl-13 {
    width: 54.16667vw; }
  .col-xl-14 {
    width: 58.33334vw; } }

/* Single property */
html {
  font-size: 14px; }
  @media screen and (min-width: 320px) {
    html {
      font-size: calc( 14px + 4 * (100vw - 320px) / 1046); } }
  @media screen and (min-width: 1366px) {
    html {
      font-size: 18px; } }

@media screen and (min-width: 1281px) {
  html {
    font-size: 0.78125vw; } }

@media screen and (max-width: 1280px) {
  html {
    font-size: 10px; } }

/* Multiple properties with same values */
h1 {
  padding-bottom: 2em;
  padding-top: 2em; }
  @media screen and (min-width: 20em) {
    h1 {
      padding-bottom: calc( 2em + 2 * (100vw - 20em) / 50);
      padding-top: calc( 2em + 2 * (100vw - 20em) / 50); } }
  @media screen and (min-width: 70em) {
    h1 {
      padding-bottom: 4em;
      padding-top: 4em; } }

.col3 {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100% !important;
  margin: 0 -2.08333vw;
  padding: 0; }
  .col3 > * {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 0 2.08333vw !important;
    margin-bottom: 4.16667vw; }
    .col3 > * > * {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-width: 100%;
      padding: 0;
      margin: 0; }
      .col3 > * > * > * {
        flex: 1;
        max-width: 100%; }
    @media all and (min-width: 768px) {
      .col3 > * {
        flex: 1 0 30%;
        max-width: 33.33334%; } }
    @media all and (max-width: 767px) {
      .col3 > * {
        flex: 1 0 50%;
        max-width: 50%; } }
  @media all and (max-width: 550px) {
    .col3 {
      margin: 0; }
      .col3 > * {
        flex: 1 0 100%;
        max-width: 100%;
        margin: 0 0 8.33333vw;
        padding: 0 !important; } }

* {
  box-sizing: border-box;
  outline: none !important; }
  *:hover, *:focus {
    outline: none !important; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #323538; }

::-webkit-scrollbar-track {
  border-radius: 0;
  border: none;
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  background: #4ed6c8;
  border-radius: 0; }

body,
html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 320px; }

body,
input {
  font-family: "analog", sans-serif;
  color: #fff; }

body {
  min-height: 100vh;
  background: #2b8fb3;
  background: -moz-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
  background: -webkit-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
  background: linear-gradient(135deg, #2b8fb3 35%, #5df4d1 130%);
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-backface-visibility: hidden;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent; }
  body.noOverflow {
    height: 100vh;
    overflow: hidden; }
  body[class*="-open"] {
    overflow: hidden; }
    body[class*="-open"] .gr-main {
      cursor: pointer; }
  body .mask {
    opacity: 0;
    visibility: hidden;
    background: url(../../../Images/Scss-Assets/patternBG_01.png) #2c424d;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s 0.5s; }
    body .mask svg {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 150px;
      height: 150px;
      transform: translate(-50%, -50%) scale(1);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      opacity: 1; }

@media screen and (max-width: 499px) {
  .home .gr-main section > h1 {
    background: url(../../../Images/Misc/Images/analogo_sign_white.svg) no-repeat;
    background-size: auto 0.8em;
    background-position: right center;
    padding-right: 1.2em; } }

html.loading {
  overflow: hidden; }
  html.loading .mask {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

nav ol,
nav ul {
  margin: 0;
  padding: 0; }
  nav ol li,
  nav ul li {
    list-style: none;
    padding: 0;
    margin: 0; }
    nav ol li a,
    nav ul li a {
      text-transform: uppercase;
      text-decoration: none;
      color: #fff; }

img,
svg {
  width: 100%;
  height: auto; }

img {
  display: block; }

figure {
  margin: 0; }

hr {
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  height: 0;
  padding: 0;
  margin: 4.16667vw 0; }

.touch .mainNav *:not(.burger):hover, .touch .mainNav *:not(.burger):focus, .touch .mainNav *:not(.burger):active {
  transition: none !important; }

.typo3-member {
  position: absolute;
  top: 1vw;
  right: 1vw;
  width: 5.75vw;
  z-index: 30;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
  .imprintOpen .typo3-member {
    opacity: 0;
    visibility: hidden; }

.nicescroll-rails {
  left: auto !important;
  right: 0; }

@media screen and (max-width: 1280px) {
  .typo3-member {
    width: 8.33333vw; } }

@media screen and (max-width: 1024px) {
  .typo3-member {
    width: 8.5vw; } }

@media screen and (max-width: 499px) {
  .typo3-member {
    display: none; } }

@media screen and (max-width: 479px) {
  body {
    overflow: auto !important; } }

#particles-js {
  display: none;
  pointer-events: none; }

.mapIsOpen section.contact a,
.mapIsOpen section.contact .showMap {
  cursor: pointer; }

.mapIsOpen #particles-js {
  display: block;
  pointer-events: none; }

.square {
  font-size: 2rem;
  width: 100em;
  height: 100em;
  position: fixed;
  right: -100em;
  transform: rotate(45deg) translateZ(0);
  transform-origin: 50% top;
  opacity: 0.3; }
  .square.sq01 {
    background: #5df4d1;
    top: -50em; }
  .square.sq02 {
    background: #2b8fb3;
    margin-top: 100em; }
  .square.sq03 {
    width: 150em;
    height: 150em;
    right: -150em; }

.tunnel {
  background: #2c424d;
  width: 100vw;
  height: 100vh;
  padding: 0 20vw 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  font-size: 1rem; }
  @media all and (max-width: 600px) {
    .tunnel {
      padding: 0 30px 5vh; } }
  .tunnel .content {
    position: relative;
    width: 100%;
    max-width: 700px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start; }
  .tunnel .analogo {
    margin: 17.5vh 0 5vh;
    flex: 1 0 100%;
    text-align: center; }
    .tunnel .analogo img,
    .tunnel .analogo svg {
      width: 12.5em;
      max-width: 175px;
      height: auto;
      margin: 0 auto; }
  .tunnel .claim {
    position: relative;
    width: 100%;
    margin: 0 0 6.5vh; }
    .tunnel .claim svg {
      width: 100%; }
      .tunnel .claim svg text {
        font-family: "analog", sans-serif;
        font-weight: 250;
        text-transform: uppercase;
        font-size: 27.6px;
        fill: url(#logo-gradient);
        transform: translate(0, 22px); }
        .tunnel .claim svg text tspan {
          fill: #fff; }
  .tunnel .typo3 {
    position: absolute;
    right: 0;
    top: 0;
    width: 4%;
    transform: translate(100%, -100%); }
    .tunnel .typo3 svg {
      width: 100%;
      height: auto; }
  .tunnel .tunnelnav {
    width: 100%;
    position: relative;
    overflow: hidden; }
    .tunnel .tunnelnav ul {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-left: none;
      border-right: none;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
      .tunnel .tunnelnav ul li {
        width: 50%; }
        .tunnel .tunnelnav ul li a {
          cursor: pointer;
          display: block;
          font-size: 1.75em;
          height: 3.2em;
          font-weight: 400;
          padding: 1em 0;
          color: rgba(255, 255, 255, 0.5);
          transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
          position: relative; }
          @media all and (min-width: 1600px) {
            .tunnel .tunnelnav ul li a {
              font-size: 24px; } }
          @media all and (max-width: 450px) {
            .tunnel .tunnelnav ul li a {
              font-size: 1.35em;
              text-align: center; }
              .tunnel .tunnelnav ul li a span {
                position: static !important;
                transform: none !important; } }
          .tunnel .tunnelnav ul li a span {
            pointer-events: none;
            display: inline-block;
            transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
            position: relative;
            left: 0; }
          .tunnel .tunnelnav ul li a:hover, .tunnel .tunnelnav ul li a:focus, .tunnel .tunnelnav ul li a:active {
            color: #5df5d2; }
            .tunnel .tunnelnav ul li a:hover span, .tunnel .tunnelnav ul li a:focus span, .tunnel .tunnelnav ul li a:active span {
              right: 50%;
              transform: translateX(-100%);
              transition-delay: 0.15s; }
        .tunnel .tunnelnav ul li:first-child {
          padding-right: 1.5em; }
          .tunnel .tunnelnav ul li:first-child a span {
            left: 100%;
            transform: translateX(-100%); }
          .tunnel .tunnelnav ul li:first-child a:hover, .tunnel .tunnelnav ul li:first-child a:focus, .tunnel .tunnelnav ul li:first-child a:active {
            color: #5df5d2; }
            .tunnel .tunnelnav ul li:first-child a:hover span, .tunnel .tunnelnav ul li:first-child a:focus span, .tunnel .tunnelnav ul li:first-child a:active span {
              left: 50%;
              transform: translateX(-50%);
              transition-delay: 0.15s; }
        .tunnel .tunnelnav ul li:last-child {
          text-align: left;
          padding-left: 1.5em; }
          .tunnel .tunnelnav ul li:last-child a:hover span, .tunnel .tunnelnav ul li:last-child a:focus span, .tunnel .tunnelnav ul li:last-child a:active span {
            right: auto;
            left: 50%;
            transform: translateX(-50%); }
    .tunnel .tunnelnav #highlight {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      height: 100%;
      color: #5df5d2;
      transition: all 0.5s;
      border-top: 2px solid currentColor;
      border-bottom: 2px solid currentColor; }
      .tunnel .tunnelnav #highlight.out {
        opacity: 0; }
      .tunnel .tunnelnav #highlight:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: #3bacbc;
        background: -moz-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
        background: -webkit-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
        background: linear-gradient(135deg, #3bacbc 35%, #50d7c8 130%);
        background-size: cover;
        background-attachment: scroll;
        opacity: 0.35; }
  .tunnel .social {
    bottom: 0;
    transform: translateY(7rem);
    display: block; }
    @media all and (max-height: 700px) {
      .tunnel .social {
        display: none; } }
    .tunnel .social a {
      padding: 0;
      width: 2em;
      height: 2em;
      line-height: 1.9em;
      border: 2px solid rgba(185, 193, 199, 0.35);
      border-radius: 50%;
      text-decoration: none !important;
      font-size: 2em; }
  .tunnel .impressum {
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    position: absolute;
    bottom: 4.16667vw;
    right: 4.16667vw;
    text-decoration: none;
    font-size: 1em; }
    .tunnel .impressum a {
      opacity: 0.5;
      transition: all 0.35s; }
      .tunnel .impressum a:hover {
        opacity: 1; }
    .tunnel .impressum .divider {
      margin: 0 0.5em;
      color: #5df5d2; }
    @media all and (min-width: 601px) {
      .tunnel .impressum {
        transform: rotate(90deg) translate3d(0, 0, 0);
        transform-origin: 100% 0;
        letter-spacing: 0.06em;
        width: 100%;
        bottom: 1rem;
        margin: 0;
        right: 1.8rem;
        justify-content: flex-end; } }
    @media all and (max-width: 600px) {
      .tunnel .impressum {
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        bottom: 15px; } }
    .tunnel .impressum:hover {
      color: #fff; }
  .tunnel.hidden {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transform: translateY(-100%);
    transition-delay: 0.75s; }
    .tunnel.hidden .analogo,
    .tunnel.hidden .claim,
    .tunnel.hidden .tunnelnav,
    .tunnel.hidden .social {
      transform: translateY(-30vh);
      opacity: 0;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .tunnel.hidden .analogo {
      transition-delay: 0.1s; }
    .tunnel.hidden .claim {
      transition-delay: 0.25s; }
    .tunnel.hidden .tunnelnav {
      transition-delay: 0.35s; }
    .tunnel.hidden .social {
      transition-delay: 0.4s; }

::selection {
  background: transparent;
  color: #4ed6c8; }

::-moz-selection {
  background: rgba(78, 214, 200, 0.5);
  background: transparent;
  color: #4ed6c8; }

section {
  color: #fff; }
  section h1,
  section .h1 {
    font-size: 10em;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0;
    line-height: 1;
    position: relative;
    margin: 0 0 6vw; }
    section h1 span,
    section .h1 span {
      display: block;
      margin-bottom: 0.25em;
      left: 0.4%;
      bottom: 100%;
      line-height: 1;
      color: #4ed6c8;
      font-weight: 600;
      letter-spacing: 0;
      font-size: 0.33333em;
      text-transform: uppercase; }
  section h2,
  section .h2 {
    font-size: 3.6em;
    letter-spacing: -0.03em;
    text-transform: none;
    font-weight: 700;
    line-height: 1;
    margin: 4.16667vw 0 2.08333vw 0;
    padding: 0 0 0 0.5em;
    position: relative; }
    section h2 + p,
    section .h2 + p {
      margin-top: 0 !important; }
    section h2:before,
    section .h2:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0.15em;
      background: #4ed6c8;
      transform: translateX(-2em);
      opacity: 0; }
    section h2 span,
    section .h2 span {
      opacity: 0.4; }
      section h2 span.shuffled,
      section .h2 span.shuffled {
        opacity: 1; }
  section h3,
  section .h3 {
    font-size: 2.25em;
    margin-top: 0; }
  section p,
  section ul,
  section ol {
    font-weight: 200;
    font-size: 1.8em;
    line-height: 1.35;
    margin: 4.16667vw 0;
    letter-spacing: 0.015em; }
    section p + p,
    section ul + p,
    section ol + p {
      margin-top: calc(2.08333vw * -1); }
    section p a,
    section ul a,
    section ol a {
      color: #fff;
      position: relative;
      background-image: linear-gradient(120deg, #4ed6c8 0%, #5df5d2 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.125em;
      background-position: 0 95%;
      transition: background-size 0.2s, color 0.2s;
      text-decoration: none; }
      section p a:before,
      section ul a:before,
      section ol a:before {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #216d88; }
      section p a:hover, section p a:focus,
      section ul a:hover,
      section ul a:focus,
      section ol a:hover,
      section ol a:focus {
        color: #216d88;
        background-size: 100% 100%; }
    section p strong,
    section p b,
    section ul strong,
    section ul b,
    section ol strong,
    section ol b {
      font-weight: 600; }
    section p.intro,
    section ul.intro,
    section ol.intro {
      font-weight: 300;
      font-style: italic;
      font-size: 2.25em;
      line-height: 1.45;
      margin-top: -0.75em; }
  section blockquote {
    font-size: 1em;
    margin: 0;
    padding: 0; }
    section blockquote p {
      margin-left: 0;
      font-size: 3.2em;
      font-weight: 200;
      font-style: italic;
      text-align: left;
      line-height: 1.2;
      margin-bottom: 1.2em; }
  section ol {
    list-style: none;
    counter-reset: count;
    padding-left: 0; }
    section ol li {
      counter-increment: count;
      margin-bottom: 1.25em;
      position: relative;
      padding-left: 1.5em; }
      section ol li:before {
        position: absolute;
        left: 0;
        font-weight: 700;
        font-size: 0.8em;
        content: counter(count);
        color: #fff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        line-height: 1;
        background: #4ed6c8;
        margin-top: 0.25em;
        transform: translateX(-0.25em); }
      section ol li:last-child {
        margin-bottom: 0; }

@media all and (min-width: 1281px) {
  section h2,
  section .h2 {
    margin-left: 8.33333vw; }
  section h3,
  section .h3 {
    margin-left: 8.33333vw; }
  section p,
  section ul {
    margin-left: 8.33333vw; } }

@media all and (max-width: 1280px) {
  section h1,
  section .h1 {
    font-size: 8vw; }
  section h2,
  section .h2 {
    margin-left: 0; }
    section h2:before,
    section .h2:before {
      transform: translateX(-2vw); }
    section h2.appeared:before,
    section .h2.appeared:before {
      transform: translateX(0); }
  section p,
  section ul {
    margin-left: 8.33333vw; } }

@media all and (max-width: 1024px) {
  section h1,
  section .h1 {
    font-size: 10vw; }
  section h2,
  section .h2 {
    font-size: 5vw;
    margin-top: 10vw;
    margin-bottom: 4vw; } }

@media all and (max-width: 1023px) {
  section h1,
  section .h1 {
    margin-bottom: 4.16667vw; }
  section h2,
  section .h2 {
    margin: 8.33333vw 0 4.16667vw; }
    section h2 > span:last-child,
    section h2 > div:last-child,
    section .h2 > span:last-child,
    section .h2 > div:last-child {
      color: #5df5d2; }
    section h2:before,
    section .h2:before {
      transform: translateX(0); }
  section p,
  section ul {
    font-size: 1.75em;
    margin-bottom: 4.16667vw;
    margin-left: 0;
    font-weight: 200; }
    section p.intro,
    section ul.intro {
      font-size: 2.25em;
      margin-left: 0; } }

@media (max-width: 800px) {
  section p {
    font-size: 1.4em; } }

@media all and (max-width: 499px) {
  section h1,
  section .h1 {
    font-size: 4em; }
  section h2,
  section .h1 {
    font-size: 3em; }
  section p.intro {
    font-size: 2em;
    line-height: 1.45; } }

@media all and (max-width: 600px) {
  section h1,
  section .h1 {
    font-size: 6em; }
  section h2,
  section .h1 {
    font-size: 2.5em;
    margin-top: 16.66667vw; }
  section p.intro {
    font-size: 2em;
    line-height: 1.45; } }

@media all and (max-width: 480px) {
  section h1,
  section .h1 {
    font-size: 6em; }
  section p.intro {
    font-size: 2em;
    margin-top: 0; } }

@media all and (max-width: 414px) {
  section h1,
  section .h1 {
    font-size: 5em; }
  section h2,
  section .h2 {
    font-size: 2.5em; } }

@media (max-width: 374px) {
  section h1,
  section .h1 {
    font-size: 4.4em; } }

/* .wf-loading{
  .sideBar{
    transition-delay: 2s;
    width: 80vw;
    transition: $t-medium;
    &:before{
      transform: translateX(0);
      transition-delay: 0s;
    }
    .social{
      opacity: 0;
    }
    .burger{
      opacity: 0;
      span{
        opacity: 0;
      }
    }
  }
  .sliderNav, .sliderNav.scrollUp {
    transform: translateY(120%);
    transition: $t-fast;
    transition-delay: 0s;
  }
} */
html.loading .gr-main,
html.loading .gr-main * {
  transition: none !important; }

.logo {
  max-width: 150px;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  transition-delay: 1s; }

.gr-menu,
.mainBar {
  z-index: 100;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; }

.content {
  position: relative; }
  .content section {
    top: 0;
    display: block;
    position: fixed;
    max-width: 100vw;
    overflow: hidden;
    opacity: 0; }
    .content section.active {
      -webkit-overflow-scrolling: touch;
      opacity: 1;
      transition: all 1s;
      transition-delay: 0.25s; }
    .content section.inactive {
      opacity: 0;
      height: auto;
      transition: all 0.5s; }

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%) scale(0);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
  opacity: 0; }

.resizing section *,
.resizing .gr-main,
.resizing .sideNav,
.resizing .slider {
  transition: none !important;
  transition-delay: 0s !important; }

.resizing .mainBar,
.resizing .mainBar * {
  transition: none !important;
  transition-delay: 0s !important; }

.resizing .gr-menu {
  transition: none !important;
  transition-delay: 0s !important; }

.wf-loading body {
  overflow: hidden !important; }

.wf-loading .loader {
  transform: translate(-50%, -50%) scale(1);
  transition-delay: 0.35s;
  opacity: 1; }

.wf-loading .gr-menu,
.wf-loading .mainBar,
.wf-loading .sideBar {
  width: 100%;
  opacity: 1 !important;
  transition: all 0s;
  transition-delay: 0s; }
  .wf-loading .gr-menu .phone,
  .wf-loading .mainBar .phone,
  .wf-loading .sideBar .phone {
    opacity: 0; }

.wf-loading .mainBar {
  background-color: #2c424d !important; }

.wf-loading .logo {
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
  opacity: 0; }
  .wf-loading .logo img.small {
    opacity: 0;
    visibility: hidden; }

.wf-loading .burger,
.wf-loading .mainNav,
.wf-loading .social {
  opacity: 0;
  visibility: hidden; }

@media screen and (max-width: 499px) {
  .wf-loading .mainBar,
  .wf-loading .gr-menu {
    height: 100vh; }
  .wf-loading .mainNav {
    opacity: 0;
    transition-delay: 0s; } }

@media screen and (max-width: 479px) {
  .loader {
    transform: translate(-50%, -50%) scale(0); } }

.button {
  cursor: pointer;
  background: transparent;
  border: 2px solid #fff;
  line-height: 1;
  width: auto;
  display: inline-block;
  text-decoration: none;
  padding: 1em 1.4em;
  margin: 1em 0;
  text-align: center;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  position: relative;
  overflow: hidden;
  min-width: 250px; }
  @media all and (min-width: 1281px) {
    .button {
      font-size: 1rem; } }
  .button span {
    position: relative;
    z-index: 10;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    font-size: 2em;
    min-height: 1em;
    display: block;
    text-decoration: none;
    transform: scale(0.85);
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .button:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scaleY(0);
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    background: #216d88;
    opacity: 0; }
  .button:hover {
    background: rgba(33, 109, 136, 0.5); }
    .button:hover span {
      transform: scale(1); }
    .button:hover:before {
      opacity: 0.5;
      transform: translate(-50%, -50%) scaleY(1); }
  .button.btn-green {
    background: #5df5d2;
    background: #2b8fb3;
    background: -moz-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: -webkit-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: linear-gradient(135deg, #2b8fb3 35%, #5df4d1 130%);
    background-attachment: fixed;
    background-size: 120%;
    background-attachment: unset;
    border: none; }
  .button.btn-block {
    display: block; }

.projectSlideButton {
  justify-content: space-between; }

.projectSlideButton,
.bigButton {
  border-top: 1px solid #fff;
  padding-right: 0;
  margin: 5em 0;
  margin-left: 8.33333vw;
  position: relative;
  text-align: left;
  display: flex; }
  .projectSlideButton .button,
  .bigButton .button {
    padding: 1em 0;
    display: block;
    text-align: right;
    overflow: visible; }
    .projectSlideButton .button.visitSite,
    .bigButton .button.visitSite {
      margin-left: auto;
      border: none; }
      .projectSlideButton .button.visitSite span,
      .bigButton .button.visitSite span {
        font-size: 1.75em;
        display: block;
        transform: scale(1); }
        .projectSlideButton .button.visitSite span:after,
        .bigButton .button.visitSite span:after {
          font-family: "analog-icons" !important;
          speak: none;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: inline-block;
          transform: scale(0.5) translate(15px, 10%);
          transform-origin: center center;
          content: "";
          transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
      .projectSlideButton .button.visitSite:before,
      .bigButton .button.visitSite:before {
        display: none;
        top: 0;
        transform: none;
        height: 2px;
        width: 20px; }
      .projectSlideButton .button.visitSite:hover,
      .bigButton .button.visitSite:hover {
        background: none !important; }
        .projectSlideButton .button.visitSite:hover span:after,
        .bigButton .button.visitSite:hover span:after {
          transform: scale(0.5) translate(25px, 10%); }
        .projectSlideButton .button.visitSite:hover span:before,
        .bigButton .button.visitSite:hover span:before {
          transform: scale(0.5) translate(-25px, 10%) rotate(180deg); }
    .projectSlideButton .button.nextProject, .projectSlideButton .button.lastProject,
    .bigButton .button.nextProject,
    .bigButton .button.lastProject {
      width: calc(50% - 3px);
      display: block; }
      .projectSlideButton .button.nextProject:before, .projectSlideButton .button.lastProject:before,
      .bigButton .button.nextProject:before,
      .bigButton .button.lastProject:before {
        display: none; }
    .projectSlideButton .button.nextProject,
    .bigButton .button.nextProject {
      text-align: right; }
    .projectSlideButton .button.lastProject,
    .bigButton .button.lastProject {
      text-align: left;
      margin-left: 0; }
      .projectSlideButton .button.lastProject span:after,
      .bigButton .button.lastProject span:after {
        display: none; }
      .projectSlideButton .button.lastProject span:before,
      .bigButton .button.lastProject span:before {
        font-family: "analog-icons" !important;
        speak: none;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        transform: scale(0.5) translate(-15px, 10%) rotate(180deg);
        transform-origin: center center;
        content: "";
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }

@media all and (max-width: 1023px) {
  .projectSlideButton {
    margin-left: 0; }
  .button {
    min-width: 30%; } }

@media all and (max-width: 499px) {
  .projectSlideButton .button.nextProject {
    display: block;
    width: 100%; }
  .projectSlideButton .button.lastProject {
    display: none; } }

@media all and (max-width: 479px) {
  .button {
    margin-left: 0 !important;
    min-width: 100%;
    display: block; } }

section .tablewrap {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

section table {
  min-width: 1000px; }
  section table th,
  section table td {
    border-bottom: 1px solid #fff; }
  section table th,
  section table td {
    border-right: 1px solid #fff;
    padding: 15px; }
    section table th:last-child,
    section table td:last-child {
      border-right: none; }
  section table tr:last-child td {
    border-bottom: none; }
  section table h2,
  section table h3,
  section table h4,
  section table h5 {
    margin: 0.5em 0; }
  section table ul,
  section table ol {
    margin: 0 0 30px;
    padding-left: 18px;
    font-size: 16px; }
  @media screen and (max-width: 1023px) {
    section table {
      display: block; } }

p {
  opacity: 0;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  transition-delay: 0s;
  transform: translateY(30px); }
  p.appeared {
    transform: translateX(0);
    opacity: 1; }

@media (min-width: 320px) {
  section.imageHeader, section.jumpNav {
    transform: translateY(0);
    opacity: 1; }
  section p,
  section > h3,
  section article,
  section .h2 {
    opacity: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
    transition-delay: 0s;
    transform: translateY(30px); }
    section p.appeared,
    section > h3.appeared,
    section article.appeared,
    section .h2.appeared {
      transform: translateX(0);
      opacity: 1; }
  section h1 + p.intro,
  section h1 + p:first-child,
  section h1 + p.intro,
  section p:first-of-type {
    transition-delay: 0.75s; }
  section h1 + p:first-child,
  section h1 + p.intro + p {
    transition-delay: 0.9s; }
  section h2 {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transform: translateX(10vw);
    opacity: 0; }
    section h2:before {
      opacity: 0;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
    section h2.appeared {
      transition-delay: 0.4s;
      transform: translateX(0);
      opacity: 1;
      height: auto !important; }
      section h2.appeared:before {
        opacity: 1;
        transform: none;
        transition-delay: 0.6s; }
      section h2.appeared .shuffled {
        height: auto !important; }
  .msIE section:not(.fixedBgImage),
  .msIE footer .liga-logo,
  .msIE footer .ksc-logo,
  .msIE .sponsoren .sponsor,
  .msIE .hauptsponsor .klaiber-logo,
  .msIE .textElement {
    transform: none;
    opacity: 1; } }

@media (min-width: 1024px) {
  section h2.appeared {
    transform: translateX(0); } }

@media (min-width: 1280px) {
  section h2.appeared {
    transform: translateX(0); } }

.burger {
  cursor: pointer;
  width: 26px;
  height: 14px;
  position: relative;
  display: inline-block;
  z-index: 100;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  margin: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .burger:before {
    content: "";
    background-color: #4ed6c8;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    position: absolute;
    z-index: 1;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .burger:before.tablet {
      background-color: rgba(78, 214, 200, 0.6); }
  .burger.mobile:before {
    border-radius: 5em; }
  .burger span {
    position: absolute;
    z-index: 2;
    display: block;
    background: currentColor;
    bottom: auto;
    width: 100%;
    height: 2px;
    border-radius: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
    .burger span:nth-child(1) {
      top: 0;
      transform: none; }
    .burger span:nth-child(2) {
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      width: 0;
      left: 50%; }
    .burger span:nth-child(3) {
      bottom: 0;
      transform: none; }
  .no-touch .burger:hover span:nth-child(2) {
    opacity: 1;
    width: 100%; }
  .burger.burgerOpen {
    transform: translateX(-50%) rotate(-135deg) scale(0.75);
    overflow: visible;
    color: #fff; }
    .burger.burgerOpen:before {
      opacity: 1; }
    .burger.burgerOpen span:nth-child(1) {
      top: 50%;
      transform: rotate(-90deg) translateX(0%); }
    .burger.burgerOpen span:nth-child(2) {
      top: 50% !important;
      opacity: 0 !important;
      transform: none;
      visibility: hidden; }
    .burger.burgerOpen span:nth-child(3) {
      top: 50%; }
    .no-touch .burger.burgerOpen:hover:before {
      background: #216d88; }
    .burger.burgerOpen.mobile:before {
      background: rgba(93, 245, 210, 0.15);
      transform: translate3d(-50%, -50%, 0) scale(3.5); }
    .burger.burgerOpen.tablet {
      color: #2c424d;
      transform: translateX(0) rotate(-135deg) scale(0.5); }

.burger.tablet, .burger.desktop {
  display: none; }

@media all and (min-width: calc(500px)) and (max-width: 1023px) {
  .burger.tablet {
    display: block;
    margin-top: 1.4rem;
    transform: none;
    left: 1em; } }

@media all and (min-width: 1024px) {
  .burger.desktop {
    display: block;
    color: #216d88;
    margin-top: 3.75rem; }
    .burger.desktop.burgerOpen {
      color: #fff; } }

section article.newsArticle,
section div.newsArticle {
  margin-bottom: 8.33333vw; }
  @media all and (min-width: 1024px) {
    section article.newsArticle,
    section div.newsArticle {
      margin-left: 8.33333vw;
      margin-bottom: 8.33333vw; } }
  @media all and (min-width: 1281px) {
    section article.newsArticle,
    section div.newsArticle {
      margin-left: 16.66667vw;
      margin-bottom: 8.33333vw; } }
  section article.newsArticle h3,
  section div.newsArticle h3 {
    font-size: 2.75em; }
  section article.newsArticle p,
  section article.newsArticle h3,
  section article.newsArticle .bigButton,
  section div.newsArticle p,
  section div.newsArticle h3,
  section div.newsArticle .bigButton {
    margin-left: 0; }
  section article.newsArticle .newsLatest_img,
  section div.newsArticle .newsLatest_img {
    margin-bottom: 2.08333vw; }
    @media all and (min-width: 680px) {
      section article.newsArticle .newsLatest_img.floating_img,
      section div.newsArticle .newsLatest_img.floating_img {
        float: right;
        margin: 0 0 2.08333vw 2.08333vw;
        max-width: calc(50% - 2.08333vw); } }

section article.newsDetail > .h2:first-child, section article.newsList > .h2:first-child,
section div.newsDetail > .h2:first-child,
section div.newsList > .h2:first-child {
  color: #5df5d2;
  display: none; }
  section article.newsDetail > .h2:first-child span, section article.newsList > .h2:first-child span,
  section div.newsDetail > .h2:first-child span,
  section div.newsList > .h2:first-child span {
    opacity: 1;
    color: #fff; }

section article.newsDetail .h2, section article.newsList .h2,
section div.newsDetail .h2,
section div.newsList .h2 {
  margin: 0;
  font-size: 3.33333em; }
  @media all and (min-width: 1024px) {
    section article.newsDetail .h2, section article.newsList .h2,
    section div.newsDetail .h2,
    section div.newsList .h2 {
      margin: 2.08333vw 0 0 0; } }
  section article.newsDetail .h2 + h1,
  section article.newsDetail .h2 + .h1, section article.newsList .h2 + h1,
  section article.newsList .h2 + .h1,
  section div.newsDetail .h2 + h1,
  section div.newsDetail .h2 + .h1,
  section div.newsList .h2 + h1,
  section div.newsList .h2 + .h1 {
    margin-top: 0;
    transform: none; }

section article.newsDetail .h2.subheader, section article.newsList .h2.subheader,
section div.newsDetail .h2.subheader,
section div.newsList .h2.subheader {
  margin-bottom: 4.16667vw; }

section article.newsDetail p, section article.newsList p,
section div.newsDetail p,
section div.newsList p {
  margin-left: 0;
  padding-left: 0; }

section article.newsDetail h3.h2, section article.newsList h3.h2,
section div.newsDetail h3.h2,
section div.newsList h3.h2 {
  margin-top: 2.08333vw;
  margin-bottom: 2.08333vw; }
  @media all and (min-width: 1024px) {
    section article.newsDetail h3.h2, section article.newsList h3.h2,
    section div.newsDetail h3.h2,
    section div.newsList h3.h2 {
      margin-left: -4.16667vw; } }
  section article.newsDetail h3.h2 span, section article.newsList h3.h2 span,
  section div.newsDetail h3.h2 span,
  section div.newsList h3.h2 span {
    opacity: 1;
    color: currentColor; }

section article .newsDetail--img,
section div .newsDetail--img {
  margin-bottom: 2.08333vw; }

section article .newsDetail-imagebox,
section div .newsDetail-imagebox {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.04167vw; }
  section article .newsDetail-imagebox .col-6,
  section div .newsDetail-imagebox .col-6 {
    width: 50%;
    flex: 1 0 50%;
    padding: 0 1.04167vw 2.08333vw; }

section article a.news--listitem,
section div a.news--listitem {
  display: block;
  border-left: 0.5em solid #5df5d2;
  padding-left: 1.5em;
  margin-bottom: 4.16667vw;
  text-decoration: none; }
  section article a.news--listitem *,
  section div a.news--listitem * {
    text-decoration: none; }
  section article a.news--listitem:-webkit-any-link, section article a.news--listitem:link,
  section div a.news--listitem:-webkit-any-link,
  section div a.news--listitem:link {
    color: #fff;
    text-decoration: none; }
  section article a.news--listitem h2,
  section article a.news--listitem .h2,
  section div a.news--listitem h2,
  section div a.news--listitem .h2 {
    margin-bottom: 1.04167vw;
    position: relative;
    color: #fff; }
  section article a.news--listitem p,
  section div a.news--listitem p {
    transform: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s !important;
    transition-delay: 0s;
    margin: 0 0 2.08333vw;
    color: #fff; }
  section article a.news--listitem .date,
  section div a.news--listitem .date {
    font-weight: 800;
    font-size: 0.45em;
    line-height: 1;
    text-align: right;
    color: #5df5d2;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-4.16667vw, 7%, 0);
    display: none !important; }
    @media all and (max-width: 1024px) {
      section article a.news--listitem .date,
      section div a.news--listitem .date {
        display: block;
        position: static;
        transform: none;
        text-align: left;
        margin-bottom: 0.52083vw; }
        section article a.news--listitem .date div,
        section div a.news--listitem .date div {
          display: inline; } }

section article .news--listitem_footer,
section div .news--listitem_footer {
  display: flex;
  text-decoration: none;
  color: #5df5d2; }
  section article .news--listitem_footer span,
  section div .news--listitem_footer span {
    box-sizing: content-box;
    color: currentColor;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.6em;
    min-width: 6.5em;
    padding: 0;
    text-transform: uppercase;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    position: relative; }
    section article .news--listitem_footer span:after,
    section div .news--listitem_footer span:after {
      font-family: "analog-icons" !important;
      speak: none;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: scale(0.5) translate(15px, 10%);
      transform-origin: center center;
      content: "";
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      position: absolute;
      right: 0;
      top: 0.02em; }

@media all and (min-width: 1024px) {
  body .newsList {
    margin-left: 8.33333vw; } }

body .news--body.list {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100% !important;
  margin: 0 -2.08333vw;
  padding: 0; }
  body .news--body.list > * {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 0 2.08333vw !important;
    margin-bottom: 4.16667vw; }
    body .news--body.list > * > * {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-width: 100%;
      padding: 0;
      margin: 0; }
      body .news--body.list > * > * > * {
        flex: 1;
        max-width: 100%; }
    @media all and (min-width: 768px) {
      body .news--body.list > * {
        flex: 1 0 30%;
        max-width: 33.33334%; } }
    @media all and (max-width: 767px) {
      body .news--body.list > * {
        flex: 1 0 50%;
        max-width: 50%; } }
  @media all and (max-width: 550px) {
    body .news--body.list {
      margin: 0; }
      body .news--body.list > * {
        flex: 1 0 100%;
        max-width: 100%;
        margin: 0 0 8.33333vw;
        padding: 0 !important; } }
  @media all and (min-width: 768px) {
    body .news--body.list .col {
      flex: 1 0 50%;
      max-width: 50%; } }

body section div .news--listitem,
body section div .news--listitem:link {
  border: none;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
  body section div .news--listitem:hover,
  body section div .news--listitem:link:hover {
    transform: scale(1.07);
    box-shadow: 0 0.5em 3em 0.4em rgba(33, 109, 136, 0.55); }
  body section div .news--listitem .inner,
  body section div .news--listitem:link .inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2.08333vw;
    background: rgba(33, 109, 136, 0.6);
    color: #2c424d; }
    @media screen and (max-width: 1023px) {
      body section div .news--listitem .inner,
      body section div .news--listitem:link .inner {
        padding: 4.16667vw; } }
    @media screen and (max-width: 550px) {
      body section div .news--listitem .inner,
      body section div .news--listitem:link .inner {
        padding: 8.33333vw; } }
    body section div .news--listitem .inner h2,
    body section div .news--listitem .inner .h2,
    body section div .news--listitem:link .inner h2,
    body section div .news--listitem:link .inner .h2 {
      margin-top: 0;
      font-size: 2.5em;
      color: #4ed6c8;
      padding-left: 0; }
      body section div .news--listitem .inner h2:before,
      body section div .news--listitem .inner .h2:before,
      body section div .news--listitem:link .inner h2:before,
      body section div .news--listitem:link .inner .h2:before {
        content: none; }
    body section div .news--listitem .inner p,
    body section div .news--listitem:link .inner p {
      font-size: 1.4em;
      font-weight: 200;
      color: #fff; }
    body section div .news--listitem .inner .news--listitem_footer,
    body section div .news--listitem:link .inner .news--listitem_footer {
      margin-top: auto;
      color: #fff; }

@media screen and (min-width: 1281px) {
  body article.newsDetail .news--body {
    margin-left: 8.33333vw; } }

body article.newsDetail h1 {
  font-size: 8em;
  line-height: 0.8; }

body article.newsDetail .h2.date {
  display: block !important;
  margin: 0.25em 0 0;
  padding: 0; }
  body article.newsDetail .h2.date + h1 {
    margin-top: 0 !important; }

body article.newsDetail .news--text {
  opacity: 0;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  transition-delay: 0s;
  transform: translateY(30px); }
  body article.newsDetail .news--text.appeared {
    transform: translateX(0);
    opacity: 1; }
  body article.newsDetail .news--text p {
    opacity: 1;
    transform: translateY(0); }
    body article.newsDetail .news--text p.appeared {
      transform: translateX(0);
      opacity: 1; }

@media screen and (max-width: 550px) {
  body article.newsDetail h1 {
    font-size: 5em; }
  body article.newsDetail h2 {
    font-size: 2.5em; } }

@media screen and (min-width: 768px) {
  body.home .news--body.list > * {
    max-width: 100%; } }

ul#movingLogos {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block !important;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  overflow: hidden;
  opacity: 0.4;
  background: #2b8fb3;
  background: -moz-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
  background: -webkit-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
  background: linear-gradient(135deg, #2b8fb3 35%, #5df4d1 130%);
  background-attachment: fixed; }

li.layer {
  position: relative;
  width: 130%;
  height: 130%;
  left: -15% !important;
  top: -15% !important;
  text-align: center; }

.starfield .layer .img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  animation-direction: normal;
  background-repeat: repeat; }

.starfield #img-3 {
  background-image: url(../../../Images/Scss-Assets/parallax/bg-dust_green.png); }

.starfield #img-2 {
  background-image: url(../../../Images/Scss-Assets/parallax/dust-medio_green.png); }

.starfield #img-1 {
  background-image: url(../../../Images/Scss-Assets/parallax/dust-delante_green.png); }

@keyframes pulse {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

.logos .layer .img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: pulse 1s ease-in-out infinite normal;
  background-size: 115vw;
  background-repeat: repeat;
  opacity: 0.3; }

.logos #img-4 {
  background-image: url(../../../Images/Scss-Assets/parallax/level-01.svg);
  animation: pulse 3s ease-in-out infinite; }

.logos #img-3 {
  background-image: url(../../../Images/Scss-Assets/parallax/level-02.svg);
  animation: pulse 5s ease-in-out infinite; }

.logos #img-2 {
  background-image: url(../../../Images/Scss-Assets/parallax/level-03.svg);
  animation: pulse 7s ease-in-out infinite; }

.logos #img-1 {
  background-image: url(../../../Images/Scss-Assets/parallax/level-04.svg);
  animation: pulse 11s ease-in-out infinite; }

@keyframes pulse {
  0%,
  100% {
    opacity: 0.3; }
  50% {
    opacity: 0.1; } }

@media all and (max-width: 1024px) {
  .logos .layer .img {
    background-size: 145vw; } }

@media all and (max-width: 1023px) {
  ul#movingLogos {
    width: 100%; } }

[hidden],
.uk-hidden {
  display: none !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slider {
  z-index: 10; }

.imgSlider {
  margin-top: 4.16667vw;
  margin-left: 8.33333vw; }
  .imgSlider figure.slick-slide {
    background: #eef0f2; }

.slick-arrow {
  position: absolute;
  z-index: 10;
  background: #eef0f2;
  color: #526773;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  right: -45px;
  top: 0;
  text-align: center;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
  cursor: pointer; }
  .slick-arrow.slick-prev {
    transform: rotate(-90deg);
    top: 40px;
    margin-top: 1px; }
  .slick-arrow.slick-next {
    transform: rotate(90deg); }
  .slick-arrow:hover, .slick-arrow:focus {
    background: #fff;
    color: #216d88; }

ul.slick-dots {
  font-size: 2em;
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0.5em 0 2em; }
  ul.slick-dots li {
    cursor: pointer;
    margin: 0 0.3em;
    padding: 0;
    list-style: none;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    position: relative; }
    ul.slick-dots li:before {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      display: block;
      content: "";
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      transform: translate3d(-50%, -50%, 0) scale(0.3);
      opacity: 0; }
    ul.slick-dots li:first-child {
      margin-left: 0; }
    ul.slick-dots li:last-child {
      margin-right: 0; }
    ul.slick-dots li:hover, ul.slick-dots li:focus {
      transform: scale(1.25); }
      ul.slick-dots li:hover:before, ul.slick-dots li:focus:before {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(1); }
    ul.slick-dots li.slick-active {
      background: #fff;
      cursor: default; }
      ul.slick-dots li.slick-active:hover, ul.slick-dots li.slick-active:focus {
        transform: scale(1); }
    ul.slick-dots li button {
      display: none; }

@media screen and (max-width: 1024px) {
  .imgSlider {
    margin-left: 0; } }

@media screen and (max-width: 1024px) {
  .slick-arrow {
    display: none !important;
    width: 8.33333vw;
    height: 8.33333vw;
    line-height: 8.33333vw;
    right: -8.33333vw; }
    .slick-arrow.slick-prev {
      top: 8.33333vw; } }

.item.pop {
  opacity: 0;
  transform: scale(0.25);
  font-size: 1rem; }
  .item.pop.uk-scrollspy-inview {
    opacity: 1;
    transform: scale(1); }
  .item.pop:after {
    opacity: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    transition-delay: 0s;
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: red;
    width: 100%;
    height: 25%;
    background: transparent;
    background: linear-gradient(0deg, #fff 10%, rgba(255, 255, 255, 0) 100%); }
  .item.pop a {
    color: #fff;
    text-decoration: none; }
  .item.pop .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: none;
    transform: translate(-50%, -50%);
    z-index: 30;
    background: #fff;
    color: #2c424d;
    width: 100%;
    height: 100%;
    padding: 2.5em;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 1.5rem 1.1rem -0.5rem #216d88;
    opacity: 0;
    pointer-events: none;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s, opacity cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s; }
    .item.pop .popup * {
      margin: 0;
      transform: none;
      opacity: 1; }
    .item.pop .popup h3,
    .item.pop .popup .h3,
    .item.pop .popup .popup-content {
      width: 100%; }
    .item.pop .popup .popup-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 100%;
      opacity: 0;
      transform: translateY(2em); }
    .item.pop .popup p,
    .item.pop .popup ul,
    .item.pop .popup ol,
    .item.pop .popup a.link {
      padding: 0;
      color: #2c424d;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 1.618em; }
      .item.pop .popup p + a.link,
      .item.pop .popup ul + a.link,
      .item.pop .popup ol + a.link,
      .item.pop .popup a.link + a.link {
        margin: -1em 0 1.618em; }
    .item.pop .popup a.link {
      position: relative;
      font-weight: 600;
      padding-right: 1em; }
      .item.pop .popup a.link:after {
        font-family: "analog-icons" !important;
        speak: none;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: scale(0.5) translate(15px, 10%);
        transform-origin: center center;
        content: "";
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
        position: absolute;
        right: 0;
        top: 0.02em; }
      .item.pop .popup a.link:hover:after {
        color: #4ed6c8;
        transform: scale(0.5) translate(30px, 10%); }
    .item.pop .popup p,
    .item.pop .popup ul,
    .item.pop .popup ol,
    .item.pop .popup h3,
    .item.pop .popup .h3,
    .item.pop .popup h4,
    .item.pop .popup .h4,
    .item.pop .popup .button {
      opacity: 0;
      transform: translateY(3rem);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
      min-width: 0; }
    .item.pop .popup ul {
      padding-left: 1.5rem; }
    .item.pop .popup h3,
    .item.pop .popup .h3 {
      margin-bottom: 0.5em;
      font-weight: 700;
      line-height: 1.15;
      color: #4ed6c8; }
    .item.pop .popup h4,
    .item.pop .popup .h4 {
      font-size: 1.4em;
      margin: -0.5em 0 1em; }
    .item.pop .popup .button {
      margin-top: auto;
      transition-delay: 0.5s; }
    .item.pop .popup .closePopup {
      font-size: 1.5rem;
      line-height: 1;
      position: absolute;
      z-index: 30;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      height: 1.1em;
      width: 1.1em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
      .item.pop .popup .closePopup:hover, .item.pop .popup .closePopup:focus, .item.pop .popup .closePopup:active {
        background-color: #4ed6c8;
        color: #fff; }
  .item.pop.popupOpen {
    pointer-events: none;
    cursor: auto;
    z-index: 30; }
    .item.pop.popupOpen:after {
      opacity: 1;
      transition-delay: 0.2s; }
    .item.pop.popupOpen .popup {
      width: 25rem;
      height: 25rem;
      opacity: 1;
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .item.pop.popupOpen .popup p,
      .item.pop.popupOpen .popup ul,
      .item.pop.popupOpen .popup ol,
      .item.pop.popupOpen .popup h3,
      .item.pop.popupOpen .popup .h3,
      .item.pop.popupOpen .popup h4,
      .item.pop.popupOpen .popup .h4,
      .item.pop.popupOpen .popup .button,
      .item.pop.popupOpen .popup .popup-content {
        transition-delay: 0.15s;
        opacity: 1;
        transform: none; }
      .item.pop.popupOpen .popup h3,
      .item.pop.popupOpen .popup .h3,
      .item.pop.popupOpen .popup .h4,
      .item.pop.popupOpen .popup h4,
      .item.pop.popupOpen .popup .popup-content {
        transition-delay: 0.15s; }
      .item.pop.popupOpen .popup p,
      .item.pop.popupOpen .popup ul,
      .item.pop.popupOpen .popup ol {
        transition-delay: 0.2s; }
      .item.pop.popupOpen .popup .button {
        transition-delay: 0.25s; }
      .item.pop.popupOpen .popup .h3,
      .item.pop.popupOpen .popup h4,
      .item.pop.popupOpen .popup .h4 {
        transition-delay: 0.1s;
        flex: none; }
  @media screen and (max-width: 1280px) {
    .item.pop .popup .closePopup {
      font-size: 2rem; }
    .item.pop .popup h3,
    .item.pop .popup .h3 {
      font-size: 3em; }
    .item.pop .popup p,
    .item.pop .popup ul,
    .item.pop .popup ol {
      font-size: 1.75em; }
    .item.pop.popupOpen .popup {
      width: 33rem;
      height: 33rem; } }
  @media all and (max-width: 599px) {
    .item.pop .popup {
      left: calc(2.08333vw * -1);
      transform: translate(0, -50%); }
    .item.pop:nth-child(2n) .popup {
      left: auto;
      right: calc(2.08333vw * -1);
      transform: translate(0, -50%); } }
  @media screen and (max-width: 412px) {
    .item.pop .popup {
      left: 50% !important;
      right: auto !important;
      transform: translate(-50%, -50%) !important; }
    .item.pop.popupOpen .popup {
      width: calc(100% + 8.33333vw);
      height: calc(100% + 8.33333vw); }
      .popupOpen .item.pop.popupOpen .popup h3,
      .popupOpen .item.pop.popupOpen .popup .h3,
      .popupOpen .item.pop.popupOpen .popup p,
      .popupOpen .item.pop.popupOpen .popup ul,
      .popupOpen .item.pop.popupOpen .popup ol {
        font-size: 3em; }
      .popupOpen .item.pop.popupOpen .popup p,
      .popupOpen .item.pop.popupOpen .popup ul,
      .popupOpen .item.pop.popupOpen .popup ol {
        font-size: 1.75rem; } }
  @media all and (min-width: 600px) and (max-width: 1199px) {
    .item.pop .popup {
      left: calc(2.08333vw * -1);
      transform: translate(0, -50%); }
    .item.pop:nth-child(2n) .popup {
      left: 50%;
      transform: translate(-50%, -50%); }
    .item.pop:nth-child(3n) .popup {
      left: auto;
      right: calc(2.08333vw * -1);
      transform: translate(0, -50%); }
    .item.pop:nth-child(4n) .popup {
      left: calc(2.08333vw * -1);
      transform: translate(0, -50%); }
    .item.pop:nth-child(5n) .popup, .item.pop:nth-child(8n) .popup {
      left: 50%;
      transform: translate(-50%, -50%); } }

.referenzen .item.pop:after,
.logoBadges .item.pop:after {
  content: none; }

.gr-menu {
  z-index: 31;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }

.mainBar {
  background: url("../../../Images/Scss-Assets/patternBG_01.png") #2c424d;
  color: #fff;
  will-change: transform;
  transform: translate3d(0, 0, 0); }
  .mainBar .phone,
  .mainBar .imprint {
    display: none;
    text-align: center;
    position: absolute;
    bottom: 4em;
    left: 0;
    width: 100%;
    padding: 0 4.16667vw;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transition-delay: 1s; }
    .mainBar .phone span,
    .mainBar .imprint span {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.95em;
      color: #4ed6c8; }
    .mainBar .phone a,
    .mainBar .phone .divider,
    .mainBar .imprint a,
    .mainBar .imprint .divider {
      font-size: 1.3em;
      color: #fff;
      text-decoration: none; }

.impressum,
.impressum.bottomInfo,
.imprint.mobile {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: 20px; }
  .impressum .divider,
  .impressum.bottomInfo .divider,
  .imprint.mobile .divider {
    margin: 0 0.25em; }
  .impressum a,
  .impressum .divider,
  .impressum.bottomInfo a,
  .impressum.bottomInfo .divider,
  .imprint.mobile a,
  .imprint.mobile .divider {
    font-size: 0.9em;
    transition: color 0.25s;
    color: #fff;
    text-decoration: none; }
    .impressum a:hover, .impressum a:focus, .impressum a:active,
    .impressum .divider:hover,
    .impressum .divider:focus,
    .impressum .divider:active,
    .impressum.bottomInfo a:hover,
    .impressum.bottomInfo a:focus,
    .impressum.bottomInfo a:active,
    .impressum.bottomInfo .divider:hover,
    .impressum.bottomInfo .divider:focus,
    .impressum.bottomInfo .divider:active,
    .imprint.mobile a:hover,
    .imprint.mobile a:focus,
    .imprint.mobile a:active,
    .imprint.mobile .divider:hover,
    .imprint.mobile .divider:focus,
    .imprint.mobile .divider:active {
      color: #5df5d2; }
    @media all and (max-width: 1299px) {
      .impressum a,
      .impressum .divider,
      .impressum.bottomInfo a,
      .impressum.bottomInfo .divider,
      .imprint.mobile a,
      .imprint.mobile .divider {
        font-size: 1.1em; } }

.imprint.mobile {
  display: none; }
  @media screen and (max-width: 1023px) {
    .imprint.mobile {
      display: flex; } }

.sideBar {
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
  will-change: transform; }
  .sideBar:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #2b8fb3;
    background: -moz-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: -webkit-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: linear-gradient(135deg, #2b8fb3 35%, #5df4d1 130%);
    background-attachment: fixed;
    transform: translateX(-100%);
    transition: all 0.75s ease-in-out;
    transition-delay: 0.25s; }
  .sideBar .social a {
    font-size: 1.5em;
    margin: 0.75em 0.3em 0;
    display: block; }
    .sideBar .social a:hover {
      color: #216d88; }

.logo {
  display: block;
  padding: 10px 10px 0;
  margin-top: 5px;
  top: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .logo .big {
    display: none; }
  .logo .small {
    display: block;
    margin-bottom: 4rem; }

@media screen and (max-width: 1499px) {
  .logo {
    transform: translateX(-50%) scale(0.75);
    transform-origin: center top; } }

@media screen and (min-width: 1024px) {
  .logo {
    margin: 0;
    padding: 0; }
    .logo .big {
      display: block; }
    .logo .small {
      display: none; }
  .mainBar .mainNav,
  .mainBar .social {
    display: none; }
  .mainBar .phone {
    display: block; }
  .sideBar {
    transition-delay: 0.25s; }
  .gr-main {
    transition-delay: 0.35s; }
  .desktopNav-open .sideBar {
    width: 20.83334vw;
    transition-delay: 0s; }
  .desktopNav-open .gr-main {
    transform: translateX(16.66667vw);
    transition-delay: 0s; } }

@media screen and (max-width: 499px) {
  .mainBar {
    background: url("../../../Images/Scss-Assets/patternBG_01.png") #323538;
    color: #fff;
    height: 100%; } }

@keyframes burgerSlide {
  0% {
    left: -26px; }
  100% {
    left: 0;
    opacity: 1; } }

.gr-main section:not(.welcome) {
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }

body[class*="-open"] .gr-main section:not(.welcome) {
  filter: blur(5px); }

.mainNav {
  margin: 4.16667vw 0;
  transform: translate3d(0, 0, 0);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  will-change: opacity, transform; }
  .mainNav li a {
    cursor: pointer;
    display: block;
    color: #eef0f2;
    font-weight: 400;
    height: 3.25em;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
    font-size: 1.5em;
    letter-spacing: 0.075em; }
    .mainNav li a span {
      vertical-align: middle;
      display: inline-block;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .mainNav li a span.analog-icon {
      font-size: 1.5em;
      width: auto;
      margin-right: 0.5em;
      color: #4ed6c8; }
    .mainNav li a:hover span.analog-icon, .mainNav li a:focus span.analog-icon {
      color: #fff; }
  .mainNav li:last-child a {
    height: auto; }

.social {
  position: absolute;
  width: 100%;
  left: 0;
  border: none;
  bottom: 22px;
  text-align: center;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s; }
  .social a {
    text-decoration: none !important;
    font-size: 2em;
    margin: 0.75em 0.3em 0;
    display: inline-block;
    color: #b9c1c7;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .social a:hover, .social a:focus {
      color: #216d88; }
      @media screen and (max-width: 1023px) {
        .social a:hover, .social a:focus {
          color: #fff; } }

.desktopNav-open .sideBar .social {
  opacity: 0 !important;
  visibility: hidden; }

@media screen and (min-width: 1024px) {
  .scrollTop {
    display: none; }
  .mainNav {
    margin: 5vw 0 0;
    opacity: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    transition-delay: 0s;
    transform: translate(-50%, 5vh); }
    .mainNav li a {
      height: auto;
      color: #526773;
      font-size: 1.5em;
      padding: 0.85em 0;
      margin: 0 4.16667vw;
      border-bottom: 1px solid rgba(185, 193, 199, 0.35);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
      .mainNav li a span {
        transition: none;
        font-weight: 200;
        text-transform: none; }
      .mainNav li a span.analog-icon {
        display: none; }
      .mainNav li a:hover, .mainNav li a:active {
        letter-spacing: 0.35em;
        color: #4ed6c8; }
    .mainNav li.active a {
      color: #216d88; }
      .mainNav li.active a span {
        font-weight: 400; }
    .mainNav li:first-child a {
      border-top: 1px solid rgba(185, 193, 199, 0.35); }
    .mainNav li:last-child a {
      border: none; }
    .desktopNav-open .mainNav {
      opacity: 1;
      transform: translate(-50%, 0);
      transition-delay: 0.25s; } }

@media screen and (max-width: 499px) {
  .gr-menu {
    height: 65px; }
  .mainNav {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transition-delay: 0.75s;
    margin: 0; }
    .mainNav > ul {
      display: flex; }
      .mainNav > ul > li {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center; }
        .mainNav > ul > li:nth-child(4) {
          border: none; }
        .mainNav > ul > li:nth-child(n + 5) {
          display: none; }
        .mainNav > ul > li a {
          height: 65px;
          padding: 0;
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .mainNav > ul > li a span.analog-icon, .mainNav > ul > li a span.text {
            display: block;
            text-align: center;
            width: 100%;
            margin-right: 0; }
          .mainNav > ul > li a span.text {
            font-size: 12px;
            color: #b9c1c7;
            font-weight: 600;
            margin-top: 4px;
            letter-spacing: 0.025em; }
          .mainNav > ul > li a:hover span.text, .mainNav > ul > li a:focus span.text, .mainNav > ul > li a:active span.text {
            color: #fff; }
        .mainNav > ul > li.burgernav {
          overflow: hidden;
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          padding: 0; }
          .mainNav > ul > li.burgernav span.analog-icon {
            margin: 0;
            font-size: 3.75em; } }

@media all and (max-width: 374px) {
  .mainNav > ul > li a span.text {
    font-size: 10px; } }

.sliderNav {
  position: fixed;
  z-index: 10;
  width: 75%;
  right: 0;
  bottom: -100%;
  height: 4.16667vw;
  background: #eef0f2;
  text-align: center;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  /* position: fixed;
  z-index: 10;
  width: auto;
  left: calc(50% + 12.5vw);
  transform: translateX(-50%);
  bottom: -100%;
  height: $oneGrid;
  background: $grey-lighter;
  text-align: center;
  box-shadow: 0 1.6em 1.1em -0.5em rgba(32, 99, 123, 0.5);
  transition: $t-slow; */ }
  .sliderNav .currentProjects {
    position: absolute;
    left: 4.16667vw;
    top: 100%;
    margin-top: 1px;
    transform: translateY(0);
    opacity: 0;
    line-height: 1;
    font-weight: 700;
    font-size: calc(15px + 0.5vw);
    text-transform: uppercase;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    color: #2b90b4; }
  .sliderNav.show {
    bottom: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; }
    .sliderNav.show .currentProjects {
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition-delay: 1s;
      color: #2c424d; }
    .sliderNav.show .slide-burger {
      background: #fff;
      transition-delay: 1s; }
      .sliderNav.show .slide-burger span {
        background: #2c424d;
        transition-delay: 1s; }
      .sliderNav.show .slide-burger:hover span, .sliderNav.show .slide-burger:focus span, .sliderNav.show .slide-burger:active span {
        transition-delay: 0s; }
  .sliderNav .slide-left,
  .sliderNav .slide-right,
  .sliderNav .slide-burger {
    height: 4.16667vw;
    width: 4.16667vw;
    background: #eef0f2;
    display: inline-block;
    vertical-align: top;
    margin: 0 -2px;
    cursor: pointer; }
  .sliderNav .slide-burger {
    background: #2b90b4;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transition-delay: 0.25s; }
    .sliderNav .slide-burger span {
      background: #fff; }
  .sliderNav .slide-left {
    transform: rotate(-90deg); }
  .sliderNav .slide-right {
    transform: rotate(90deg); }
  .sliderNav .analog-icon.arrow_up {
    display: inline-block;
    font-size: 2em;
    color: #526773;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .sliderNav .burger {
    animation: none;
    opacity: 1;
    top: 50%;
    height: 14px; }

.slideMenu {
  z-index: 10;
  padding: 0;
  left: 50%;
  transform: translate3d(-50%, 100%, 0);
  bottom: 100%;
  position: absolute;
  max-width: 4.16667vw;
  min-width: 4.16667vw;
  max-height: 4.16667vw;
  transform-origin: center bottom;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  background: transparent;
  transition-delay: 0.3s;
  visibility: hidden; }
  .slideMenu:before {
    content: "";
    position: absolute;
    border-radius: 30px;
    display: block;
    z-index: -1;
    width: 90%;
    height: 90%;
    opacity: 0;
    left: 5%;
    bottom: 0;
    background: #216d88;
    box-shadow: 0 0 3em #216d88, 0 0 3em #216d88, 0 0 7em #216d88, 0 0 9em #216d88;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
  .slideMenu ul {
    margin: 0;
    padding: 0;
    position: relative;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .slideMenu ul li {
      float: left;
      vertical-align: top;
      padding: 0 2.08333vw;
      padding-right: 0;
      width: 33.33333%;
      margin-bottom: 2.08333vw; }
      .slideMenu ul li .inner {
        background: #fff;
        transform: scale(0);
        opacity: 0;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
        position: relative;
        text-align: center; }
        .slideMenu ul li .inner img {
          padding: 4.16667vw;
          transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }

.open .slideMenu {
  visibility: visible;
  max-width: 58.33334vw;
  min-width: 58.33334vw;
  max-height: 100vh;
  transform: translate3d(-50%, 0, 0);
  transition-delay: 0s; }
  .open .slideMenu:before {
    opacity: 0.15;
    transition-delay: 0s; }
  .open .slideMenu ul {
    opacity: 1;
    visibility: visible;
    padding: 2.08333vw 2.08333vw 0 0;
    background: #eef0f2; }
    .open .slideMenu ul li .inner {
      transform: scale(1);
      opacity: 1;
      transition-delay: 0.2s; }
    .open .slideMenu ul li:hover .inner, .open .slideMenu ul li:focus .inner, .open .slideMenu ul li:active .inner {
      transition-delay: 0s;
      transform: scale(1.05);
      box-shadow: 2px 3px 2em rgba(0, 0, 0, 0.1); }
      .open .slideMenu ul li:hover .inner img, .open .slideMenu ul li:focus .inner img, .open .slideMenu ul li:active .inner img {
        transform: scale(1.25); }

@media screen and (max-width: 1023px) {
  .sliderNav {
    position: fixed;
    width: calc(100% - 75px);
    height: 8.33333vw; }
    .sliderNav .slide-left,
    .sliderNav .slide-right,
    .sliderNav .slide-burger {
      height: 8.33333vw;
      width: 8.33333vw; } }

@media screen and (max-width: 499px) {
  .sliderNav {
    z-index: 10;
    width: 100%;
    height: 55px; }
    .sliderNav.scrollUp, .sliderNav.show {
      transform: translateY(-55px) !important; }
    .sliderNav .slide-left,
    .sliderNav .slide-right,
    .sliderNav .slide-burger {
      height: 55px;
      width: 55px; } }

@media screen and (max-width: 480px) {
  .slideMenu {
    max-width: 100%;
    min-width: 100%;
    left: 0;
    transform: translate3d(0, 100%, 0); }
  .open .slideMenu {
    max-width: 100%;
    min-width: 100%;
    transform: translate3d(0, 0, 0); }
  .sliderNav .currentProjects {
    display: none; } }

@media screen and (min-width: calc(500px)) and (max-width: 1023px) {
  .mainBar {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .mainBar .mainNav {
      min-width: 160px;
      opacity: 0;
      visibility: hidden;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      transition-delay: 0s;
      margin-left: 12px;
      transform: translateX(-25px);
      left: 0; }
    .mainBar .logo {
      width: 43px;
      padding: 0;
      left: auto;
      transform: none; }
    .mainBar.open {
      width: 25.0vw; }
      .mainBar.open .mainNav {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
        transition-delay: 0.25s; }
    .mainBar .social {
      position: absolute;
      width: 100%;
      left: 0;
      border: none;
      bottom: 22px; }
      .mainBar .social a {
        display: block; }
  .tabletNav-open .gr-main {
    transform: translateX(calc(25vw - 75px));
    opacity: 0.15;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; } }

@media screen and (min-width: calc(500px)) and (max-width: 768px) {
  .mainBar.open {
    width: 50vw; } }

.mainNav ul li .mobileNav {
  display: none;
  overflow: hidden; }

.burgernav {
  display: none; }

@media screen and (max-width: 499px) {
  .mainBar .burgernav {
    cursor: pointer;
    display: flex; }
    .mainBar .burgernav .burger {
      display: block;
      transform: none;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      margin: 0;
      color: #5df5d2;
      width: 24px; }
  .mobileNav-open .burgernav .burger {
    transform: rotate(-135deg); }
  .mainNav ul li .mobileNav {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 100%;
    display: block;
    box-sizing: content-box;
    padding: 20px 0 0 20px;
    visibility: hidden; }
    .mainNav ul li .mobileNav ul {
      box-shadow: 0 0 19px rgba(34, 117, 147, 0.55);
      transform: translateY(120%);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
      background: #3bacbc;
      background: -moz-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
      background: -webkit-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
      background: linear-gradient(135deg, #3bacbc 35%, #50d7c8 130%);
      background-size: cover; }
      .mainNav ul li .mobileNav ul li a:not(.analog-icon) {
        padding: 15px;
        color: #fff;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s !important;
        transition-delay: 0s;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25); }
        .mainNav ul li .mobileNav ul li a:not(.analog-icon):hover, .mainNav ul li .mobileNav ul li a:not(.analog-icon):focus, .mainNav ul li .mobileNav ul li a:not(.analog-icon):active {
          background: rgba(93, 245, 210, 0.25); }
      .mainNav ul li .mobileNav ul li:last-child a {
        border: none; }
      .mainNav ul li .mobileNav ul li.socials {
        display: flex;
        justify-content: center; }
        .mainNav ul li .mobileNav ul li.socials a {
          font-size: 2.5em;
          padding: 0.5em 0.75em;
          flex: 0; }
    .mobileNav-open .mainNav ul li .mobileNav {
      visibility: visible; }
      .mobileNav-open .mainNav ul li .mobileNav ul {
        transform: translateY(0); } }

@media screen and (max-width: 550px) {
  .mainNav ul li .mobileNav {
    width: 100%; } }

section {
  padding: 4.16667vw calc(8.33333vw - 20px) 4.16667vw 4.16667vw;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  will-change: transform, filter;
  overflow: hidden; }
  section section {
    padding-left: 0 !important;
    padding-right: 0 !important; }

@media all and (max-width: 1024px) {
  section {
    padding: 4.16667vw; } }

@media (max-width: 800px) {
  section {
    padding: 8.33333vw;
    padding-top: 2.77778vw; } }

@media (max-width: 800px) {
  section {
    padding: 8.33333vw; } }

@media (max-width: 499px) {
  section {
    padding-bottom: 130px; } }

section.welcome {
  position: relative;
  display: none;
  overflow: hidden;
  max-width: 100vw;
  padding: 0; }
  section.welcome .content {
    position: relative;
    display: block;
    width: 100%; }
  section.welcome .headline {
    color: #4ed6c8;
    text-align: center;
    font-size: calc(10px + 2.75vw);
    width: 100%;
    padding: 0 4.16667vw;
    margin: 0;
    font-weight: 200;
    letter-spacing: 0;
    position: absolute;
    bottom: 100px; }
  @media all and (max-width: 1023px) {
    section.welcome {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100vh;
      background: url("../../../Images/Scss-Assets/patternBG_01.png") #2c424d; }
      section.welcome .logo {
        transition: none;
        display: block !important;
        transform: none;
        left: auto;
        top: auto;
        margin: 0 auto 80px;
        padding: 0; }
        section.welcome .logo .big {
          display: block; }
      section.welcome .typo3-member {
        display: block;
        width: 50%;
        max-width: 80px;
        margin: 0.5rem; }
      section.welcome + section {
        padding-top: 16.66667vw; } }

div.leistungen .leistungen_icons .item {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  background: rgba(33, 109, 136, 0);
  position: relative; }
  div.leistungen .leistungen_icons .item img {
    transform: scale(0.8);
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  div.leistungen .leistungen_icons .item .label {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25em;
    line-height: 1.25;
    height: 25%; }
    div.leistungen .leistungen_icons .item .label span {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  div.leistungen .leistungen_icons .item:hover, div.leistungen .leistungen_icons .item:focus, div.leistungen .leistungen_icons .item:active {
    background: rgba(33, 109, 136, 0.5);
    border-color: #fff; }
    div.leistungen .leistungen_icons .item:hover img, div.leistungen .leistungen_icons .item:focus img, div.leistungen .leistungen_icons .item:active img {
      transform: scale(1); }

.portfolioView:after {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s; }

.portfolioView .typo3-member {
  opacity: 0; }

.portfolioView .closeView {
  opacity: 1;
  transition-delay: 0.5s; }

.portfolioView #movingLogos {
  opacity: 0; }

@media screen and (max-width: 1399px) {
  .portfolioView .gr-menu {
    transform: translateX(-101%);
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; }
  .portfolioView .gr-main {
    width: 100%;
    margin: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; }
  .portfolioView .mainbar {
    background: #fff; }
  .portfolioView .sliderNav {
    width: 100%;
    transform: translateY(0) !important; } }

section.contact {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh; }
  section.contact p a:hover {
    color: #5df5d2; }
  section.contact .t3-member {
    margin: 60px auto;
    max-width: 120px; }
  section.contact p.mail a {
    text-decoration: none; }
  section.contact .social {
    display: block !important; }
    section.contact .social a {
      color: #fff;
      font-size: 3em;
      margin: 0 0.5em 0 0; }
      section.contact .social a:hover, section.contact .social a:focus {
        color: #5df5d2; }
  section.contact .social,
  section.contact .impressum {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    width: auto; }
  section.contact .social {
    left: 0;
    text-align: left; }
  section.contact .impressum {
    right: 0;
    cursor: pointer;
    font-size: 1.5em;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    section.contact .impressum:hover {
      color: #5df5d2; }

#gMap .analog-mapoverlay {
  color: #216d88; }

#gMap a.analog-route {
  color: #216d88; }

.contactFooter {
  position: relative;
  padding: 4.16667vw 0;
  margin: 4.16667vw 0 4.16667vw 8.33333vw;
  border: 1px solid #fff;
  border-left: none;
  border-right: none; }

.button.showMap {
  margin-left: 8.33333vw; }

ul#movingLogos {
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  transition-delay: 0.5s; }

.popup.imprint {
  position: fixed;
  max-width: 100%;
  width: 75vw;
  height: 100vh;
  overflow-x: hidden !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 2vw;
  z-index: 30;
  top: 0;
  right: 0;
  background: #fff;
  color: #2c424d;
  transform: translateX(100%);
  visibility: visible;
  will-change: transform, scroll;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .popup.imprint h2 {
    transform: translateX(0);
    opacity: 1; }
    .popup.imprint h2 span,
    .popup.imprint h2 div {
      color: #216d88 !important; }
  .popup.imprint a {
    color: #2c424d; }
    .popup.imprint a:hover {
      color: #216d88; }
  .popup.imprint p {
    transform: none;
    opacity: 1; }

body.imprintOpen .popup.imprint {
  transform: translateX(0); }

body.imprintOpen section.contact {
  max-height: 100%;
  overflow: hidden; }

.mapBG {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute !important;
  opacity: 0;
  visibility: hidden;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  z-index: 2; }
  .mapBG .gr-main {
    background: none;
    z-index: 30; }
  .mapBG section.contact {
    opacity: 0;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    transition-delay: 2s;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto; }

#particles-js {
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

#gMap {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  position: fixed !important;
  opacity: 0.175;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s; }
  #gMap.zoom {
    transform: scale(1.5); }
  #gMap .gr-main {
    background: none !important;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s; }

.mapIsOpen .typo3-member {
  opacity: 0; }

.mapIsOpen .open .burger:hover span {
  background: #216d88; }

.mapIsOpen .burger span {
  background: #fff; }

.mapIsOpen .mainNav ul li a {
  color: #fff;
  border-color: #fff; }
  .mapIsOpen .mainNav ul li a:hover span {
    color: #216d88; }

.mapIsOpen .sideBar .social a {
  color: #fff; }

.mapIsOpen .content {
  transform: translateX(-100%);
  max-height: 100vh; }

.mapIsOpen .gr-main {
  overflow: hidden; }

.mapIsOpen .mapBG {
  opacity: 1;
  visibility: visible;
  overflow: hidden; }
  .mapIsOpen .mapBG .gr-main {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    transition-delay: 0s !important; }
  .mapIsOpen .mapBG .content {
    transform: translateX(0); }
  .mapIsOpen .mapBG section.contact {
    opacity: 1;
    transition-delay: 0s;
    padding-bottom: 0; }

.mapIsOpen .mapBG,
.mapIsOpen .mainBar {
  transition-delay: 0.25s; }

.mapIsOpen .sliderNav {
  display: none; }

.mapIsOpen ul#movingLogos {
  opacity: 0;
  transition-delay: 0.25s; }

.mapIsOpen .sliderNav {
  transform: translateX(100%) !important;
  transition-delay: 0.2s; }

.mapIsOpen .mainBar {
  transition-delay: 0s; }

.mapIsOpen .sideBar {
  text-align: center;
  background: white;
  background: rgba(255, 255, 255, 0.3);
  transition-delay: 0s; }

.mapIsOpen.mapOnly #gMap.zoom {
  transition-delay: 0.75s; }

.mapIsOpen.mapOnly .mapBG {
  opacity: 0.8; }

.closeView {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 1em;
  right: 1em;
  width: 3em;
  height: 3em;
  z-index: 100000;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
  cursor: pointer;
  transform: rotate(0deg);
  transform-origin: center center; }
  .closeView:before, .closeView:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    top: 50%;
    transform-origin: center center;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .closeView:before {
    transform: translateY(-50%); }
  .closeView:after {
    transform: translateY(-50%) rotate(90deg); }
  .closeView:hover, .closeView:focus {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    opacity: 0.75; }
    .closeView:hover:before, .closeView:hover:after, .closeView:focus:before, .closeView:focus:after {
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      background: #fff; }
  .mapOnly .closeView,
  .portfolioView .closeView,
  .imprint .closeView {
    opacity: 1;
    visibility: visible;
    transform: rotate(-45deg);
    transition-delay: 0s;
    background: #fff;
    border-radius: 50%;
    padding: 0;
    border: 9px solid #fff; }
    .imprint .mapOnly .closeView, .imprint
    .portfolioView .closeView, .imprint
    .imprint .closeView {
      background: #4ed6c8; }
    .mapOnly .closeView:before, .mapOnly .closeView:after,
    .portfolioView .closeView:before,
    .portfolioView .closeView:after,
    .imprint .closeView:before,
    .imprint .closeView:after {
      background: #323538; }
    .mapOnly .closeView:before,
    .portfolioView .closeView:before,
    .imprint .closeView:before {
      transform: translateY(-50%); }
    .mapOnly .closeView:after,
    .portfolioView .closeView:after,
    .imprint .closeView:after {
      transform: translateY(-50%) rotate(90deg); }
    .mapOnly .closeView:hover, .mapOnly .closeView:focus,
    .portfolioView .closeView:hover,
    .portfolioView .closeView:focus,
    .imprint .closeView:hover,
    .imprint .closeView:focus {
      background: #5df5d2;
      border-color: #5df5d2;
      transition-delay: 0s; }
      .mapOnly .closeView:hover:before, .mapOnly .closeView:hover:after, .mapOnly .closeView:focus:before, .mapOnly .closeView:focus:after,
      .portfolioView .closeView:hover:before,
      .portfolioView .closeView:hover:after,
      .portfolioView .closeView:focus:before,
      .portfolioView .closeView:focus:after,
      .imprint .closeView:hover:before,
      .imprint .closeView:hover:after,
      .imprint .closeView:focus:before,
      .imprint .closeView:focus:after {
        background: #fff; }

.mapOnly #gMap {
  opacity: 0.7; }

.mapOnly .gr-menu {
  transform: translateX(-100%); }

.mapOnly .gr-main {
  transform: translateX(100%);
  transition-delay: 0s; }

@media screen and (min-width: calc(500px)) {
  .mapIsOpen .mainBar {
    transition-delay: 0s;
    background-color: rgba(50, 53, 56, 0.4); } }

@media screen and (min-width: 1024px) {
  .mapIsOpen .gr-main {
    transition-delay: 0s; } }

@media screen and (max-width: 1023px) {
  .contactFooter {
    margin-left: 0; }
  .popup.imprint {
    width: calc(100vw - 75px); } }

@media screen and (max-width: 499px) {
  .popup.imprint {
    width: 100%; }
  .contactFooter {
    padding: 50px 0;
    margin: 30px 0; }
    .contactFooter .impressum {
      font-size: 1.5em; } }

@media screen and (max-width: 499px) {
  .mapOnly .gr-menu {
    transform: translate(0, 100%); }
  section.contact h2,
  section.contact .h2 {
    margin-top: 30px; }
  section.contact .contactFooter {
    text-align: center;
    padding: 30px 0; }
    section.contact .contactFooter .impressum,
    section.contact .contactFooter .social {
      text-align: center;
      position: relative;
      display: block;
      width: 100%;
      transform: none; }
    section.contact .contactFooter .impressum {
      font-size: 1.1em;
      margin-top: 13px; } }

@media screen and (max-width: 479px) {
  #particles-js {
    display: none !important; } }

.mobileLogo {
  display: none; }

.home h1 span {
  margin-bottom: -0.25em; }

@media all and (max-width: 499px) {
  .home h1 span {
    display: none; }
  .home h1 .type {
    visibility: hidden; }
  .mobileLogo {
    display: block;
    position: absolute;
    left: 0;
    top: 6px;
    height: 100%;
    width: auto;
    transform: translateX(-7%) scale(1);
    transform-origin: left center; }
    .mobileLogo img {
      height: 100%;
      width: auto; } }

.analog-icon.arrow_right {
  position: relative;
  top: 50%;
  text-align: center; }
  .analog-icon.arrow_right:before {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }

.logoBadges .items {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100% !important;
  margin: 0 -2.08333vw;
  padding: 0; }
  .logoBadges .items > * {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 0 2.08333vw !important;
    margin-bottom: 4.16667vw; }
    .logoBadges .items > * > * {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-width: 100%;
      padding: 0;
      margin: 0; }
      .logoBadges .items > * > * > * {
        flex: 1;
        max-width: 100%; }
    @media all and (min-width: 768px) {
      .logoBadges .items > * {
        flex: 1 0 30%;
        max-width: 33.33334%; } }
    @media all and (max-width: 767px) {
      .logoBadges .items > * {
        flex: 1 0 50%;
        max-width: 50%; } }
  @media all and (max-width: 550px) {
    .logoBadges .items {
      margin: 0; }
      .logoBadges .items > * {
        flex: 1 0 100%;
        max-width: 100%;
        margin: 0 0 8.33333vw;
        padding: 0 !important; } }

.logoBadges .item.pop {
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .logoBadges .item.pop img,
  .logoBadges .item.pop .svg {
    border: none;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .logoBadges .item.pop .inner {
    text-align: center;
    display: flex;
    flex: 1;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    max-width: none; }
    .logoBadges .item.pop .inner > a {
      flex: 1; }
  .logoBadges .item.pop .popup {
    max-width: none; }
    .logoBadges .item.pop .popup h3,
    .logoBadges .item.pop .popup .h3 {
      margin: auto;
      text-align: center; }
    .logoBadges .item.pop .popup.popupOpen h3,
    .logoBadges .item.pop .popup.popupOpen .h3 {
      transition-delay: 0.35s !important; }
  .logoBadges .item.pop:hover .inner img {
    transform: scale(1.15); }

.referenzen ul {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100% !important;
  margin: 0 -2.08333vw;
  padding: 0; }
  .referenzen ul > * {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 0 2.08333vw !important;
    margin-bottom: 4.16667vw; }
    .referenzen ul > * > * {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-width: 100%;
      padding: 0;
      margin: 0; }
      .referenzen ul > * > * > * {
        flex: 1;
        max-width: 100%; }
    @media all and (min-width: 768px) {
      .referenzen ul > * {
        flex: 1 0 30%;
        max-width: 33.33334%; } }
    @media all and (max-width: 767px) {
      .referenzen ul > * {
        flex: 1 0 50%;
        max-width: 50%; } }
  @media all and (max-width: 550px) {
    .referenzen ul {
      margin: 0; }
      .referenzen ul > * {
        flex: 1 0 100%;
        max-width: 100%;
        margin: 0 0 8.33333vw;
        padding: 0 !important; } }
  .referenzen ul li {
    cursor: pointer;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    padding: 0;
    list-style: none;
    position: relative;
    z-index: 1; }
    .referenzen ul li .inner {
      background: #fff;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
      position: relative;
      text-align: center; }
      .referenzen ul li .inner > .h3 {
        color: #72828e;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 1.75em;
        padding: 0.5em;
        background: #edf1f4;
        background: #eef0f2;
        margin: 0;
        height: 2.6em; }
        @media all and (min-width: 1281px) {
          .referenzen ul li .inner > .h3 {
            font-size: 1.25em; } }
      .referenzen ul li .inner img {
        padding: 2.08333vw 4.16667vw 6.25vw;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
        @media all and (min-width: 1281px) {
          .referenzen ul li .inner img {
            padding: 4.16667vw;
            transform: translateY(-6%); } }
        @media screen and (max-width: 1023px) {
          .referenzen ul li .inner img {
            transform: translateY(0) scale(0.7); } }
      .referenzen ul li .inner h4 {
        color: #526773;
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: 0;
        font-weight: 600;
        margin: 0;
        padding: 1em;
        background: rgba(82, 103, 115, 0.15); }
    .referenzen ul li:hover {
      transform: scale(1.07) !important;
      z-index: 2; }
      .referenzen ul li:hover .inner {
        transform: scale(1);
        box-shadow: 0 0.5em 3em 0.4em rgba(33, 109, 136, 0.55); }
        .referenzen ul li:hover .inner img {
          transform: translateY(-6%) scale(1.1); }
          @media screen and (max-width: 1280px) {
            .referenzen ul li:hover .inner img {
              transform: translateY(3.5%) scale(1.1); } }
          @media screen and (max-width: 1023px) {
            .referenzen ul li:hover .inner img {
              transform: translateY(0) scale(1); } }

@media all and (max-width: 499px) {
  .logoBadges .item .info .inner h3,
  .logoBadges .item .info .h3 {
    font-size: 1.25em; } }

section.entry {
  min-height: 100vh; }
  section.entry .deployment {
    margin-top: 8.33333vw;
    margin-bottom: 8.33333vw;
    margin-left: 0; }
    section.entry .deployment .landscape,
    section.entry .deployment .portrait {
      display: none; }
    @media all and (min-width: 1024px) {
      section.entry .deployment .landscape {
        display: block; } }
    @media all and (max-width: 1023px) {
      section.entry .deployment .portrait {
        display: block;
        max-height: 90vh; } }

body.company section.entry#company {
  order: -2; }

body.company section.entry#agency {
  order: -1; }

body.agency section.entry#agency {
  order: -2; }

body.agency section.entry#company {
  order: -1; }

.project_mobilePreview {
  position: relative;
  margin: 8.33333vw 0;
  margin-left: 16.66667vw;
  color: #fff; }
  .project_mobilePreview .mp {
    position: relative;
    cursor: pointer !important;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
    color: #fff; }
    .project_mobilePreview .mp .bg {
      fill: rgba(255, 255, 255, 0.5);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
    .project_mobilePreview .mp .obj {
      fill: currentColor; }
    .project_mobilePreview .mp.zoom {
      z-index: 30;
      transform: scale(1.05); }
      .project_mobilePreview .mp.zoom .bg {
        fill: rgba(255, 255, 255, 0.7); }
    @media all and (min-width: calc(500px)) {
      .project_mobilePreview .mp.iphone.zoom {
        z-index: 301;
        transform: translate(25%, -34%) scale(1.5) !important;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; } }
    .project_mobilePreview .mp figure {
      overflow: hidden;
      position: absolute;
      z-index: 11;
      border-radius: 3px;
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
      opacity: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: currentColor; }
      .project_mobilePreview .mp figure:before {
        position: absolute;
        z-index: 24;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #216d88; }
      .project_mobilePreview .mp figure:after {
        content: "Klick to scroll";
        position: absolute;
        left: 50%;
        top: 7rem;
        transform: translate(-50%, 0);
        z-index: 30;
        background: #4ed6c8;
        font-size: 1.8rem;
        white-space: nowrap;
        font-weight: 700;
        padding: 1rem;
        line-height: 1;
        box-shadow: 0 0.6em 1em -0.2em rgba(0, 0, 0, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s; }
      .project_mobilePreview .mp figure:hover:before, .project_mobilePreview .mp figure:hover:after,
      .touch .project_mobilePreview .mp figure:before,
      .touch .project_mobilePreview .mp figure:after {
        opacity: 1; }
      .project_mobilePreview .mp figure:hover img,
      .touch .project_mobilePreview .mp figure img {
        animation: scrollPreview normal forwards ease-in-out 0.3s 0s; }
      .project_mobilePreview .mp figure.scrolling {
        opacity: 1; }
        .project_mobilePreview .mp figure.scrolling:before, .project_mobilePreview .mp figure.scrolling:after {
          opacity: 0 !important; }
        .project_mobilePreview .mp figure.scrolling img {
          animation: 0; }
    .project_mobilePreview .mp.ipad {
      transform-origin: center bottom; }
      .project_mobilePreview .mp.ipad figure {
        width: 87.85%;
        height: 92.3%; }
    .project_mobilePreview .mp.iphone {
      position: absolute;
      width: 25%;
      bottom: -15%;
      left: -15%;
      z-index: 15; }
      @media all and (min-width: 1500px) {
        .project_mobilePreview .mp.iphone {
          left: -26.5%;
          width: 30%; }
          .project_mobilePreview .mp.iphone.zoom {
            transform: translate(16.8%, -21.9%) scale(1.31); } }
      @media all and (min-width: 1800px) {
        .project_mobilePreview .mp.iphone.zoom {
          transform: none; } }
      .project_mobilePreview .mp.iphone figure {
        width: 93.75%;
        height: 78.6%; }
      .project_mobilePreview .mp.iphone img.shadow {
        transform: scaleX(1.25) scaleY(1.35) translate(1%, 3%);
        transform-origin: 0 center; }
      .project_mobilePreview .mp.iphone.appeared {
        transform: none;
        opacity: 1; }
  .project_mobilePreview .device {
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s; }
  .project_mobilePreview img.shadow {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: scale(1.11) translate(3%, -5%); }

section p + .project_mobilePreview {
  margin-top: 4.16667vw; }

@keyframes scrollPreview {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0); } }

@keyframes scrollFull {
  0% {
    background-position: 0 top; }
  50% {
    background-position: 0 bottom; }
  100% {
    background-position: 0 top; } }

@media screen and (max-width: 1499px) {
  .project_mobilePreview {
    margin-left: 8.33333vw; } }

@media screen and (max-width: 1024px) {
  .project_mobilePreview {
    margin-left: 2.77778vw;
    margin-bottom: 12.5vw; } }

@media screen and (max-width: 1023px) {
  .project_mobilePreview {
    margin-left: 0; }
    .project_mobilePreview img.shadow {
      transform: scale(1.11) translate(0%, -6%);
      opacity: 0.75; }
    .project_mobilePreview .mp.iphone {
      display: none; } }

@media screen and (max-width: 499px) {
  .project_mobilePreview .mp.ipad {
    display: none; }
  .project_mobilePreview .mp.iphone {
    display: block;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 425px;
    margin: 4em auto 0;
    transform: none !important; } }

.testimonial {
  position: relative;
  padding: 8.33333vw 0;
  display: flex;
  margin-left: 8.33333vw; }
  .testimonial .icon-quote {
    display: flex;
    justify-content: center;
    margin: 0 2.08333vw 4.16667vw 0;
    width: 6.25vw;
    height: 6.25vw;
    flex: 0 0 6.25vw;
    background: rgba(93, 245, 210, 0.36); }
    .testimonial .icon-quote img {
      width: 55%; }
  .testimonial p.subline {
    font-weight: 400;
    text-align: left;
    font-size: 1.6em;
    line-height: 1;
    font-style: normal;
    opacity: 1; }
  @media all and (max-width: 1280px) {
    .testimonial {
      margin-left: 0; } }
  @media all and (max-width: 499px) {
    .testimonial {
      font-size: 0.75em; }
      .testimonial .icon-quote {
        margin-right: 4.16667vw; } }
  @media all and (max-width: 600px) {
    .testimonial {
      flex-direction: column; }
      .testimonial .icon-quote {
        margin-right: 0;
        flex: 0 0 12.5vw;
        width: 12.5vw;
        height: 12.5vw; }
      .testimonial blockquote {
        flex: 1 0 100%; } }
