section.entry {
    min-height: 100vh;

    .deployment {
        margin-top: $twoGrid;
        margin-bottom: $twoGrid;
        margin-left: 0;

        .landscape,
        .portrait {
            display: none;
        }

        .landscape {
            @media all and (min-width: 1024px) {
                display: block;
            }
        }

        .portrait {
            @media all and (max-width: 1023px) {
                display: block;
                max-height: 90vh;
            }
        }
    }
}

body.company {
    section.entry#company {
        order: -2;
    }

    section.entry#agency {
        order: -1;
    }
}

body.agency {
    section.entry#agency {
        order: -2;
    }

    section.entry#company {
        order: -1;
    }
}
