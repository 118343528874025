.mainNav {
    margin: $oneGrid 0;
    transform: translate3d(0, 0, 0);

    @include centerVert;

    will-change: opacity, transform;

    li {
        a {
            cursor: pointer;
            display: block;
            color: $grey-lighter;
            font-weight: 400;
            height: 3.25em;
            transition: $t-slow;
            font-size: 1.5em;
            letter-spacing: 0.075em;

            span {
                vertical-align: middle;
                display: inline-block;
                transition: $t-medium;
            }

            span.analog-icon {
                font-size: 1.5em;
                width: auto;
                margin-right: 0.5em;
                color: $ci-green;
            }

            &:hover,
            &:focus {
                span.text {
                    //color: $ci-green;
                }

                span.analog-icon {
                    color: #fff;
                }
            }
        }

        &:last-child {
            a {
                height: auto;
            }
        }
    }
}

.social {
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
    bottom: 22px;
    text-align: center;
    transition: $t-mediumslow;

    a {
        text-decoration: none !important;
        font-size: 2em;
        margin: 0.75em 0.3em 0;
        display: inline-block;
        color: $grey-light;
        transition: $t-medium;

        &:hover,
        &:focus {
            color: $ci-blue;

            @media screen and (max-width: 1023px) {
                color: #fff;
            }
        }
    }
}

.desktopNav-open {
    .sideBar .social {
        opacity: 0 !important;
        visibility: hidden;
    }
}

@media screen and (min-width: 1024px) {
    .scrollTop {
        display: none;
    }

    .mainNav {
        margin: 5vw 0 0;
        opacity: 0;
        transition: $t-fast;
        transition-delay: 0s;
        transform: translate(-50%, 5vh);

        li {
            a {
                height: auto;
                color: $grey-dark;
                font-size: 1.5em;
                padding: 0.85em 0;
                margin: 0 $oneGrid;
                border-bottom: 1px solid transparentize($grey-light, 0.65);
                transition: $t-medium;

                span {
                    transition: none;
                    font-weight: 200;
                    text-transform: none;
                }

                span.analog-icon {
                    display: none;
                }

                &:hover,
                &:active {
                    letter-spacing: 0.35em;
                    color: $ci-green;
                }
            }

            &.active {
                a {
                    color: $ci-blue;

                    span {
                        font-weight: 400;
                    }
                }
            }

            &:first-child a {
                border-top: 1px solid transparentize($grey-light, 0.65);
            }

            &:last-child a {
                border: none;
            }
        }

        .desktopNav-open & {
            opacity: 1;
            transform: translate(-50%, 0);
            transition-delay: 0.25s;
        }
    }
}

@media screen and (max-width: $bp-handheld-max) {
    $navHeight: 65px;
    $mobileNavHeight: 65px;

    .gr-menu {
        height: $mobileNavHeight;
    }

    .mainNav {
        transition: $t-medium;
        transition-delay: 0.75s;
        margin: 0;

        > ul {
            display: flex;

            > li {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(4) {
                    border: none;
                }

                &:nth-child(n + 5) {
                    display: none;
                }

                a {
                    height: $mobileNavHeight;
                    padding: 0;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span {
                        &.analog-icon,
                        &.text {
                            display: block;
                            text-align: center;
                            width: 100%;
                            margin-right: 0;
                        }

                        &.text {
                            font-size: 12px;
                            color: $grey-light;
                            font-weight: 600;
                            margin-top: 4px;
                            letter-spacing: 0.025em;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        span {
                            &.text {
                                color: #fff;
                            }
                        }
                    }
                }

                &.burgernav {
                    overflow: hidden;
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    span.analog-icon {
                        margin: 0;
                        font-size: 3.75em;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 374px) {
    .mainNav {
        > ul {
            > li {
                &:first-child {
                }

                a {
                    span.text {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
