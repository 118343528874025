$grey-darkest: rgb(50, 53, 56);
$grey-dark: rgb(82, 103, 115);
$grey-mid: rgb(114, 130, 142);
$grey-light: rgb(185, 193, 199);
$grey-lighter: rgb(238, 240, 242);

$ci-blue: rgb(33, 109, 136);
$ci-blue-bright: rgb(43, 144, 180);
$ci-blue-grey: #2c424d;

$ci-green: rgb(78, 214, 200);
$ci-green-bright: rgb(93, 245, 210);

$bp-handheld-max: 499px;
$bp-handheld-min: calc(#{$bp-handheld-max + 1px});

$t-fast: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.25s;
$t-medium: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
$t-slow: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s;

$t-fast: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
$t-medium: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
$t-mediumslow: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
$t-slow: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
$t-superslow: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
