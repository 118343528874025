$icomoon-font-path: "../../../Fonts/iconfont" !default;

$analog-icon-analogo_font: "\e900";
$analog-icon-analogo_sign: "\e901";
$analog-icon-arrow_right: "\e902";
$analog-icon-arrow_up: "\e903";
$analog-icon-menu_contact: "\e904";
$analog-icon-menu_home: "\e905";
$analog-icon-menu_projects: "\e906";
$analog-icon-menu_services: "\e907";
$analog-icon-social_facebook: "\e908";
$analog-icon-social_twitter: "\e909";
$analog-icon-social_xing: "\e90a";
$analog-icon-t3_member: "\e90b";
$analog-icon-speechBubble: "\e90c";
$analog-icon-menu_burger: "\e90d";
$analog-icon-menu_news: "\e90e";

@font-face {
    font-family: "analog-icons";
    src:
        url("#{$icomoon-font-path}/analog-icons.woff2?qz1e5s") format("woff2"),
        url("#{$icomoon-font-path}/analog-icons.ttf?qz1e5s") format("truetype"),
        url("#{$icomoon-font-path}/analog-icons.woff?qz1e5s") format("woff"),
        url("#{$icomoon-font-path}/analog-icons.svg?qz1e5s#analog-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin customIcon {
    font-family: "analog-icons" !important;
    speak: none;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="analog-icon."],
[class*=" analog-icon."],
[class*="analog-icon "] {
    @include customIcon;
}

.analog-icon.analogo_font {
    &:before {
        content: $analog-icon-analogo_font;
    }
}

.analog-icon.analogo_sign {
    &:before {
        content: $analog-icon-analogo_sign;
    }
}

.analog-icon.arrow_right {
    &:before {
        content: $analog-icon-arrow_right;
    }
}

.analog-icon.arrow_up {
    &:before {
        content: $analog-icon-arrow_up;
    }
}

.analog-icon.menu_contact {
    &:before {
        content: $analog-icon-menu_contact;
    }
}

.analog-icon.menu_home {
    &:before {
        content: $analog-icon-menu_home;
    }
}

.analog-icon.menu_projects {
    &:before {
        content: $analog-icon-menu_projects;
    }
}

.analog-icon.menu_services {
    &:before {
        content: $analog-icon-menu_services;
    }
}

.analog-icon.social_facebook {
    &:before {
        content: $analog-icon-social_facebook;
    }
}

.analog-icon.social_twitter {
    &:before {
        content: $analog-icon-social_twitter;
    }
}

.analog-icon.social_xing {
    &:before {
        content: $analog-icon-social_xing;
    }
}

.analog-icon.t3_member {
    &:before {
        content: $analog-icon-t3_member;
    }
}

.analog-icon.speechBubble {
    &:before {
        content: $analog-icon-speechBubble;
    }
}

.analog-icon.menu_burger {
    &:before {
        content: $analog-icon-menu_burger;
    }
}

.analog-icon.menu_news {
    &:before {
        content: $analog-icon-menu_news;
    }
}
