$p_delay: 0.5s;

body{

}

.portfolioView {
    &:after {
        opacity: 1;
        visibility: visible;
        transition-delay: $p_delay;
    }

    .typo3-member {
        opacity: 0;
    }

    .closeView {
        opacity: 1;
        transition-delay: $p_delay;
    }

    #movingLogos {
        opacity: 0;
    }
}

@media screen and (max-width: 1399px) {
    .portfolioView {
        .gr-menu {
            transform: translateX(-101%);
            transition: $t-slow;
        }

        .gr-main {
            width: 100%;
            margin: 0;
            transition: $t-slow;
        }

        .mainbar {
            background: #fff;
        }

        .sliderNav {
            width: 100%;
            transform: translateY(0) !important;
        }
    }
}
