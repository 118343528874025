div.leistungen {
    .leistungen_icons {
        .item {
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, 0.18);
            transition: $t-medium;
            background: transparentize($ci-blue, 1);
            position: relative;

            img {
                transform: scale(0.8);
                transition: $t-medium;
            }

            .label {
                position: absolute;
                bottom: 10%;
                left: 0;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.25em;
                line-height: 1.25;
                height: 25%;

                span {
                    display: block;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &:hover,
            &:focus,
            &:active {
                background: transparentize($ci-blue, 0.5);
                border-color: #fff;

                img {
                    transform: scale(1);
                }
            }
        }
    }
}
