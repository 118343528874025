section {
    .tablewrap {
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    table {
        min-width: 1000px;

        th,
        td {
            border-bottom: 1px solid #fff;
        }

        th,
        td {
            border-right: 1px solid #fff;
            padding: 15px;

            &:last-child {
                border-right: none;
            }
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }

        h2,
        h3,
        h4,
        h5 {
            margin: 0.5em 0;
        }

        ul,
        ol {
            margin: 0 0 30px;
            padding-left: 18px;
            font-size: 16px;
        }

        @media screen and (max-width: 1023px) {
            display: block;
        }
    }
}
