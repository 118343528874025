@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}:
                calc(
                    #{$min-value} +
                    #{strip-unit($max-value - $min-value)} *
                    (100vw - #{$min-vw}) /
                    #{strip-unit($max-vw - $min-vw)}
                );
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/* Single property */
html {
    @include fluid-type(font-size, 320px, 1366px, 14px, 18px);
}

@media screen and (min-width: 1281px) {
    html {
        font-size: 0.78125vw;
    }
}

@media screen and (max-width: 1280px) {
    html {
        font-size: 10px;
    }
}

/* Multiple properties with same values */
h1 {
    @include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
}

@mixin section-ElementMargins {
    @media all and (min-width: 1281px) {
        margin-left: $twoGrid;
    }

    @media all and (max-width: 1280px) {
        margin-left: $twoGrid;
    }

    @media all and (max-width: 1023px) {
        margin-left: 0;
    }
}

@mixin gradient_ci {
    background: #2b8fb3;
    background: -moz-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: -webkit-linear-gradient(-45deg, #2b8fb3 35%, #5df4d1 130%);
    background: linear-gradient(135deg, #2b8fb3 35%, #5df4d1 130%);
    background-attachment: fixed;
}

@mixin gradient_ci-Bright {
    background: #3bacbc;
    background: -moz-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
    background: -webkit-linear-gradient(-45deg, #3bacbc 35%, #50d7c8 130%);
    background: linear-gradient(135deg, #3bacbc 35%, #50d7c8 130%);
    background-size: cover;
}

@mixin gradient_grey-Dark {
    background: #2a2a2c;
    background: -moz-linear-gradient(-45deg, #2a2a2c 0%, #526773 85%);
    background: -webkit-linear-gradient(-45deg, #2a2a2c 0%, #526773 85%);
    background: linear-gradient(135deg, #2a2a2c 0%, #526773 100%);
}

@mixin gradient_blue-Dark {
    background: $ci-blue;
    background: -moz-linear-gradient(-45deg, $ci-blue 0%, $ci-blue-bright 85%);
    background: -webkit-linear-gradient(-45deg, $ci-blue 0%, $ci-blue-bright 85%);
    background: linear-gradient(135deg, $ci-blue 0%, $ci-blue-bright 100%);
}

@mixin gradient_white {
    background: transparent;
    background: linear-gradient(0deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
}

@mixin centerVert {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centerHorz {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin centerVertHorz {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@mixin link-arrow {
    position: relative;

    &:after {
        @include customIcon;

        transform: scale(0.5) translate(15px, 10%);
        transform-origin: center center;
        content: $analog-icon-arrow_right;
        transition: $t-fast;
        position: absolute;
        right: 0;
        top: 0.02em;
    }
}

@mixin orderedList {
    list-style: none;
    counter-reset: count;
    padding-left: 0;

    li {
        counter-increment: count;
        margin-bottom: 1.25em;
        position: relative;
        padding-left: 1.5em;

        &:before {
            position: absolute;
            left: 0;
            font-weight: 700;
            font-size: 0.8em;
            content: counter(count);
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            line-height: 1;
            background: $ci-green;
            margin-top: 0.25em;
            transform: translateX(-0.25em);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin col-3 {
    @media all and (min-width: 768px) {
        flex: 1 0 30%;
        max-width: calc(33% - #{$threeGrid});
        margin-bottom: $oneGrid;
    }

    @media all and (min-width: 1024px) {
        flex: 1 0 30%;
        max-width: calc(33% - #{$threeGrid2});
        margin-bottom: $halfGrid;
    }

    @media all and (max-width: $bp-handheld-max) {
        flex: 1 0 50%;
        max-width: calc(50% - #{$halfGrid});
        margin-bottom: $oneGrid;
    }

    @media all and (max-width: 412px) {
        flex: 1 0 100%;
        max-width: 100%;
        margin-bottom: $oneGrid;
    }
}

@mixin col3 {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100% !important;
    margin: 0 $halfGrid-neg;
    padding: 0;

    > * {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        max-width: 100%;
        padding: 0 $halfGrid !important;
        margin-bottom: $oneGrid;

        > * {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            max-width: 100%;
            padding: 0;
            margin: 0;

            > * {
                flex: 1;
                max-width: 100%;
            }
        }

        @media all and (min-width: 768px) {
            flex: 1 0 30%;
            max-width: 33.33334%;
        }

        @media all and (min-width: 1024px) {

        }

        @media all and (max-width: 767px) {
            flex: 1 0 50%;
            max-width: 50%;
        }
    }

    @media all and (max-width: 550px) {
        margin: 0;

        > * {
            flex: 1 0 100%;
            max-width: 100%;
            margin: 0 0 $twoGrid;
            padding: 0 !important;
        }
    }
}

.col3 {
    @include col3;
}
